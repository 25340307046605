import { useDispatch, useSelector } from "react-redux";
import { Modal } from "./Modal";
import React, { useState } from "react";
import { Button, DraggableWindowHeader, TextInput } from "@hoylu/client-common";
import { textInputLocalization } from "../../utils/text.input.localization";
import { useI18n } from "../../utils/hooks/use.i18n";
import {
  cancelDashboardOption,
  createWorkspace,
  duplicateWorkspace,
} from "../../state/workspaces/workspaces.actions";
import { getWaitingToCreateFromTemplate } from "../../state/workspaces/workspaces.selector";
import Styles from "./new.workspace.passwords.dialog.module.css";
import { isPasswordRequired } from "../../state/user/user.selector";
import {
  getCreateBlankWorkspacePayload,
  getCreateFromTemplatePayload,
} from "../../utils/create.workspace.utils";

export const NewWorkspacePasswordsDialog = () => {
  const t = useI18n("NEW_WORKSPACE_DIALOG.");
  const templateDetails = useSelector(getWaitingToCreateFromTemplate);
  const hasToSetPassword = useSelector(isPasswordRequired);
  const dispatch = useDispatch();
  const [templatePassword, setTemplatePassword] = useState("");
  const [newPassword, setPassword] = useState("");

  const cancel = () => {
    dispatch(cancelDashboardOption());
  };

  const handleCreationFromTemplate = () => {
    if (templateDetails?.workspaceId) {
      const payload = getCreateFromTemplatePayload(
        templateDetails?.workspaceId
      );

      if (templateDetails.hasPassword) {
        payload.password = templatePassword;
      }

      if (hasToSetPassword) {
        payload.newPassword = newPassword;
      }

      dispatch(duplicateWorkspace.request({ ...payload }));
    }
  };

  const handleBlankWorkspaceCreation = () => {
    const payload = getCreateBlankWorkspacePayload();

    if (hasToSetPassword) {
      payload.password = newPassword;
    }

    dispatch(createWorkspace.request(payload));
  };

  const sendRequest = () => {
    if (templateDetails) {
      handleCreationFromTemplate();
    } else {
      handleBlankWorkspaceCreation();
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (shouldDisable()) return;

    sendRequest();
  };

  const shouldDisable = () => {
    const isBlockedByTemplatePassword =
      templateDetails?.hasPassword && !templatePassword;
    const isBlockedByNewPassword = hasToSetPassword && newPassword.length < 8;

    return isBlockedByTemplatePassword || isBlockedByNewPassword;
  };

  return (
    <Modal handleOutsideClick={cancel}>
      <DraggableWindowHeader title={t("CREATE_WORKSPACE")} onClose={cancel} />
      <form className={Styles.form} onSubmit={handleSubmit}>
        {templateDetails && templateDetails.hasPassword && (
          <TextInput
            label={t("PASSWORD")}
            value={templatePassword}
            onChange={setTemplatePassword}
            type={"password"}
            toolTip={t("PASSWORD")}
            localizationDict={textInputLocalization()}
            useTogglePassword={true}
            isFocus={true}
          />
        )}
        {hasToSetPassword && (
          <TextInput
            type={"password"}
            label={`${t("NEW_PASSWORD")} - ${t("CHARACTERS")}`}
            value={newPassword}
            onChange={setPassword}
            useTogglePassword={true}
            localizationDict={textInputLocalization()}
            isFocus={!templateDetails?.hasPassword}
          />
        )}
        <div className={Styles.buttons}>
          <Button
            type={"primary"}
            toolTip={t("CONTINUE")}
            onClick={sendRequest}
            disabled={shouldDisable()}
          >
            {t("CONTINUE")}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
