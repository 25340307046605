import React, { JSX } from "react";
import Styles from "./TaskView.module.css";
import { RowProps } from "./types";

export const DefaultRow = <T extends object>({
  row,
}: RowProps<T>): JSX.Element => {
  const props = row.getRowProps({ className: Styles.row });

  return (
    <div
      key={props.key}
      style={props.style}
      className={props.className}
      role={props.role}
    >
      {row.cells.map((cell) => {
        const cellProps = cell.getCellProps({
          style: { width: cell.column.width },
        });
        return (
          <div
            key={cellProps.key}
            style={cellProps.style}
            role={cellProps.role}
            className={`${Styles.cell} ${Styles.fontColor} ${Styles.label} ${cellProps.className}`}
          >
            {cell.render("Cell")}
          </div>
        );
      })}
    </div>
  );
};
