import React, { FormEvent, useState } from "react";
import { connect } from "react-redux";
import type { RootState } from "typesafe-actions";
import { isPasswordRequired } from "../../state/user/user.selector";
import type { WorkspaceDetails } from "../../state/workspaces/types";
import {
  cancelDashboardOption,
  duplicateWorkspace,
} from "../../state/workspaces/workspaces.actions";
import { getSelectedWorkspaceDetails } from "../../state/workspaces/workspaces.selector";
import { Localized } from "../../strings";
import { Modal } from "./Modal";
import { GlobalAccess } from "../../services/workspaces/global.access";
import Styles from "./DuplicateWorkspaceDialog.module.css";
import {
  Button,
  DraggableWindowHeader,
  TextInput,
  useWindowSize,
} from "@hoylu/client-common";
import { textInputLocalization } from "../../utils/text.input.localization";
import { defaultGlobalAccess } from "../../utils/create.workspace.utils";

const SCREEN_LG = 1024;

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state),
  isPasswordRequired: isPasswordRequired(state),
});

const mapDispatchToProps = {
  cancelDashboardOption,
  duplicateWorkspace: duplicateWorkspace.request,
};

type DuplicateProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const Duplicate: React.FC<DuplicateProps> = ({
  cancelDashboardOption,
  duplicateWorkspace,
  selectedWorkspaceDetails,
  isPasswordRequired,
}) => {
  const strings = Localized.object("DUPLICATE_WORKSPACE_DIALOG");

  if (!selectedWorkspaceDetails) return null;

  return (
    <DuplicateWorkspaceBaseDialog
      cancel={cancelDashboardOption}
      apply={(params) =>
        duplicateWorkspace({
          workspaceId: selectedWorkspaceDetails.workspaceId,
          ...params,
        })
      }
      selectedWorkspaceDetails={selectedWorkspaceDetails}
      icon={"hoylu-ui-icons-copy"}
      isPasswordRequired={isPasswordRequired}
      strings={{
        shortTitle: strings.COPY_THIS_WORKSPACE,
        longTitle: `${strings.MAKE_COPY} "${selectedWorkspaceDetails.workspaceName}"`,
        newWorkspaceNameLabel: strings.NEW_WORKSPACE_NAME,
        newWorkspaceNameTooltip: strings.NEW_WORKSPACE_NAME,
        newWorkspaceNameDefault: `${strings.COPY_OF} "${selectedWorkspaceDetails.workspaceName}"`,
        passwordLabel: strings.PASSWORD,
        passwordTooltip: strings.PASSWORD,
        applyButtonText: strings.DUPLICATE,
        cancelButtonText: strings.CANCEL,
        newPasswordLabel: strings.NEW_PASSWORD,
        showPassword: strings.SHOW_PASSWORD,
        characters: strings.CHARACTERS,
      }}
    />
  );
};

type ApplyParams = {
  workspaceName: string;
  globalAccess: GlobalAccess;
  password?: string;
  newPassword?: string;
};

export type DuplicateWorkspaceDialogProps = {
  cancel: () => void;
  apply: (params: ApplyParams) => void;
  alternate?: () => void;
  selectedWorkspaceDetails: WorkspaceDetails;
  icon?: string;
  isPasswordRequired: boolean;
  strings: {
    shortTitle: string;
    longTitle: string;
    newWorkspaceNameLabel: string;
    newWorkspaceNameTooltip: string;
    newWorkspaceNameDefault: string;
    passwordLabel: string;
    passwordTooltip: string;
    applyButtonText: string;
    cancelButtonText: string;
    alternateButtonText?: string;
    alternateButtonTooltip?: string;
    newPasswordLabel: string;
    showPassword: string;
    characters: string;
  };
};

// Note: This piece of duplicate workspace dialog is also used by CreateWorkspaceFromTemplateDialog
export const DuplicateWorkspaceBaseDialog: React.FC<DuplicateWorkspaceDialogProps> = ({
  cancel,
  apply,
  alternate,
  selectedWorkspaceDetails,
  icon,
  isPasswordRequired,
  strings,
}) => {
  const [newPassword, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newName, setNewName] = useState(strings.newWorkspaceNameDefault);
  const { width: windowWidth } = useWindowSize();
  const title =
    windowWidth > SCREEN_LG ? strings.longTitle : strings.shortTitle;

  const submitAction = (e: FormEvent) => {
    e.preventDefault();
    apply({
      workspaceName: newName || "",
      password: currentPassword,
      globalAccess: defaultGlobalAccess,
      newPassword,
    });
  };

  return (
    <Modal>
      <div className={Styles.duplicateWorkspaceDialog}>
        <DraggableWindowHeader icon={icon} title={title} onClose={cancel} />
        <form className={Styles.form} onSubmit={submitAction}>
          <TextInput
            label={strings.newWorkspaceNameLabel}
            value={newName}
            onChange={setNewName}
            toolTip={strings.newWorkspaceNameTooltip}
            onFocus={(e) => e.target.select()}
            isFocus={true}
          />
          {selectedWorkspaceDetails?.hasPassword && (
            <TextInput
              label={strings.passwordLabel}
              value={currentPassword}
              onChange={setCurrentPassword}
              type={"password"}
              toolTip={strings.passwordTooltip}
              localizationDict={textInputLocalization()}
              useTogglePassword={true}
            />
          )}
          {isPasswordRequired && (
            <TextInput
              type={"password"}
              label={`${strings.newPasswordLabel} - ${strings.characters}`}
              value={newPassword}
              onChange={setPassword}
              useTogglePassword={true}
              localizationDict={textInputLocalization()}
            />
          )}
          <div className={Styles.footer}>
            {strings.alternateButtonText && alternate && (
              <Button
                type={"secondary"}
                toolTip={strings.alternateButtonTooltip}
                onClick={alternate}
              >
                {strings.alternateButtonText}
              </Button>
            )}
            <Button
              type={"negative"}
              toolTip={strings.cancelButtonText}
              onClick={cancel}
            >
              {strings.cancelButtonText}
            </Button>
            <Button
              type={"primary"}
              toolTip={strings.applyButtonText}
              onClick={submitAction}
            >
              {strings.applyButtonText}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Duplicate);
