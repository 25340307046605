import { NexusService } from "./service";
import { debug } from "debug";
import {
  NexusStatusEntity,
  NexusFilterCursor,
  WithHeaders,
  NexusTaskDto,
  NexusTaskCreateDto,
  NexusTaskUpdateDto,
  Operation,
  UUIDString,
  NexusLabelDto,
} from "./types";

const _LOG = debug("hoylu:task:service:log");

export class NexusTaskService extends NexusService {
  protected readonly path: string = "task";

  /**
   * Retrieves all available task status enums
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusStatusEntity[]>} Array of status entities
   */
  public async getTasksStatusEnums(
    signal?: AbortSignal
  ): Promise<NexusStatusEntity[]> {
    _LOG("Fetching task status enums");
    return this.getJSON<NexusStatusEntity[]>(
      `status`,
      undefined,
      undefined,
      signal
    );
  }

  /**
   * Retrieves all tasks for a given scope with filtering
   * @param {NexusFilterCursor} filter - Filter criteria for tasks
   * @param {string} scopeId - The scope ID to fetch tasks from (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<WithHeaders<NexusTaskDto[]>>} Array of tasks with response headers
   */
  public async getAllTasks(
    filter: NexusFilterCursor = {},
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<WithHeaders<NexusTaskDto[]>> {
    _LOG(`Fetching all tasks for scope: ${scopeId || this.scopeId}`);
    return this.getJSONWithHeaders<NexusTaskDto[]>(
      this.scopedPath(scopeId),
      filter,
      {},
      signal
    );
  }

  /**
   * Creates a new task
   * @param {NexusTaskCreateDto} task - The task data to create
   * @param {string} scopeId - The scope ID to create the task in (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusTaskDto>} The created task
   */
  public async postTask(
    task: NexusTaskCreateDto,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusTaskDto> {
    _LOG(`Creating new task in scope: ${scopeId || this.scopeId}`);
    return this.postJSON<NexusTaskDto>(
      this.scopedPath(scopeId),
      task,
      undefined,
      signal
    );
  }

  /**
   * Retrieves a specific task by ID
   * @param {string} id - The ID of the task to fetch
   * @param {string} scopeId - The scope ID to fetch the task from (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusTaskDto>} The requested task
   */
  public async getTask(
    id: string,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusTaskDto> {
    _LOG(`Fetching task ${id} from scope: ${scopeId || this.scopeId}`);
    return this.getJSON<NexusTaskDto>(
      this.scopedPath(scopeId, id),
      undefined,
      undefined,
      signal
    );
  }

  /**
   * Deletes a task
   * @param {string} id - The ID of the task to delete
   * @param {string} scopeId - The scope ID of the task (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<void>}
   */
  public async deleteTask(
    id: string,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<void> {
    _LOG(`Deleting task ${id} from scope: ${scopeId || this.scopeId}`);
    return this.deleteJSON<void>(
      this.scopedPath(scopeId, id),
      undefined,
      signal
    );
  }

  /**
   * Updates a task with new data
   * @param {string} id - The ID of the task to update
   * @param {NexusTaskUpdateDto} task - The updated task data
   * @param {string} scopeId - The scope ID of the task (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusTaskDto>} The updated task
   */
  public async putTask(
    id: string,
    task: NexusTaskUpdateDto,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusTaskDto> {
    _LOG(`Updating task ${id} in scope: ${scopeId || this.scopeId}`);
    return this.putJSON<NexusTaskDto>(
      this.scopedPath(scopeId, id),
      task,
      undefined,
      signal
    );
  }

  /**
   * Patches a task with specific operations
   * @param {string} id - The ID of the task to patch
   * @param {Operation[]} operations - Array of operations to apply
   * @param {string} scopeId - The scope ID of the task (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusTaskDto>} The patched task
   */
  public async patchTask(
    id: string,
    operations: Operation[],
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusTaskDto> {
    _LOG(`Patching task ${id} in scope: ${scopeId || this.scopeId}`);
    return this.patchJSON<NexusTaskDto>(
      this.scopedPath(scopeId, id),
      operations,
      undefined,
      signal
    );
  }

  /**
   * Creates multiple tasks in bulk
   * @param {NexusTaskCreateDto[]} tasks - Array of task data to create
   * @param {string} scopeId - The scope ID to create the tasks in (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusTaskDto[]>} Array of created tasks
   */
  public async postManyTasks(
    tasks: NexusTaskCreateDto[],
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusTaskDto[]> {
    _LOG(`Creating ${tasks.length} tasks in scope: ${scopeId || this.scopeId}`);
    return this.postJSON<NexusTaskDto[]>(
      this.scopedPath(scopeId, "bulk"),
      tasks,
      undefined,
      signal
    );
  }

  /**
   * Deletes an entire scope and its tasks
   * @param {string} scopeId - The scope ID to delete (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<void>}
   */
  public async deleteScope(
    scopeId?: string,
    signal?: AbortSignal
  ): Promise<void> {
    _LOG(`Deleting scope: ${scopeId || this.scopeId}`);
    return this.deleteJSON<void>(
      `scope/${scopeId || this.scopeId}`,
      undefined,
      signal
    );
  }

  /**
   * Retrieves all labels for a specific task
   * @param {UUIDString} taskId - The ID of the task
   * @param {UUIDString} scopeId - The scope ID (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusLabelDto[]>} Array of labels associated with the task
   */
  public async getTaskLabels(
    taskId: UUIDString,
    scopeId: UUIDString = "",
    signal?: AbortSignal
  ): Promise<NexusLabelDto[]> {
    _LOG(
      `Fetching labels for task ${taskId} in scope: ${scopeId || this.scopeId}`
    );
    return this.getJSON<NexusLabelDto[]>(
      this.scopedPath(scopeId, taskId, "labels"),
      undefined,
      undefined,
      signal
    );
  }

  /**
   * Adds a label to a task
   * @param {UUIDString} taskId - The ID of the task
   * @param {UUIDString} labelId - The ID of the label to add
   * @param {UUIDString} scopeId - The scope ID (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusLabelDto[]>} Updated array of labels for the task
   */
  public async postTaskLabel(
    taskId: UUIDString,
    labelId: UUIDString,
    scopeId: UUIDString = "",
    signal?: AbortSignal
  ): Promise<NexusLabelDto[]> {
    _LOG(
      `Adding label ${labelId} to task ${taskId} in scope: ${
        scopeId || this.scopeId
      }`
    );
    return this.postJSON<NexusLabelDto[]>(
      this.scopedPath(scopeId, taskId, "labels", labelId),
      {},
      undefined,
      signal
    );
  }

  /**
   * Removes a label from a task
   * @param {UUIDString} taskId - The ID of the task
   * @param {UUIDString} labelId - The ID of the label to remove
   * @param {UUIDString} scopeId - The scope ID (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusLabelDto[]>} Updated array of labels for the task
   */
  public async deleteTaskLabel(
    taskId: UUIDString,
    labelId: UUIDString,
    scopeId: UUIDString = "",
    signal?: AbortSignal
  ): Promise<NexusLabelDto[]> {
    _LOG(
      `Removing label ${labelId} from task ${taskId} in scope: ${
        scopeId || this.scopeId
      }`
    );
    return this.deleteJSON<NexusLabelDto[]>(
      this.scopedPath(scopeId, taskId, "labels", labelId),
      undefined,
      signal
    );
  }

  /**
   * Removes all labels from a task
   * @param {UUIDString} taskId - The ID of the task
   * @param {UUIDString} scopeId - The scope ID (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusLabelDto[]>} Updated array of labels for the task
   */
  public async deleteTaskLabels(
    taskId: UUIDString,
    scopeId: UUIDString = "",
    signal?: AbortSignal
  ): Promise<UUIDString[]> {
    _LOG(
      `Removing all labels from task ${taskId} in scope: ${
        scopeId || this.scopeId
      }`
    );
    return this.deleteJSON<UUIDString[]>(
      this.scopedPath(scopeId, taskId, "labels"),
      undefined,
      signal
    );
  }

  /**
   * Retrieves all workspace associations for a task
   * @param {UUIDString} taskId - The ID of the task
   * @param {string} scopeId - The scope ID (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<string[]>} Array of workspace IDs associated with the task
   */
  public async getAllWorkspaces(
    taskId: UUIDString,
    scopeId = "",
    signal?: AbortSignal
  ): Promise<string[]> {
    _LOG(
      `Fetching workspaces for task ${taskId} in scope: ${
        scopeId || this.scopeId
      }`
    );
    return this.getJSON<string[]>(
      this.scopedPath(scopeId, taskId, "workspace-associations"),
      undefined,
      undefined,
      signal
    );
  }

  /**
   * Associates a workspace with a task
   * @param {UUIDString} taskId - The ID of the task
   * @param {string} workspaceId - The ID of the workspace to associate
   * @param {string} scopeId - The scope ID (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<string[]>} Updated array of workspace IDs associated with the task
   */
  public async postTaskWorkspace(
    taskId: UUIDString,
    workspaceId: string,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<string[]> {
    _LOG(
      `Adding workspace ${workspaceId} to task ${taskId} in scope: ${
        scopeId || this.scopeId
      }`
    );
    return this.postJSON<string[]>(
      this.scopedPath(scopeId, taskId, "workspace-associations", workspaceId),
      {},
      undefined,
      signal
    );
  }

  /**
   * Removes a workspace association from a task
   * @param {UUIDString} taskId - The ID of the task
   * @param {string} workspaceId - The ID of the workspace to remove
   * @param {string} scopeId - The scope ID (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<string[]>} Updated array of workspace IDs associated with the task
   */
  public async deleteTaskWorkspace(
    taskId: UUIDString,
    workspaceId: string,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<string[]> {
    _LOG(
      `Removing workspace ${workspaceId} from task ${taskId} in scope: ${
        scopeId || this.scopeId
      }`
    );
    return this.deleteJSON<string[]>(
      this.scopedPath(scopeId, taskId, "workspace-associations", workspaceId),
      undefined,
      signal
    );
  }
}
