import { log, setLogUrl } from "@hoylu/client-common";
import { ajax } from "rxjs/ajax";
import { getTokenSilent, handleLogin, handleLogout, registerEmail, } from "./dependencies/loginDependencies";
import { headRequest, postJSON, putJSON, requestBlob, requestText, } from "./dependencies/ajaxRequests";
import { focusWorkspace, getDocumentTitle, updateDocumentTitle, } from "./dependencies/workspaceDependencies";
import { syncHistory } from "./dependencies/syncHistory";
import { sendMessageToAllWorkspaces, sendMessageToWorkspace, } from "../post-message-dispatch";
import { flagsForUserObservable } from "../feature-flags-dispatch";
import { hubspotTryTrackUserRegistration } from "./dependencies/hubspot";
import { clearWebexShareUrl, setWebexShareUrl } from "./dependencies/webex";
import { identifyUserInPendo } from "./dependencies/pendo";
import { templateRequests } from "./templates/template.requests";
import { removeThumbnail, uploadThumbnail } from "./dependencies/uploadServiceDependencies";
import { documentIdv3Requests } from "./workspaces/api/documentIdv3.requests";
import { documentIdv2Requests } from "./workspaces/api/documentIdv2.requests";
import { documentIdv1Requests } from "./workspaces/api/documentIdv1.requests";
import { getNexusReportService, getNexusTaskService, } from "./analytics";

export default {
  getJSON: ajax.getJSON,
  deleteAjax: ajax.delete,
  postJSON,
  putJSON,
  getTokenSilent,
  registerEmail,
  login: handleLogin,
  logout: handleLogout,
  log: log,
  setLogUrl,
  syncHistory,
  focusWorkspace,
  updateDocumentTitle,
  getDocumentTitle,
  sendMessageToWorkspace,
  sendMessageToAllWorkspaces,
  headRequest,
  flagsForUser: flagsForUserObservable,
  requestText,
  requestBlob,
  getCurrentDate: () => new Date(),
  hubspotTryTrackUserRegistration,
  setWebexShareUrl,
  clearWebexShareUrl,
  identifyUserInPendo,
  templateRequests,
  uploadThumbnail,
  removeThumbnail,
  documentIdv1Requests,
  documentIdv2Requests,
  documentIdv3Requests,
  getNexusTaskService,
  getNexusReportService,
};
