import React, { createContext, useEffect, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Option } from "@hoylu/client-common";
import { DateTime, Interval } from "luxon";
import {
  getSelectedProjectWorkspaces,
  selectedProject,
} from "../../state/workspaces/workspaces.selector";
import { ReportFilters } from "../../services/analytics";
import { fetchMasterData } from "../../state/analytics/analytics.actions";
import { selectAnalyticsMasterData } from "../../state/analytics/analytics.selector";
import copy from "fast-copy";

export type FilterOptions = {
  availableOptions: Option<string>[];
  selectedOptions: Option<string>[];
};

export type FilterState = {
  to?: string;
  from?: string;
  filters: Record<string, FilterOptions>;
  query?: string;
};

const initialState: FilterState = { filters: {} };

export enum FilterActionsType {
  SET_AVAILABLE_OPTIONS = "SET_AVAILABLE_OPTIONS",
  SET_SELECTED_OPTIONS = "SET_SELECTED_OPTIONS",
  SET_DATE_RANGE = "SET_DATE_RANGE",
  SET_QUERY = "SET_QUERY",
  REFRESH = "REFRESH",
}

export class FilterAction<TType extends FilterActionsType, TPayload> {
  readonly type: TType;
  readonly payload: TPayload;

  private constructor(type: TType, payload: TPayload) {
    this.type = type;
    this.payload = payload;
  }

  static setQuery(query?: string) {
    return new FilterAction(FilterActionsType.SET_QUERY, query);
  }

  static setAvailableOptions(key: string, options: Option<string>[]) {
    return new FilterAction(FilterActionsType.SET_AVAILABLE_OPTIONS, {
      key,
      options,
    });
  }

  static setSelectedOptions(key: string, options: Option<string>[]) {
    return new FilterAction(FilterActionsType.SET_SELECTED_OPTIONS, {
      key,
      options,
    });
  }

  static setDateRange(key: "to" | "from", date: DateTime) {
    return new FilterAction(FilterActionsType.SET_DATE_RANGE, { key, date });
  }

  static refresh() {
    return new FilterAction(FilterActionsType.REFRESH, {});
  }
}

export type AnyFilterAction = ReturnType<
  typeof FilterAction[Exclude<keyof typeof FilterAction, "prototype">]
>;

// Define the reducer
const filterReducer = (state = initialState, action: AnyFilterAction) => {
  switch (action.type) {
    case FilterActionsType.REFRESH:
      return copy(state);
    case FilterActionsType.SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case FilterActionsType.SET_DATE_RANGE:
      const date = action.payload.date.toISODate();
      if (date) {
        return {
          ...state,
          [action.payload.key]: date,
        } as FilterState;
      }
      return {
        ...state,
      };
    case FilterActionsType.SET_AVAILABLE_OPTIONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: {
            selectedOptions: [],
            availableOptions: action.payload.options,
          },
        },
      };
    case FilterActionsType.SET_SELECTED_OPTIONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: {
            ...state.filters[action.payload.key],
            selectedOptions: action.payload.options,
          },
        },
      };
    default:
      return state;
  }
};

export type FilterContextType = {
  filterState: typeof initialState;
  project?: string;
  dateRange?: Interval<true>;
  filterDispatch: React.Dispatch<any>;
};

// Create context
export const FilterContext = createContext<FilterContextType>({
  filterState: initialState,
  project: undefined,
  dateRange: undefined,
  filterDispatch: () => null,
});

// Create a provider component
export const FilterProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const project = useSelector(selectedProject);
  const projectWorkspaces = useSelector(getSelectedProjectWorkspaces);

  useEffect(() => {
    project?.id && dispatch(fetchMasterData.request(project?.id));
  }, [project?.id]);

  const masterData = useSelector(selectAnalyticsMasterData);

  const [filterState, filterDispatch] = useReducer(filterReducer, initialState);

  const dateRange = useMemo(() => {
    if (!masterData.data?.from || !masterData.data?.to) return undefined;
    const fromDate = DateTime.fromISO(masterData.data.from);
    const toDate = DateTime.fromISO(masterData.data.to);
    if (!fromDate.isValid || !toDate.isValid) return undefined;
    const interval = Interval.fromDateTimes(fromDate, toDate);
    return interval.isValid ? interval : undefined;
  }, [masterData.data?.from, masterData.data?.to]);

  useEffect(() => {
    //Teams
    if (masterData.data?.team) {
      filterDispatch(
        FilterAction.setAvailableOptions(
          "team",
          masterData.data.team
            .filter((t) => !t.isDeleted)
            .map((t) => ({
              label: t.name,
              value: t.id,
            }))
            .concat({
              label: "No Team assigned",
              value: "00000000-0000-0000-0000-000000000000",
            })
        )
      );
      filterDispatch(FilterAction.setSelectedOptions("team", []));
    }
    //Status
    if (masterData.data?.status) {
      filterDispatch(
        FilterAction.setAvailableOptions(
          "status",
          masterData.data.status.map((t) => ({
            label: t.name,
            value: t.id,
          }))
        )
      );
      filterDispatch(FilterAction.setSelectedOptions("status", []));
    }
    //Labels
    if (masterData.data?.label) {
      filterDispatch(
        FilterAction.setAvailableOptions(
          "label",
          masterData.data.label
            .filter((t) => !t.isDeleted)
            .map((t) => ({
              label: t.name,
              value: t.id,
            }))
        )
      );
      filterDispatch(FilterAction.setSelectedOptions("label", []));
    }
    //Assignees
    if (masterData.data?.assignee) {
      filterDispatch(
        FilterAction.setAvailableOptions(
          "assignee",
          masterData.data.assignee.map((t) => ({
            label: t,
            value: t,
          }))
        )
      );
      filterDispatch(FilterAction.setSelectedOptions("assignee", []));
    }
    //Workspace
    if (masterData.data?.workspace) {
      filterDispatch(
        FilterAction.setAvailableOptions(
          "workspace",
          masterData.data.workspace.map((t) => ({
            label:
              projectWorkspaces.find(
                (w) => w.workspaceId && w.workspaceId === t
              )?.workspaceName ?? t,
            value: t,
          }))
        )
      );
      filterDispatch(FilterAction.setSelectedOptions("workspace", []));
    }

    const now = DateTime.now();
    const currentWeek = now.startOf('week');
    let fromDate: DateTime;
    let toDate: DateTime;

    if (dateRange?.end && dateRange.end < currentWeek) {
      // dateRange.end is before current week
      fromDate = dateRange.end.minus({ weeks: 4 });
      toDate = dateRange.end;
    } else if (dateRange?.start && dateRange.start > currentWeek) {
      // dateRange.start is after current week  
      fromDate = dateRange.start;
      toDate = dateRange.start.plus({ weeks: 4 });
    } else {
      // current week is within range (or no range)
      fromDate = currentWeek.minus({ weeks: 2 });
      toDate = currentWeek.plus({ weeks: 2 });
    }

    // Ensure dates are within range bounds
    if (dateRange) {
      fromDate = fromDate < dateRange.start ? dateRange.start : fromDate;
      toDate = toDate > dateRange.end ? dateRange.end : toDate;
    }

    filterDispatch(FilterAction.setDateRange("from", fromDate));
    filterDispatch(FilterAction.setDateRange("to", toDate));
  }, [masterData, projectWorkspaces]);

  return (
    <FilterContext.Provider
      value={{ filterState, dateRange, project: project?.id, filterDispatch }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const toReportFilters = (context: FilterContextType): ReportFilters => {
  const filters: ReportFilters = { filters: {} };
  Object.entries(context.filterState.filters).forEach(([key, value]) => {
    filters.filters[key] = value.selectedOptions.map((o) => o.value);
  });
  filters.to = context.filterState.to;
  filters.from = context.filterState.from;
  filters.query = context.filterState.query;
  return filters;
};
