import { DateTime } from "luxon";
import {
  BarData,
  BarDataSet,
  getTotalByXAxis,
  LineData,
  LineDataSetExtended,
  PieDataSet,
} from "@hoylu/nivo-charts";

// Case sensitivity and the flip here is intentional.
const STATUS_ORDER_MAP = new Map([
  ["Incomplete", 0],
  ["Complete", 1],
  ["Committed", 2],
  ["Planned", 3],
  ["Unplanned", 4],
]);

const PPC_ORDER_MAP = new Map([
  ["Completed on time", 0],
  ["Early/Late/Incomplete", 1],
]);

/**
 * Sorts statuses based on predefined order.
 * @param {string} a - First status to compare.
 * @param {string} b - Second status to compare.
 * @returns {number} - Comparison result.
 */
export const statusSorter = (a: string, b: string) =>
  (STATUS_ORDER_MAP.get(a) ?? 0) - (STATUS_ORDER_MAP.get(b) ?? 0);

/**
 * Sorts PPC statuses based on predefined order.
 * @param {string} a - First PPC status to compare.
 * @param {string} b - Second PPC status to compare.
 * @returns {number} - Comparison result.
 */
export const ppcSorter = (a: string, b: string) =>
  (PPC_ORDER_MAP.get(a) ?? 0) - (PPC_ORDER_MAP.get(b) ?? 0);

/**
 * Generator function to produce ISO week strings.
 * @param {string} isoWeek - Initial ISO week string.
 * @param {string} [splitter=`] - Character used to split the week and year.
 * @yields {string} - Next ISO week string.
 */
export function* weekGenerator(isoWeek: string, splitter = "`") {
  if (isoWeek === "") return isoWeek;
  const [week, year] = isoWeek.split(splitter);
  var date = DateTime.fromObject({
    weekNumber: parseInt(week),
    weekYear: parseInt(year),
  });
  while (date.isValid) {
    yield `${date.weekNumber
      .toString()
      .padStart(2, "0")}\`${date.weekYear.toString().slice(-2)}`;
    date = date.plus({ weeks: 1 });
  }
  return isoWeek;
}

export class TaskDataBuilder {
  /**
   * Builds a pie data set from the given data.
   * @param {D[]} dataSet - Array of data objects.
   * @param {function(D): string} keySelector - Function to select the key from a data object.
   * @param {function(D): number} valueSelector - Function to select the value from a data object.
   * @param {function(string): string} colorSelector - Function to select the color based on the key.
   * @param {function(string, string): number} sorter - Function to sort the keys.
   * @returns {PieDataSet[]} - Array of pie data set objects.
   */
  public buildPieDataSet<D>(
    dataSet: D[],
    keySelector: (obj: D) => string,
    valueSelector: (obj: D) => number,
    colorSelector: (key: string) => string,
    sorter: (a: string, b: string) => number
  ): PieDataSet[] {
    return dataSet
      .sort((a, b) => sorter(keySelector(a), keySelector(b)))
      .map((s) => ({
        id: keySelector(s),
        label: keySelector(s),
        value: valueSelector(s),
        color: colorSelector(keySelector(s)),
      }));
  }

  /**
   * Builds a line data set with filled gaps from the given data.
   * @param {T[]} dataSet - Array of data objects.
   * @param {Generator<string, string, unknown> | undefined} rangeGenerator - Generator function to produce the range of keys.
   * @param {function(D): string} plotSelector - Function to select the plot from a data object.
   * @param {function(T): string} keySelector - Function to select the key from a data object.
   * @param {function(T): D[]} collectionSelector - Function to select the collection from a data object.
   * @param {function(D): number} valueSelector - Function to select the value from a data object.
   * @param {function(string): string} colorSelector - Function to select the color based on the plot.
   * @param {function(string, string): number} sorter - Function to sort the plots.
   * @returns {LineDataSetExtended} - Line data set with filled gaps.
   */
  public buildLineDataSet<T extends object, D extends object>(
    dataSet: T[],
    rangeGenerator: Generator<string, string, unknown> | undefined,
    plotSelector: (obj: D) => string,
    keySelector: (obj: T) => string,
    collectionSelector: (obj: T) => D[],
    valueSelector: (obj: D) => number,
    colorSelector: (key: string) => string,
    sorter: (a: string, b: string) => number
  ): LineDataSetExtended {
    var plots = new Map<string, LineData[]>();
    const processedKeys = [];
    for (const item of dataSet) {
      const key = keySelector(item);
      var expectedKey = rangeGenerator ? rangeGenerator.next().value : key; // If there is no range generator, do not fill the gaps
      while (key !== expectedKey) {
        // Add empty data for missing key
        for (const plot of plots.values()) {
          plot.push({
            x: expectedKey,
            y: 0,
          });
        }
        processedKeys.push(expectedKey);
        expectedKey = rangeGenerator!.next().value;
      }
      processedKeys.push(key);
      // Set zero values for all plots
      for (const plot of plots.values()) {
        plot.push({
          x: key,
          y: 0,
        });
      }
      const collection = collectionSelector(item);
      for (const data of collection) {
        const plot = plotSelector(data);
        // If there is a new plot, create empty data range for it
        if (!plots.has(plot)) {
          plots.set(
            plot,
            processedKeys.map((key) => ({ x: key, y: 0 }))
          );
        }
        // Pop & push seems to be faster, so discard the last element and push the new one
        plots.get(plot)!.pop();
        plots.get(plot)!.push({
          x: key,
          y: valueSelector(data),
        });
      }
    }
    const lineSets: LineDataSetExtended = { data: [], size: 0 };
    plots.forEach((data, plot) => {
      lineSets.data.push({
        id: plot,
        color: colorSelector(plot),
        data: data,
      });
    });
    const xAxis = getTotalByXAxis(lineSets.data);
    return {
      data: lineSets.data.sort((a, b) => sorter(a.id, b.id)),
      total: xAxis,
      size: xAxis.length,
    };
  }

  /**
   * Builds a bar data set from the given data.
   * @param {T[]} barDataSet - Array of data objects.
   * @param {function(D): number} valueSelector - Function to select the value from a data object.
   * @param {function(T): D[]} collectionSelector - Function to select the collection from a data object.
   * @param {function(T): string} keySelector - Function to select the key from a data object.
   * @param {function(D): string} barSelector - Function to select the bar from a data object.
   * @param {function(string): string} aliasSelector - Function to select the alias based on the key.
   * @param {function(string): string} colorSelector - Function to select the color based on the bar.
   * @param {function(string, string): number} barSorter - Function to sort the bars.
   * @param {function(string, string): number} areaSorter - Function to sort the areas.
   * @returns {BarDataSet} - Bar data set.
   */
  public buildBarDataSet<T, D>(
    barDataSet: T[],
    valueSelector: (obj: D) => number,
    collectionSelector: (obj: T) => D[],
    keySelector: (obj: T) => string,
    barSelector: (obj: D) => string,
    aliasSelector: (obj: T) => string,
    colorSelector: (key: string) => string,
    barSorter: (a: string, b: string) => number,
    areaSorter: (a: string, b: string) => number
  ): BarDataSet {
    let labels = new Set<string>();
    let aliasMap: Map<string, string> = new Map();
    const barData = [];

    for (const item of barDataSet) {
      const key = keySelector(item);
      aliasMap.set(aliasSelector(item), key);
      const barDatum: BarData = {
        title: aliasSelector(item),
      };
      const collection = collectionSelector(item);
      for (const record of collection) {
        const bar = barSelector(record);
        labels = labels.add(bar);
        barDatum[bar] = valueSelector(record);
        barDatum[bar + "Color"] = colorSelector(bar);
      }
      barData.push(barDatum);
    }
    return {
      data: barData.sort((a, b) => barSorter(a.title, b.title)),
      keys: Array.from(labels).sort(areaSorter),
      dictionary: aliasMap,
    };
  }
}
