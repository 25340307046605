import { createAction, createAsyncAction } from "typesafe-actions";
import { PagedResponse, } from "../../services/analytics/types";
import { ReportFilters } from "../../services/analytics";
import {
  NexusTaskDto,
  MasterData,
  PpcResult,
  PpcTeamResult,
  PpcWeekResult,
  ReportResult,
  TeamReasonReportResult,
  TeamStatusReportResult,
  WeekReasonReportResult,
  WeekStatusReportResult,
} from "@hoylu/nexus-service";

export const fetchTaskStatus = createAsyncAction(
  "FETCH_TASK_STATUS_REQUEST",
  "FETCH_TASK_STATUS_SUCCESS",
  "FETCH_TASK_STATUS_FAILURE"
)<ReportFilters, ReportResult[], Error>();

export const fetchTaskStatusByTeam = createAsyncAction(
  "FETCH_TASK_STATUS_BY_TEAM_REQUEST",
  "FETCH_TASK_STATUS_BY_TEAM_SUCCESS",
  "FETCH_TASK_STATUS_BY_TEAM_FAILURE"
)<ReportFilters, TeamStatusReportResult[], Error>();

export const fetchTaskStatusByWeek = createAsyncAction(
  "FETCH_TASK_STATUS_BY_WEEK_REQUEST",
  "FETCH_TASK_STATUS_BY_WEEK_SUCCESS",
  "FETCH_TASK_STATUS_BY_WEEK_FAILURE"
)<ReportFilters, WeekStatusReportResult[], Error>();

export const fetchTaskPPC = createAsyncAction(
  "FETCH_TASK_PPC_REQUEST",
  "FETCH_TASK_PPC_SUCCESS",
  "FETCH_TASK_PPC_FAILURE"
)<ReportFilters, PpcResult[], Error>();

export const fetchTaskPPCByTeam = createAsyncAction(
  "FETCH_TASK_PPC_BY_TEAM_REQUEST",
  "FETCH_TASK_PPC_BY_TEAM_SUCCESS",
  "FETCH_TASK_PPC_BY_TEAM_FAILURE"
)<ReportFilters, PpcTeamResult[], Error>();

export const fetchTaskPPCByWeek = createAsyncAction(
  "FETCH_TASK_PPC_BY_WEEK_REQUEST",
  "FETCH_TASK_PPC_BY_WEEK_SUCCESS",
  "FETCH_TASK_PPC_BY_WEEK_FAILURE"
)<ReportFilters, PpcWeekResult[], Error>();

export const fetchTaskReason = createAsyncAction(
  "FETCH_TASK_REASON_REQUEST",
  "FETCH_TASK_REASON_SUCCESS",
  "FETCH_TASK_REASON_FAILURE"
)<ReportFilters, ReportResult[], Error>();

export const fetchTaskReasonByWeek = createAsyncAction(
  "FETCH_TASK_REASON_BY_WEEK_REQUEST",
  "FETCH_TASK_REASON_BY_WEEK_SUCCESS",
  "FETCH_TASK_REASON_BY_WEEK_FAILURE"
)<ReportFilters, WeekReasonReportResult[], Error>();

export const fetchTaskReasonByTeam = createAsyncAction(
  "FETCH_TASK_REASON_BY_TEAM_REQUEST",
  "FETCH_TASK_REASON_BY_TEAM_SUCCESS",
  "FETCH_TASK_REASON_BY_TEAM_FAILURE"
)<ReportFilters, TeamReasonReportResult[], Error>();

export const fetchMasterData = createAsyncAction(
  "FETCH_MASTER_DATA_REQUEST",
  "FETCH_MASTER_DATA_SUCCESS",
  "FETCH_MASTER_DATA_FAILURE"
)<string, MasterData, Error>();

export const fetchTasks = createAsyncAction(
  "FETCH_TASKS_REQUEST",
  "FETCH_TASKS_SUCCESS",
  "FETCH_TASKS_FAILURE"
)<ReportFilters, PagedResponse<NexusTaskDto[]>, Error>();

export const fetchMoreTasks = createAsyncAction(
  "FETCH_MORE_TASKS_REQUEST",
  "FETCH_MORE_TASKS_SUCCESS",
  "FETCH_MORE_TASKS_FAILURE"
)<string, PagedResponse<NexusTaskDto[]>, Error>();

export const deleteScope = createAction("DELETE_SCOPE")();
export const reset = createAction("RESET")();