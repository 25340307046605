import { from, of, EMPTY } from "rxjs";
import { catchError, debounceTime, filter, mergeMap } from "rxjs/operators";
import { AppEpic } from "../types";
import { isActionOf } from "typesafe-actions";
import { waitForToken } from "../helpers/waitForState";
import {
  deleteScope,
  fetchMasterData,
  fetchMoreTasks,
  fetchTaskPPC,
  fetchTaskPPCByTeam,
  fetchTaskPPCByWeek,
  fetchTaskReason,
  fetchTaskReasonByTeam,
  fetchTaskReasonByWeek,
  fetchTasks,
  fetchTaskStatus,
  fetchTaskStatusByTeam,
  fetchTaskStatusByWeek,
  reset,
} from "../../state/analytics/analytics.actions";

import { ReportFilters } from "./index";
import { NexusFilterCursor, NexusTaskDto } from "@hoylu/nexus-service";
import { unfocusWorkspaces } from "../../state/workspaces/workspaces.actions";

const DEBOUNCE_CALL = 1500;

/**
 * Epic that handles fetching task status reports.
 * @param {Observable} action$ - Stream of actions
 * @param {Observable} state$ - Stream of states
 * @param {Object} dependencies - Epic dependencies containing getNexusReportService
 * @returns {Observable} Stream of actions (success or failure)
 */
export const fetchTaskStatusEpic: AppEpic = (
  action$,
  state$,
  { getNexusReportService }
) =>
  action$.pipe(
    filter(isActionOf(fetchTaskStatus.request)),
    debounceTime(DEBOUNCE_CALL),
    waitForToken(state$),
    mergeMap((action) => {
      const filter = toNexusFilterCursor(action.payload);
      const nexusReportService = getNexusReportService(state$.value);
      return from(nexusReportService.getStatusReport(filter)).pipe(
        mergeMap((res) => of(fetchTaskStatus.success(res))),
        catchError((e) => of(fetchTaskStatus.failure(e)))
      );
    })
  );

/**
 * Epic that handles fetching task status reports grouped by team.
 * @param {Observable} action$ - Stream of actions
 * @param {Observable} state$ - Stream of states
 * @param {Object} dependencies - Epic dependencies containing getNexusReportService
 * @returns {Observable} Stream of actions (success or failure)
 */
export const fetchTaskStatusByTeamEpic: AppEpic = (
  action$,
  state$,
  { getNexusReportService }
) =>
  action$.pipe(
    filter(isActionOf(fetchTaskStatusByTeam.request)),
    debounceTime(DEBOUNCE_CALL),
    waitForToken(state$),
    mergeMap((action) => {
      const filter = toNexusFilterCursor(action.payload);
      const nexusReportService = getNexusReportService(state$.value);
      return from(nexusReportService.getStatusReportByTeam(filter)).pipe(
        mergeMap((res) => of(fetchTaskStatusByTeam.success(res))),
        catchError((e) => of(fetchTaskStatusByTeam.failure(e)))
      );
    })
  );

/**
 * Epic that handles fetching task status reports grouped by week.
 * @param {Observable} action$ - Stream of actions
 * @param {Observable} state$ - Stream of states
 * @param {Object} dependencies - Epic dependencies containing getNexusReportService
 * @returns {Observable} Stream of actions (success or failure)
 */
export const fetchTaskStatusByWeekEpic: AppEpic = (
  action$,
  state$,
  { getNexusReportService }
) =>
  action$.pipe(
    filter(isActionOf(fetchTaskStatusByWeek.request)),
    debounceTime(DEBOUNCE_CALL),
    waitForToken(state$),
    mergeMap((action) => {
      const filter = toNexusFilterCursor(action.payload);
      const nexusReportService = getNexusReportService(state$.value);
      return from(nexusReportService.getStatusReportByWeek(filter)).pipe(
        mergeMap((res) => of(fetchTaskStatusByWeek.success(res))),
        catchError((e) => of(fetchTaskStatusByWeek.failure(e)))
      );
    })
  );

/**
 * Epic that handles fetching PPC (Percent Plan Complete) reports.
 * @param {Observable} action$ - Stream of actions
 * @param {Observable} state$ - Stream of states
 * @param {Object} dependencies - Epic dependencies containing getNexusReportService
 * @returns {Observable} Stream of actions (success or failure)
 */
export const fetchTaskPPCEpic: AppEpic = (
  action$,
  state$,
  { getNexusReportService }
) =>
  action$.pipe(
    filter(isActionOf(fetchTaskPPC.request)),
    debounceTime(DEBOUNCE_CALL),
    waitForToken(state$),
    mergeMap((action) => {
      const filter = toNexusFilterCursor(action.payload);
      const nexusReportService = getNexusReportService(state$.value);
      return from(nexusReportService.getPpcReport(filter)).pipe(
        mergeMap((res) => of(fetchTaskPPC.success(res))),
        catchError((e) => of(fetchTaskPPC.failure(e)))
      );
    })
  );

/**
 * Epic that handles fetching PPC reports grouped by team.
 * @param {Observable} action$ - Stream of actions
 * @param {Observable} state$ - Stream of states
 * @param {Object} dependencies - Epic dependencies containing getNexusReportService
 * @returns {Observable} Stream of actions (success or failure)
 */
export const fetchTaskPPCByTeamEpic: AppEpic = (
  action$,
  state$,
  { getNexusReportService }
) =>
  action$.pipe(
    filter(isActionOf(fetchTaskPPCByTeam.request)),
    debounceTime(DEBOUNCE_CALL),
    waitForToken(state$),
    mergeMap((action) => {
      const filter = toNexusFilterCursor(action.payload);
      const nexusReportService = getNexusReportService(state$.value);
      return from(nexusReportService.getPpcReportByTeam(filter)).pipe(
        mergeMap((res) => of(fetchTaskPPCByTeam.success(res))),
        catchError((e) => of(fetchTaskPPCByTeam.failure(e)))
      );
    })
  );

/**
 * Epic that handles fetching PPC reports grouped by week.
 * @param {Observable} action$ - Stream of actions
 * @param {Observable} state$ - Stream of states
 * @param {Object} dependencies - Epic dependencies containing getNexusReportService
 * @returns {Observable} Stream of actions (success or failure)
 */
export const fetchTaskPPCByWeekEpic: AppEpic = (
  action$,
  state$,
  { getNexusReportService }
) =>
  action$.pipe(
    filter(isActionOf(fetchTaskPPCByWeek.request)),
    debounceTime(DEBOUNCE_CALL),
    waitForToken(state$),
    mergeMap((action) => {
      const filter = toNexusFilterCursor(action.payload);
      const nexusReportService = getNexusReportService(state$.value);
      return from(nexusReportService.getPpcReportByWeek(filter)).pipe(
        mergeMap((res) => of(fetchTaskPPCByWeek.success(res))),
        catchError((e) => of(fetchTaskPPCByWeek.failure(e)))
      );
    })
  );

/**
 * Epic that handles fetching task reason reports.
 * @param {Observable} action$ - Stream of actions
 * @param {Observable} state$ - Stream of states
 * @param {Object} dependencies - Epic dependencies containing getNexusReportService
 * @returns {Observable} Stream of actions (success or failure)
 */
export const fetchTaskReasonEpic: AppEpic = (
  action$,
  state$,
  { getNexusReportService }
) =>
  action$.pipe(
    filter(isActionOf(fetchTaskReason.request)),
    debounceTime(DEBOUNCE_CALL),
    waitForToken(state$),
    mergeMap((action) => {
      const filter = toNexusFilterCursor(action.payload);
      const nexusReportService = getNexusReportService(state$.value);
      return from(nexusReportService.getReasonReport(filter)).pipe(
        mergeMap((res) => of(fetchTaskReason.success(res))),
        catchError((e) => of(fetchTaskReason.failure(e)))
      );
    })
  );

/**
 * Epic that handles fetching task reason reports grouped by week.
 * @param {Observable} action$ - Stream of actions
 * @param {Observable} state$ - Stream of states
 * @param {Object} dependencies - Epic dependencies containing getNexusReportService
 * @returns {Observable} Stream of actions (success or failure)
 */
export const fetchTaskReasonByWeekEpic: AppEpic = (
  action$,
  state$,
  { getNexusReportService }
) =>
  action$.pipe(
    filter(isActionOf(fetchTaskReasonByWeek.request)),
    debounceTime(DEBOUNCE_CALL),
    waitForToken(state$),
    mergeMap((action) => {
      const filter = toNexusFilterCursor(action.payload);
      const nexusReportService = getNexusReportService(state$.value);
      return from(nexusReportService.getReasonReportByWeek(filter)).pipe(
        mergeMap((res) => of(fetchTaskReasonByWeek.success(res))),
        catchError((e) => of(fetchTaskReasonByWeek.failure(e)))
      );
    })
  );

/**
 * Epic that handles fetching task reason reports grouped by team.
 * @param {Observable} action$ - Stream of actions
 * @param {Observable} state$ - Stream of states
 * @param {Object} dependencies - Epic dependencies containing getNexusReportService
 * @returns {Observable} Stream of actions (success or failure)
 */
export const fetchTaskReasonByTeamEpic: AppEpic = (
  action$,
  state$,
  { getNexusReportService }
) =>
  action$.pipe(
    filter(isActionOf(fetchTaskReasonByTeam.request)),
    debounceTime(DEBOUNCE_CALL),
    waitForToken(state$),
    mergeMap((action) => {
      const filter = toNexusFilterCursor(action.payload);
      const nexusReportService = getNexusReportService(state$.value);
      return from(nexusReportService.getReasonReportByTeam(filter)).pipe(
        mergeMap((res) => of(fetchTaskReasonByTeam.success(res))),
        catchError((e) => of(fetchTaskReasonByTeam.failure(e)))
      );
    })
  );

/**
 * Epic that handles fetching master data for task filters.
 * @param {Observable} action$ - Stream of actions
 * @param {Observable} state$ - Stream of states
 * @param {Object} dependencies - Epic dependencies containing getNexusReportService
 * @returns {Observable} Stream of actions (success or failure)
 */
export const fetchTaskFiltersEpic: AppEpic = (
  action$,
  state$,
  { getNexusReportService }
) =>
  action$.pipe(
    filter(isActionOf(fetchMasterData.request)),
    waitForToken(state$),
    mergeMap((_) => {
      const nexusReportService = getNexusReportService(state$.value);
      return from(nexusReportService.getMasterData()).pipe(
        mergeMap((res) => of(fetchMasterData.success(res))),
        catchError((e) => of(fetchMasterData.failure(e)))
      );
    })
  );

/**
 * Epic that handles fetching master data on workspace unfocus.
 * @param {Observable} action$ - Stream of actions
 * @param {Observable} state$ - Stream of states
 * @param {Object} dependencies - Epic dependencies containing getNexusReportService
 * @returns {Observable} Stream of actions (success or failure)
 */
export const fetchMasterdataOnWorkspaceUnfocusEpic: AppEpic = (
  action$,
  state$,
  { getNexusReportService }
) =>
  action$.pipe(
    filter(isActionOf(unfocusWorkspaces)),
    waitForToken(state$),
    mergeMap((_) => {
      //Do nothing when there is no active project
      if (!state$.value.context.workspaces.selectedProject) return EMPTY;
      const nexusReportService = getNexusReportService(state$.value);
      return from(nexusReportService.getMasterData()).pipe(
        mergeMap((res) => of(fetchMasterData.success(res))),
        catchError((e) => of(fetchMasterData.failure(e)))
      );
    })
  );

/**
 * Epic that handles fetching tasks with pagination.
 * @param {Observable} action$ - Stream of actions
 * @param {Observable} state$ - Stream of states
 * @param {Object} dependencies - Epic dependencies containing getNexusTaskService
 * @returns {Observable} Stream of actions (success or failure)
 */
export const fetchTasksEpic: AppEpic = (
  action$,
  state$,
  { getNexusTaskService }
) =>
  action$.pipe(
    filter(isActionOf(fetchTasks.request)),
    waitForToken(state$),
    debounceTime(DEBOUNCE_CALL),
    mergeMap((action) => {
      const filter = toNexusFilterCursor(action.payload);
      const nexusReportService = getNexusTaskService(state$.value);
      return from(nexusReportService.getAllTasks(filter)).pipe(
        mergeMap((res) =>
          of(
            fetchTasks.success({
              data: res.data,
              total: res.headers.get("X-Total-Count")
                ? parseInt(res.headers.get("X-Total-Count")!)
                : res.data.length,
              next: res.headers.get("link") ?? undefined,
            })
          )
        ),
        catchError((e) => of(fetchTasks.failure(e)))
      );
    })
  );

/**
 * Epic that handles fetching more tasks (pagination).
 * @param {Observable} action$ - Stream of actions
 * @param {Observable} state$ - Stream of states
 * @param {Object} dependencies - Epic dependencies containing getNexusTaskService
 * @returns {Observable} Stream of actions (success or failure)
 */
export const fetchMoreTasksEpic: AppEpic = (
  action$,
  state$,
  { getNexusTaskService }
) =>
  action$.pipe(
    filter(isActionOf(fetchMoreTasks.request)),
    waitForToken(state$),
    mergeMap((_) => {
      const nexusTaskService = getNexusTaskService(state$.value);
      return from(
        nexusTaskService.getMoreData<NexusTaskDto[]>(
          state$.value.context.analytics.tasks!.next
        )
      ).pipe(
        mergeMap((res) =>
          of(
            fetchMoreTasks.success({
              data: res.data,
              total: parseInt(res.headers.get("X-Total-Count")!),
              next: res.headers.get("link") ?? undefined,
            })
          )
        ),
        catchError((e) => of(fetchMoreTasks.failure(e)))
      );
    })
  );

/**
 * Epic that handles deleting scope.
 * @param {Observable} action$ - Stream of actions
 * @param {Observable} state$ - Stream of states
 * @param {Object} dependencies - Epic dependencies containing getNexusTaskService
 * @returns {Observable} Stream of reset actions
 */
export const deleteScopeEpic: AppEpic = (
  action$,
  state$,
  { getNexusTaskService }
) =>
  action$.pipe(
    filter(isActionOf(deleteScope)),
    waitForToken(state$),
    mergeMap((_) => {
      const nexusTaskService = getNexusTaskService(state$.value);
      return from(nexusTaskService.deleteScope()).pipe(
        mergeMap((_) => of(reset())),
        catchError((_) => of(reset()))
      );
    })
  );

/**
 * Converts report filters to Nexus filter cursor format.
 * @param {ReportFilters} filters - The filters to convert
 * @returns {NexusFilterCursor} The converted filter cursor
 */
const toNexusFilterCursor = (filters: ReportFilters): NexusFilterCursor => {
  const nexusFilterCursor: NexusFilterCursor = {};
  if (filters.from) nexusFilterCursor.from = filters.from;
  if (filters.to) nexusFilterCursor.to = filters.to;
  if (filters.filters["team"] && filters.filters["team"].length > 0)
    nexusFilterCursor.team = filters.filters["team"];
  if (filters.filters["status"] && filters.filters["status"].length > 0)
    nexusFilterCursor.status = filters.filters["status"];
  if (filters.filters["label"] && filters.filters["label"].length > 0)
    nexusFilterCursor.label = filters.filters["label"];
  if (filters.filters["assignee"] && filters.filters["assignee"].length > 0)
    nexusFilterCursor.assignee = filters.filters["assignee"];
  if (filters.filters["workspace"] && filters.filters["workspace"].length > 0)
    nexusFilterCursor.workspace = filters.filters["workspace"];
  if (filters.query) nexusFilterCursor.query = filters.query;
  return nexusFilterCursor;
};
