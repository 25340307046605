import { RootState } from "typesafe-actions";
import { AnalyticsState } from "./analytics.reducer";
import { createSelector } from "reselect";

export const selectAnalytics = (state: RootState): AnalyticsState =>
  state.context.analytics;

export const selectTaskStatus = createSelector(
  selectAnalytics,
  (state) => state.taskStatus
);

export const selectTaskStatusByWeek = createSelector(
  selectAnalytics,
  (state) => state.taskStatusByWeek
);

export const selectTaskStatusByTeam = createSelector(
  selectAnalytics,
  (state) => state.taskStatusByTeam
);

export const selectTaskPPC = createSelector(
  selectAnalytics,
  (state) => state.taskPPC
);

export const selectTaskPPCByWeek = createSelector(
  selectAnalytics,
  (state) => state.taskPPCByWeek
);

export const selectTaskPPCByTeam = createSelector(
  selectAnalytics,
  (state) => state.taskPPCByTeam
);

export const selectTaskReason = createSelector(
  selectAnalytics,
  (state) => state.taskReason
);

export const selectTaskReasonByWeek = createSelector(
  selectAnalytics,
  (state) => state.taskReasonByWeek
);

export const selectTaskReasonByTeam = createSelector(
  selectAnalytics,
  (state) => state.taskReasonByTeam
);

export const selectAnalyticsMasterData = createSelector(
  selectAnalytics,
  (state) => state.masterData
);

export const selectTasks = createSelector(
  selectAnalytics,
  (state) => state.tasks
);

// This is a temporary selector to check if the scope is empty based on the amount of workspaces in the master data (scope is empty if there are no workspaces). This will be gone in the future.
export const selectHasEmptyScope = createSelector(
  selectAnalytics,
  (state) => !!(state.masterData?.data?.workspace?.length === 0)
);
