import { NexusService } from "./service";
import { debug } from "debug";
import {
  NexusLabelDto,
  NexusLabelCreateDto,
  NexusLabelUpdateDto,
  Operation,
} from "./types";

const _LOG = debug("hoylu:label:service:log");

export class NexusLabelService extends NexusService {
  protected readonly path: string = "label";

  /**
   * Retrieves all labels for a given scope
   * @param {string} scopeId - The scope ID to fetch labels from (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusLabelDto[]>} Array of label objects
   */
  public async getAllLabels(
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusLabelDto[]> {
    _LOG(`Fetching all labels for scope: ${scopeId || this.scopeId}`);
    return this.getJSON<NexusLabelDto[]>(
      this.scopedPath(scopeId),
      undefined,
      undefined,
      signal
    );
  }

  /**
   * Creates a new label
   * @param {NexusLabelCreateDto} Label - The label data to create
   * @param {string} scopeId - The scope ID to create the label in (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusLabelDto>} The created label
   */
  public async postLabel(
    Label: NexusLabelCreateDto,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusLabelDto> {
    _LOG(`Creating new label in scope: ${scopeId || this.scopeId}`);
    return this.postJSON<NexusLabelDto>(
      this.scopedPath(scopeId),
      Label,
      undefined,
      signal
    );
  }

  /**
   * Retrieves a specific label by ID
   * @param {string} id - The ID of the label to fetch
   * @param {string} scopeId - The scope ID to fetch the label from (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusLabelDto>} The requested label
   */
  public async getLabel(
    id: string,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusLabelDto> {
    _LOG(`Fetching label ${id} from scope: ${scopeId || this.scopeId}`);
    return this.getJSON<NexusLabelDto>(
      this.scopedPath(scopeId, id),
      undefined,
      undefined,
      signal
    );
  }

  /**
   * Updates a label with new data
   * @param {string} id - The ID of the label to update
   * @param {NexusLabelUpdateDto} Label - The updated label data
   * @param {string} scopeId - The scope ID of the label (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusLabelDto>} The updated label
   */
  public async putLabel(
    id: string,
    Label: NexusLabelUpdateDto,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusLabelDto> {
    _LOG(`Updating label ${id} in scope: ${scopeId || this.scopeId}`);
    return this.putJSON<NexusLabelDto>(
      this.scopedPath(scopeId, id),
      Label,
      undefined,
      signal
    );
  }

  /**
   * Patches a label with specific operations
   * @param {string} id - The ID of the label to patch
   * @param {Operation[]} operation - Array of operations to apply
   * @param {string} scopeId - The scope ID of the label (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusLabelDto>} The patched label
   */
  public async patchLabel(
    id: string,
    operation: Operation[],
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusLabelDto> {
    _LOG(`Patching label ${id} in scope: ${scopeId || this.scopeId}`);
    return this.patchJSON<NexusLabelDto>(
      this.scopedPath(scopeId, id),
      operation,
      undefined,
      signal
    );
  }

  /**
   * Deletes a label
   * @param {string} id - The ID of the label to delete
   * @param {string} scopeId - The scope ID of the label (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<void>}
   */
  public async deleteLabel(
    id: string,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<void> {
    _LOG(`Deleting label ${id} from scope: ${scopeId || this.scopeId}`);
    return this.deleteJSON<void>(
      this.scopedPath(scopeId, id),
      undefined,
      signal
    );
  }
}
