import React, { useContext, useEffect, useMemo, useState } from "react";
import { CellProps, Column, Row, useTable } from "react-table";
import { FilterContext, toReportFilters } from "../FilterProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAnalyticsMasterData,
  selectTasks,
} from "../../../state/analytics/analytics.selector";
import {
  fetchMoreTasks,
  fetchTasks,
} from "../../../state/analytics/analytics.actions";
import InfiniteScroll from "react-infinite-scroll-component";
import Styles from "./TaskView.module.css";
import { Icon, preventInteractions } from "@hoylu/client-common";
import Scrollbar from "@hoylu/client-common/dist/esm/assets/css.modules/scrollbar.module.css";
import { DefaultRow } from "./DefaultRow";
import { TextStartHeader } from "./TableHeaders";
import { LocationCell, StatusCell, TitleCell, WeekCell } from "./TableCells";
import { DateTime } from "luxon";
import { NexusTaskDto } from "@hoylu/nexus-service";
import { useI18n } from "../../../utils/hooks/use.i18n";

/**
 * used to render tooltip and forward click to selection cell
 * @param selectionCellIdBase id prefix of selection cell
 * @returns cell renderer
 */
export function useCell<D extends object>(selectionCellIdBase: string) {
  return (cell: CellProps<D, string | number>) => (
    <label htmlFor={`${selectionCellIdBase}_${cell.row.id}`}>
      <span title={`${cell.value}`}>{cell.value}</span>
    </label>
  );
}

export const TaskList = () => {
  const filterContext = useContext(FilterContext);
  const dispatch = useDispatch();
  const t = useI18n("ANALYTICS.");

  const taskData = useSelector(selectTasks);
  const masterData = useSelector(selectAnalyticsMasterData);

  const Cell = useCell<NexusTaskDto>("task_");

  useEffect(() => {
    dispatch(fetchTasks.request(toReportFilters(filterContext)));
  }, [filterContext]);

  const columns: Column<NexusTaskDto>[] = useMemo(
    () => [
      {
        id: "Title",
        Header: <TextStartHeader text={t("TITLE")} />,
        accessor: (r) => ({ title: r.title, id: r.id }),
        width: "20%",
        Cell: (props: CellProps<NexusTaskDto>) => {
          return <TitleCell title={props.value.title} />;
        },
      },
      {
        id: "Description",
        Header: <TextStartHeader text={t("DESCRIPTION")} />,
        accessor: (r) => ({ title: r.description, id: r.id }),
        width: "20%",
        Cell: (props: CellProps<NexusTaskDto>) => {
          return <TitleCell title={props.value.title} />;
        },
      },
      {
        id: "Team",
        Header: t("TEAM"),
        accessor: (teamData) =>
          masterData.data?.team.find((team) => team.id === teamData.teamId)
            ?.name ?? t("NO_TEAM"),
        width: "15%",
        Cell,
      },
      {
        id: "Week",
        Header: t("WEEK"),
        accessor: (r) => {
          if (!r.startDate) return undefined;
          return {
            weekNumber: DateTime.fromISO(r.startDate).weekNumber,
            weekTime: r.startDate,
          };
        },
        width: "10%",
        Cell: (props: CellProps<NexusTaskDto>) => {
          return props.value ? (
            <WeekCell weekNumber={props.value.weekNumber} />
          ) : null;
        },
      },
      {
        id: "StartDate",
        Header: t("START_DATE"),
        accessor: (t) => t.startDate ?? "",
        width: "10%",
        Cell,
      },
      {
        id: "EndDate",
        Header: t("END_DATE"),
        accessor: (t) => t.endDate ?? "",
        width: "10%",
        Cell,
      },
      {
        id: "Status",
        Header: t("STATUS"),
        accessor: (r) => {
          return {
            status: masterData.data?.status.find(
              (status) => status.id === r.statusId
            ),
            reason: masterData.data?.reason.find(
              (reason) => reason.id === r.reasonId
            )?.name,
            id: r.id,
          };
        },
        width: "10%",
        Cell: (props: CellProps<NexusTaskDto>) => {
          return (
            <StatusCell
              status={props.value?.status}
              reason={props.value?.reason}
            />
          );
        },
      },
      {
        id: "Labels",
        Header: t("LABELS"),
        accessor: (r) =>
          r.customLabels
            ?.map((labelId) => {
              const label = masterData.data?.label.find(
                (l) => l.id === labelId
              );
              //Do not show deleted labels, show unknown if label masterdata is not found
              return !label
                ? t("UNKNOWN")
                : !label.isDeleted
                ? label.name
                : undefined;
            })
            ?.filter((l) => !!l)
            ?.join("; ") ?? "",
        width: "15%",
        Cell,
      },
      {
        id: "Workspaces",
        Header: t("WORKSPACES"),
        accessor: (r) => ({ id: r.id, workspaces: r.associatedWorkspaces }),
        width: "15%",
        Cell: (props: CellProps<NexusTaskDto>) => {
          return (
            <LocationCell
              taskId={props.value.id}
              workspaces={props.value.workspaces}
            />
          );
        },
      },
    ],
    [masterData]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: taskData?.data ?? [],
  });

  const [open, setOpen] = useState(false);

  if (!taskData) return null;
  return (
    <div className={Styles.section}>
      <div className={Styles.header} onClick={() => setOpen((o) => !o)}>
        <Icon icon={"hoylu-ui-icons-task-with-checkmark"} />
        <span>
          {t("TASK_LIST")} ({taskData.total ?? 0})
        </span>
        <Icon icon={open ? "hoylu-ui-icons-up" : "hoylu-ui-icons-down"} />
      </div>
      {open && (
        <div className={Styles.list}>
          <div className={Styles.container} {...preventInteractions()}>
            <div {...getTableProps()} className={Styles.table}>
              <div className={Styles.head}>
                {headerGroups.map((headerGroup) => {
                  const groupProps = headerGroup.getHeaderGroupProps();
                  return (
                    <div
                      key={groupProps.key}
                      style={groupProps.style}
                      className={`${Styles.row} ${groupProps.className}`}
                      role={groupProps.role}
                    >
                      {headerGroup.headers.map((column) => {
                        const headerProps = column.getHeaderProps({
                          style: { width: column.width },
                        });
                        return (
                          <div
                            key={headerProps.key}
                            style={headerProps.style}
                            className={`${Styles.cell} ${headerProps.className}`}
                            role={headerProps.role}
                          >
                            {column.render("Header")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              <div
                id="scrollableDiv"
                className={`${Styles.restrictedHeight} ${Scrollbar.default}`}
                {...getTableBodyProps()}
              >
                <InfiniteScroll
                  dataLength={rows.length}
                  next={() =>
                    taskData.next &&
                    dispatch(fetchMoreTasks.request(taskData.next))
                  }
                  hasMore={!!taskData.next}
                  loader={null}
                  scrollableTarget="scrollableDiv"
                >
                  {rows.map((row: Row<NexusTaskDto>) => {
                    prepareRow(row);
                    return <DefaultRow key={row.id} row={row} />;
                  })}
                </InfiniteScroll>
              </div>
            </div>
          </div>
          <div className={Styles.footer}>
            {taskData.total && (
              <span>{t("TASK_COUNT", rows.length, taskData.total)}</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
