import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activateDashboardOption,
  createWorkspace,
  createWorkspaceFromTemplateId,
} from "../state/workspaces/workspaces.actions";
import { useWorkspaceDefaultImage } from "../utils/defaultImages";
import { TemplateCard } from "./cards/template.card";
import { DashboardOption } from "../state/workspaces/DashboardOption";
import { fetchTemplates } from "../state/templates/templates.actions";
import { templatesSelectors } from "../state/templates";
import { defaultModule } from "../utils/defaultModule";
import { QUICK_ACCESS_CATEGORY_ID } from "../services/templates/types";
import { TemplateMetadata } from "../state/templates/types";
import { Button } from "@hoylu/client-common";
import SliderLayout from "./layouts/SliderLayout";
import Styles from "./CreateWorkspaceSection.module.css";
import { getUserName, isPasswordRequired } from "../state/user/user.selector";
import { useI18n } from "../utils/hooks/use.i18n";
import { getCreateBlankWorkspacePayload } from "../utils/create.workspace.utils";
import { WorkspaceType } from "../state/workspaces/WorkspaceType";

export const CreateWorkspaceSection: React.FC = () => {
  const dispatch = useDispatch();
  const userName = useSelector(getUserName);
  const t = useI18n("SECTIONS.");
  const tcw = useI18n("CREATE_WORKSPACES_SECTION.");
  const hasToSetPassword = useSelector(isPasswordRequired);
  const defaultImgSrc = useWorkspaceDefaultImage(WorkspaceType.HOYLU);

  const blankWorkspaceTemplate = {
    templateId: "",
    workspaceId: "",
    name: defaultModule,
  };

  const quickAccessTemplates = useSelector(
    templatesSelectors.quickAccessTemplates
  );
  const allDashboardTemplates: TemplateMetadata[] = [
    blankWorkspaceTemplate,
    ...quickAccessTemplates,
  ];

  const seeMoreBtnStyles = {
    fontWeight: "700",
    backgroundColor: "transparent",
    color: "var(--global--accent-deep--Color)",
    width: "fit-content",
    padding: 0,
    margin: 0,
  };

  useEffect(() => {
    dispatch(fetchTemplates.request({ categoryId: QUICK_ACCESS_CATEGORY_ID }));
  }, [dispatch]);

  const createFromTemplate = (templateId: string) => {
    dispatch(createWorkspaceFromTemplateId.request({ templateId }));
  };

  const createBlankWorkspace = () => {
    if (hasToSetPassword) {
      dispatch(
        activateDashboardOption({
          optionType: DashboardOption.CREATE,
        })
      );
    } else {
      dispatch(createWorkspace.request(getCreateBlankWorkspacePayload()));
    }
  };

  const openAssetPanel = () => {
    dispatch(
      activateDashboardOption({
        optionType: DashboardOption.ASSET_PANEL,
      })
    );
  };

  return (
    <>
      <div className={Styles.createWorkspaceSection}>
        <div className={Styles.greetingsContainer}>
          <span className={Styles.greetings}>
            {t("START_FROM_TEMPLATE.TITLE", userName)}
          </span>
          <Button additionalStyles={seeMoreBtnStyles} onClick={openAssetPanel}>
            {t("START_FROM_TEMPLATE.BUTTON")} &gt;
          </Button>
        </div>
        <SliderLayout>
          {allDashboardTemplates.map((t, i) => (
            <TemplateCard
              key={`${t.name}-${i}`}
              imageURL={t.name === defaultModule ? "" : defaultImgSrc}
              templateName={
                t.name === defaultModule
                  ? tcw("MODULES.BLANK_WORKSPACE")
                  : t.name
              }
              tooltip={tcw("CREATE") + " "}
              onClick={() =>
                t.templateId && t.name != defaultModule
                  ? createFromTemplate(t.templateId)
                  : createBlankWorkspace()
              }
              workspaceId={t.workspaceId}
              enabled={true}
              isDefaultModule={t.name === defaultModule}
            />
          ))}
        </SliderLayout>
      </div>
    </>
  );
};
