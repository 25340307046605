import { NexusService } from "./service";
import { debug } from "debug";
import {
  NexusFilterCursor,
  PpcResult,
  PpcTeamResult,
  PpcWeekResult,
  ReportResult,
  TeamStatusReportResult,
  WeekStatusReportResult,
  TeamReasonReportResult,
  WeekReasonReportResult,
  MasterData,
} from "./types";

const _LOG = debug("hoylu:report:service:log");

export class NexusReportService extends NexusService {
  protected readonly path: string = "report";

  /**
   * Fetches PPC report data
   * @param {NexusFilterCursor} filter - Filter criteria for the report
   * @param {string} scopeId - Optional scope ID (defaults to current scope)
   * @param {AbortSignal} signal - Optional abort signal to cancel request
   * @returns {Promise<PpcResult[]>} Array of PPC results
   */
  public async getPpcReport(
    filter: NexusFilterCursor = {},
    scopeId?: string,
    signal?: AbortSignal
  ): Promise<PpcResult[]> {
    _LOG(`Fetching PPC report for scope: ${scopeId ?? this.scopeId}`);
    return this.getJSON<PpcResult[]>(
      this.scopedPath(scopeId, "ppc"),
      filter,
      undefined,
      signal
    );
  }

  /**
   * Fetches PPC report data grouped by team
   * @param {NexusFilterCursor} filter - Filter criteria for the report
   * @param {string} scopeId - Optional scope ID (defaults to current scope)
   * @param {AbortSignal} signal - Optional abort signal to cancel request
   * @returns {Promise<PpcTeamResult[]>} Array of team-based PPC results
   */
  public async getPpcReportByTeam(
    filter: NexusFilterCursor = {},
    scopeId?: string,
    signal?: AbortSignal
  ): Promise<PpcTeamResult[]> {
    _LOG(`Fetching PPC by team report for scope: ${scopeId ?? this.scopeId}`);
    return this.getJSON<PpcTeamResult[]>(
      this.scopedPath(scopeId, "ppc-by-team"),
      filter,
      undefined,
      signal
    );
  }

  /**
   * Fetches PPC report data grouped by week
   * @param {NexusFilterCursor} filter - Filter criteria for the report
   * @param {string} scopeId - Optional scope ID (defaults to current scope)
   * @param {AbortSignal} signal - Optional abort signal to cancel request
   * @returns {Promise<PpcWeekResult[]>} Array of week-based PPC results
   */
  public async getPpcReportByWeek(
    filter: NexusFilterCursor = {},
    scopeId?: string,
    signal?: AbortSignal
  ): Promise<PpcWeekResult[]> {
    _LOG(`Fetching PPC by week report for scope: ${scopeId ?? this.scopeId}`);
    return this.getJSON<PpcWeekResult[]>(
      this.scopedPath(scopeId, "ppc-by-week"),
      filter,
      undefined,
      signal
    );
  }

  /**
   * Fetches status report data
   * @param {NexusFilterCursor} filter - Filter criteria for the report
   * @param {string} scopeId - Optional scope ID (defaults to current scope)
   * @param {AbortSignal} signal - Optional abort signal to cancel request
   * @returns {Promise<ReportResult[]>} Array of status report results
   */
  public async getStatusReport(
    filter: NexusFilterCursor = {},
    scopeId?: string,
    signal?: AbortSignal
  ): Promise<ReportResult[]> {
    _LOG(`Fetching status report for scope: ${scopeId ?? this.scopeId}`);
    return this.getJSON<ReportResult[]>(
      this.scopedPath(scopeId, "status"),
      filter,
      undefined,
      signal
    );
  }

  /**
   * Fetches status report data grouped by team
   * @param {NexusFilterCursor} filter - Filter criteria for the report
   * @param {string} scopeId - Optional scope ID (defaults to current scope)
   * @param {AbortSignal} signal - Optional abort signal to cancel request
   * @returns {Promise<TeamStatusReportResult[]>} Array of team-based status results
   */
  public async getStatusReportByTeam(
    filter: NexusFilterCursor = {},
    scopeId?: string,
    signal?: AbortSignal
  ): Promise<TeamStatusReportResult[]> {
    _LOG(
      `Fetching status by team report for scope: ${scopeId ?? this.scopeId}`
    );
    return this.getJSON<TeamStatusReportResult[]>(
      this.scopedPath(scopeId, "status-by-team"),
      filter,
      undefined,
      signal
    );
  }

  /**
   * Fetches status report data grouped by week
   * @param {NexusFilterCursor} filter - Filter criteria for the report
   * @param {string} scopeId - Optional scope ID (defaults to current scope)
   * @param {AbortSignal} signal - Optional abort signal to cancel request
   * @returns {Promise<WeekStatusReportResult[]>} Array of week-based status results
   */
  public async getStatusReportByWeek(
    filter: NexusFilterCursor = {},
    scopeId?: string,
    signal?: AbortSignal
  ): Promise<WeekStatusReportResult[]> {
    _LOG(
      `Fetching status by week report for scope: ${scopeId ?? this.scopeId}`
    );
    return this.getJSON<WeekStatusReportResult[]>(
      this.scopedPath(scopeId, "status-by-week"),
      filter,
      undefined,
      signal
    );
  }

  /**
   * Fetches reason report data
   * @param {NexusFilterCursor} filter - Filter criteria for the report
   * @param {string} scopeId - Optional scope ID (defaults to current scope)
   * @param {AbortSignal} signal - Optional abort signal to cancel request
   * @returns {Promise<ReportResult[]>} Array of reason report results
   */
  public async getReasonReport(
    filter: NexusFilterCursor = {},
    scopeId?: string,
    signal?: AbortSignal
  ): Promise<ReportResult[]> {
    _LOG(`Fetching reason report for scope: ${scopeId ?? this.scopeId}`);
    return this.getJSON<ReportResult[]>(
      this.scopedPath(scopeId, "reason"),
      filter,
      undefined,
      signal
    );
  }

  /**
   * Fetches reason report data grouped by team
   * @param {NexusFilterCursor} filter - Filter criteria for the report
   * @param {string} scopeId - Optional scope ID (defaults to current scope)
   * @param {AbortSignal} signal - Optional abort signal to cancel request
   * @returns {Promise<TeamReasonReportResult[]>} Array of team-based reason results
   */
  public async getReasonReportByTeam(
    filter: NexusFilterCursor = {},
    scopeId?: string,
    signal?: AbortSignal
  ): Promise<TeamReasonReportResult[]> {
    _LOG(
      `Fetching reason by team report for scope: ${scopeId ?? this.scopeId}`
    );
    return this.getJSON<TeamReasonReportResult[]>(
      this.scopedPath(scopeId, "reason-by-team"),
      filter,
      undefined,
      signal
    );
  }

  /**
   * Fetches reason report data grouped by week
   * @param {NexusFilterCursor} filter - Filter criteria for the report
   * @param {string} scopeId - Optional scope ID (defaults to current scope)
   * @param {AbortSignal} signal - Optional abort signal to cancel request
   * @returns {Promise<WeekReasonReportResult[]>} Array of week-based reason results
   */
  public async getReasonReportByWeek(
    filter: NexusFilterCursor = {},
    scopeId?: string,
    signal?: AbortSignal
  ): Promise<WeekReasonReportResult[]> {
    _LOG(
      `Fetching reason by week report for scope: ${scopeId ?? this.scopeId}`
    );
    return this.getJSON<WeekReasonReportResult[]>(
      this.scopedPath(scopeId, "reason-by-week"),
      filter,
      undefined,
      signal
    );
  }

  /**
   * Fetches master data for reports
   * @param {string} scopeId - Optional scope ID (defaults to current scope)
   * @param {AbortSignal} signal - Optional abort signal to cancel request
   * @returns {Promise<MasterData>} Master data for reports
   */
  public async getMasterData(
    scopeId?: string,
    signal?: AbortSignal
  ): Promise<MasterData> {
    _LOG(`Fetching master data for scope: ${scopeId ?? this.scopeId}`);
    return this.getJSON<MasterData>(
      this.scopedPath(scopeId, "master-data"),
      undefined,
      undefined,
      signal
    );
  }
}
