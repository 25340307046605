import { AnyAction, combineReducers } from "redux";

import configReducer from "./config/config.reducer";
import workspacesReducer from "./workspaces/workspaces.reducer";
import templateReducer from "./templates/templates.reducer";
import userReducer from "./user/user.reducer";
import errorReducer from "./error/error.reducer";
import analyticsReducer from "./analytics/analytics.reducer";
import modeReducer, { AppMode } from "./mode/mode.reducer";
import { StateType } from "typesafe-actions";

const contextReducer = combineReducers({
  user: userReducer,
  config: configReducer,
  workspaces: workspacesReducer,
  error: errorReducer,
  templates: templateReducer,
  analytics: analyticsReducer,
});

export type AppContext = StateType<typeof contextReducer>;
export type AppState = {
  mode: AppMode;
  context: AppContext;
};

export default function rootReducer(
  state: AppState | undefined,
  action: AnyAction
): AppState {
  return {
    mode: modeReducer(state, action),
    context: contextReducer(state?.context, action)
  };
}
