import React from "react";
import MenuItem from "./MenuItem";
import Styles from "./MoreOptions.module.css";
import type { MenuItemSettings } from "../cards/WorkspaceCard/WorkspaceCard";

type MoreOptionsProps = {
  menuItems: MenuItemSettings[];
};

export const MoreOptions: React.FC<MoreOptionsProps> = ({ menuItems }) => {
  return (
    <div className={Styles.moreOptions}>
      {menuItems.map((item) => (
        <MenuItem
          key={item.icon}
          icon={item.icon}
          label={item.label}
          title={item.title}
          onClick={item.onClick}
        />
      ))}
    </div>
  );
};
