import { useDispatch } from "react-redux";
import { cancelDashboardOption } from "../../state/workspaces/workspaces.actions";
import { ConfirmationDialog } from "./ConfirmationDialog";
import React from "react";
import { deleteScope } from "../../state/analytics/analytics.actions";
import { useI18n } from "../../utils/hooks/use.i18n";

export const DropScopeDialog = () => {
  const dispatch = useDispatch();
  const t = useI18n("DELETE_SCOPE_DIALOG.");

  return (
    <ConfirmationDialog
      icon={"hoylu-ui-icons-trash"}
      title={t("TITLE")}
      message={t("MESSAGE")}
      confirmWithCheckmark={true}
      confirmDelete={t("CONFIRM_DELETE")}
      buttonText={t("BUTTON_TEXT")}
      cancelAction={() => dispatch(cancelDashboardOption())}
      submitAction={() => {
        dispatch(deleteScope());
        dispatch(cancelDashboardOption());
      }}
    />
  );
};
