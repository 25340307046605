import { NexusService } from "./service";
import { debug } from "debug";
import { NexusTaskRelationDto, NexusTaskRelationCreateDto } from "./types";

const _LOG = debug("hoylu:task-relation:service:log");

export class NexusTaskRelationsService extends NexusService {
  protected path: string = "task-relations";

  /**
   * Retrieves all task relations for a given scope
   * @param {string} scopeId - The scope ID to fetch relations from (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusTaskRelationDto[]>} Array of task relation objects
   */
  public async getAllTasksRelations(
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusTaskRelationDto[]> {
    _LOG(`Fetching all task relations for scope: ${scopeId || this.scopeId}`);
    return this.getJSON<NexusTaskRelationDto[]>(
      this.scopedPath(scopeId),
      undefined,
      undefined,
      signal
    );
  }

  /**
   * Creates a new task relation
   * @param {NexusTaskRelationCreateDto} taskRelation - The task relation data to create
   * @param {string} scopeId - The scope ID to create the relation in (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<void>}
   */
  public async postTaskRelation(
    taskRelation: NexusTaskRelationCreateDto,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<void> {
    _LOG(`Creating task relation in scope: ${scopeId || this.scopeId}`);
    return this.postJSON<void>(
      this.scopedPath(scopeId),
      taskRelation,
      undefined,
      signal
    );
  }

  /**
   * Retrieves a specific task relation by ID
   * @param {string} id - The ID of the task relation to fetch
   * @param {string} scopeId - The scope ID to fetch the relation from (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusTaskRelationDto>} The requested task relation
   */
  public async getTaskRelations(
    id: string,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusTaskRelationDto> {
    _LOG(`Fetching task relation ${id} from scope: ${scopeId || this.scopeId}`);
    return this.getJSON<NexusTaskRelationDto>(
      this.scopedPath(scopeId, id),
      undefined,
      undefined,
      signal
    );
  }

  /**
   * Deletes a task relation between two tasks
   * @param {string} inwardId - The ID of the inward task
   * @param {string} outwardId - The ID of the outward task
   * @param {string} scopeId - The scope ID of the relation (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<void>}
   */
  public async deleteTaskRelation(
    inwardId: string,
    outwardId: string,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<void> {
    _LOG(
      `Deleting task relation between ${inwardId} and ${outwardId} in scope: ${
        scopeId || this.scopeId
      }`
    );
    return this.deleteJSON<void>(
      this.scopedPath(scopeId, inwardId, outwardId),
      undefined,
      signal
    );
  }
}
