export const English = {
  ACCOUNT_VERIFICATION_ERROR: {
    SKIP: "Skip",
    VERIFIY_EMAIL: "Verify your email address",
    WE_NOTICED:
      "We noticed that you need to verify your email address. To do so, simply click the button below.",
    WITHOUT_VERIFICATION:
      "Without verification, you will not be able to access Hoylu Workspaces.",
  },
  APP: {
    LOADING_YOUR_WORKSPACE: "Loading Your Workspaces",
    READ_ONLY: "Read only",
  },
  ASSET_PANEL: {
    USE_EXPANDED_VIEW: "Use extended view",
    USE_COMPACT_VIEW: "Use compact view",
  },
  BETA_DISABLED_CONTENT: {
    BETA_PERIOD_ENDED: "The beta period has ended.",
    TAKE_ME: "Take me to Hoylu",
    THANK_YOU: "Thank you for your interest in the Hoylu beta.",
  },
  CENTERED_CONTENT_WITH_LOGO: {
    HOYLU_LOGO: "Hoylu Logo",
    LEFT_PATH_ICON: "Left path icon",
    RIGHT_PATH_ICON: "Right path icon",
  },
  ANALYTICS: {
    TITLE: "Title",
    DESCRIPTION: "Description",
    TEAM: "Team",
    WEEK: "Start Week",
    START_DATE: "Start Date",
    END_DATE: "End Date",
    STATUS: "Status",
    LABELS: "Labels",
    WORKSPACES: "Workspaces",
    NO_TEAM: "No Team assigned",
    TASK_LIST: "Task List",
    TASK_COUNT: "${0} of ${1}",
    DATE_FROM: "Date from",
    DATE_TO: "Date to",
    REFRESH: "Refresh",
    TASKS: "Tasks",
    TOTAL: "Total",
    PERCENT_COMPLETE: "Percent complete",
    COUNT: "Count",
    NO_DATA: "No data",
    LOAD_ERROR: "Error loading data",
    LOCATIONS_TOOLTIP: "Show list of workspaces the element is in",
    LOCATIONS_LIST: "Workspace List",
  },
  CHANGE_MASTER_DIALOG: {
    TITLE: "Link Template to new Master workspace",
    SUBTITLE: "Enter new workspace ID or paste link to new master:",
    CANCEL: "Cancel",
    CHANGE_MASTER: "Change Master",
  },
  CLOSE_BUTTON: {
    CLOSE: "Close",
  },
  CONFIRMATION_DIALOG: {
    CANCEL: "Cancel",
    OPTION_ICON: "option icon",
    PASSWORD: "Password",
  },
  CONFIRMATION_DRAWER_CONTENT: {
    CANCEL: "Cancel",
    OPTION_ICON: "option icon",
    PASSWORD: "Password",
  },
  DELETE_SCOPE_DIALOG: {
    TITLE: "Drop Scope",
    MESSAGE:
      "This operation will reset all the reporting data for this project. \n" +
      "Individual workspaces are NOT affected by this operation.\n" +
      "You will need to re-sync workspaces to use cross-project reporting again.\n",
    CONFIRM_DELETE: "I confirm that I want to drop this scope",
    BUTTON_TEXT: "Drop",
  },
  COPY_EMAIL_LINK: {
    COPIED_LINK: "Copied Link",
    COPY_LINK: "Copy Link",
    COPY_WORKSPACE_LINK: "Copy Workspace Link",
    EMAIL: "Email Link",
    EMAIL_WORKSPACE_LINK: "Email Workspace Link",
    LEARN_MORE:
      "To learn more about Hoylu and create an account, please visit Hoylu.com.",
    SMALL_COPIED_LINK: "Copied",
    SMALL_COPY_LINK: "Copy",
    SMALL_EMAIL: "Email",
    WORKSPACE_SHARED_WITH_YOU: "A Hoylu Workspace has been shared with you.",
  },
  CREATE_WORKSPACES_SECTION: {
    CREATE: "Create",
    CREATE_WORKSPACE: "Create Workspace",
    FROM_TEMPLATE: "From Template",
    MODULES: {
      BLANK_WORKSPACE: "Blank Workspace",
      BUSINESS_MODEL_CANVAS: "Business Model Canvas",
      KANBAN: "Kanban",
      PI_PLANNING: "PI Planning",
      PULL_PLANNING: "Pull Planning",
      AGILE_PLANNING: "Agile Planning",
      RETROSPECTIVE: "Retrospective",
      SIMPLE_DRAWING: "Simple Drawing",
      SUPPLY_CHAIN: "Supply Chain",
      SWOT_ANALYSIS: "SWOT Analysis",
      VALUE_PROPOSITION_CANVAS: "Value Proposition Canvas",
      WEEKLY_PLANNER: "Weekly Planner",
      WORKSPACE_IDEAS: "Workspace Ideas",
    },
    OPEN: "Open",
    TOOLTIPPOSTFIX: "\n*Hoylu Enterprise feature",
    TOGGLE_SHOW_ALL: "toggle show all workspaces",
    UPSELL: {
      AVAILABLE_WITH_ENTERPRISE:
        "This module is available with the Enterprise Plan",
      CONTACT_US: "Contact us",
      NEED_MORE_WORKSPACES: "Need more workspaces?",
      REACHED_LIMIT:
        "You have reached your limit of free workspaces. Upgrade to Hoylu Plus or contact your Project Administrator to get added as a contributor to a project to unlock unlimited workspaces, full sharing and much more.\n",
      UPGRADE_TO_ENTERPRISE:
        "To try it out, upgrade your plan to an Enterprise account, or contact us for a demonstration and to learn more.",
      UPGRADE_TO_PLUS: "Upgrade to the Plus Plan",
      AVAILABLE_WITH_PLUS: "This feature is available with the Plus Plan",
      UNLOCK_WITH_PLUS:
        "Upgrade to Hoylu Plus and unlock unlimited workspaces, full sharing and much more.",
    },
    VIEW_LESS: "view less",
    VIEW_MORE: "view more",
  },
  DELETE_WORKSPACE_DIALOG: {
    CONFIRM_DELETE: "Yes, permanently delete this Workspace",
    DELETE: 'Delete "${0}"',
    WARNING:
      "Are you sure you want to delete ${0}? The workspace will be removed from Hoylu immediately. This cannot be undone.",
  },
  INFO_SECTION_REMOVE_WORKSPACE_DIALOG: {
    HIDE_WORKSPACE:
      "Hide workspace (Will remain on dashboard as hidden workspace)",
    LEAVE_WORKSPACE:
      "Leave Workspace (Will no longer be available on your dashboard)",
    DELETE_WORKSPACE:
      "Delete Workspace (Permanently deletes workspace for ALL users)",
    HIDE: "Hide Workspace",
    LEAVE: "Leave Workspace",
    DELETE: "Delete Workspace",
    CANCEL: "Cancel",
    TITLE: "Remove Workspace",
  },
  DELETE_TEMPLATE_DIALOG: {
    TITLE: "Remove Template",
    SUBTITLE:
      "The template will no longer be available in the Template Panel. \n" +
      "The linked Master workspace will become a regular workspace again.",
    CANCEL: "Cancel",
    CONTINUE: "Continue",
  },
  DELETE_WORKSPACE_DRAWER: {
    CONFIRM_DELETE: "Yes, permanently delete this Workspace",
    DELETE: 'Delete "${0}"',
    WARNING:
      "Are you sure you want to delete ${0}? The workspace will be removed from Hoylu immediately. This cannot be undone.",
  },
  DUPLICATE_WORKSPACE_DIALOG: {
    CANCEL: "Cancel",
    COPY_OF: "Copy of",
    COPY_THIS_WORKSPACE: "Copy this Workspace",
    DUPLICATE: "Duplicate",
    MAKE_COPY: "Make a copy of",
    NEW_WORKSPACE_NAME: "New Workspace Name",
    OPTION_ICON: "option icon",
    PASSWORD: "Password",
    CHARACTERS: "at least 8 characters",
    PRIVATE_WORKSPACE: 'Set Link sharing permission to "no access"',
    PRIVATE_WORKSPACE_TOOLTIP:
      "Workspace is set to private with no access via workspace link.\n" +
      'This can later be changed in the "share" settings of the workspace.',
    SHOW_PASSWORD: "show password",
    NEW_PASSWORD: "New workspace password",
  },
  EDIT_LABEL_DIALOG: {
    LABELS: "Labels",
    ENTER_LABEL: "Enter Label",
    ADD: "Add",
    REMOVE: "Remove",
    LOADING_WORKSPACES: "Loading",
  },
  ERROR: {
    CANNOT_APPLY_CHANGES:
      "We cannot apply your changes to the workspace at this time. Please try again later.",
    CANNOT_CREATE_WORKSPACE:
      "We cannot create a workspace at this time. Please try again later.",
    CANNOT_DELETE_WORKSPACE:
      "We cannot delete this workspace. Please try again later.",
    CANNOT_DUPLICATE_WORKSPACE:
      "We cannot duplicate this workspace at this time. Please try again later.",
    CANNOT_HIDE_WORKSPACE:
      "We cannot hide this workspace. Please try again later.",
    CANNOT_UNHIDE_WORKSPACE:
      "We cannot unhide this workspace. Please try again later.",
    CANNOT_REMOVE_PASSWORD:
      "We cannot remove password for this workspace. Please try again later.",
    CANNOT_SET_PASSWORD:
      "We cannot set password for this workspace. Please try again later.",
    FAILED_DETECT_TEAMS: "failed to set teams settings",
    INCORRECT_PASSWORD: "Incorrect password. Please try again.",
    INCORRECT_WORKSPACE_PASSWORD:
      "Incorrect workspace password. Please try again.",
    SOMETHING_WENT_WRONG:
      "Something went wrong, our engineers are working on it!",
    VERIFY_WORKSPACE_ID:
      "Please verify the workspace ID and that you have permission to open this workspace.",
    WORKSPACE_DELETED: "workspace deleted",
    WORKSPACE_HAS_BEEN_DELETED:
      "The workspace you are searching for has been deleted. Try searching for a different workspace",
    PROJECTS: {
      INSUFFICIENT_PERMISSIONS_TO_REMOVE_WORKSPACE:
        "Cannot remove this workspace from the project. Insufficient permissions. Contact project admin.",
      INSUFFICIENT_PERMISSIONS_TO_ADD_WORKSPACE:
        "Cannot assign this workspace to the project. Insufficient permissions. Contact project admin.",
      CANNOT_ADD_WORKSPACE:
        "Cannot assign this workspace to the project. Reason:",
      REASON_MAX_WORKSPACES: "Max. workspace limit is reached for this project",
    },
  },
  ERROR_DIALOG: {
    OK: "Ok",
  },
  ERROR_FETCHING_WORKSPACE: {
    ERROR_FETCHING_WORKSPACES: "Error Fetching Workspaces",
    TRY_AGAIN: "Try Again",
  },
  LOAD_MORE_WORKSPACES: {
    LOAD_MORE: "Load More",
  },
  FILTER_BY_NAME: {
    FILTER_BY_NAME: "Filter by name",
    SEARCH: "Search",
    SELECT_PROJECT: "Select Project",
    LOADING_WORKSPACES: "Loading",
    MAGNIFYING_GLASS: "magnifying glass",
    DASHBOARD: "Dashboard",
    WORKSPACES: "Workspaces",
    PROJECT_SETTINGS: "Project Settings",
    PROJECT_SETTINGS_LABEL: "Open Project Settings...",
    NEW_PROJECT: "New Project",
    INACTIVE: "INACTIVE",
  },
  FILTER_BY_WORKSPACE_LABEL: {
    FILTER_BY_LABELS: "Filter by Labels",
    LOADING_WORKSPACES: "Loading",
    FILTER: "Filter",
    APPLY: "Apply",
    SHOW_HIDDEN_WORKSPACES: "Show Hidden Workspaces",
  },
  HIDE_WORKSPACE_DIALOG: {
    HIDE: "Hide",
    WARNING: "This workspace will be hidden from your dashboard",
  },
  LEAVE_WORKSPACE_DIALOG: {
    LEAVE: "Leave",
    WARNING:
      "The workspace will be removed from dashboard and will no longer count against credit limit.\n" +
      "If added, you will also be removed from workspace permission list.",
  },
  HIDE_WORKSPACE_DRAWER: {
    HIDE: "Hide",
    WARNING: "This workspace will be hidden from your dashboard",
  },
  HOYLU_LOGO: {
    HOYLU_DASHBOARD: "Hoylu Dashboard",
    HOYLU_LOGO: "Hoylu Logo",
  },
  KANBAN_CONFIGURATION: {
    SET_COLUMNS: "Set number of columns",
  },
  LINKED_MASTER_DIALOG: {
    TITLE: "Linked Master Workspace",
    SUBTITLE: "The template is currently linked to:",
    NAME_FIELD: "Workspace name:",
    ID_FIELD: "Workspace ID:",
    CANCEL: "Cancel",
    CHANGE_MASTER: "Change Master",
    EDIT_CONTENT: "Edit Content",
  },
  LOGIN_PROMPT: {
    AGREE: "I agree to Hoylu's",
    BACK_TO_LOGIN: "Back to login",
    ENTER_AS_GUEST: "Enter as Guest",
    ENTER_EMAIL: "Enter email",
    JOIN: "Join",
    LOGIN: "Login",
    OR: "or",
    SIGN_UP: "Sign Up",
    TERMS: "Terms of Use",
    WELCOME_TO_HOYLU: "Welcome to Hoylu",
  },
  LOADING_WORKSPACE_DIALOG: {
    LOADING_WORKSPACE: "Loading Workspace",
    LOADED: "loaded",
    PROCESS: "processed",
    RENDER: "rendered",
    STEP: "Step",
  },
  MAC_APP: {
    UPSELL_TITLE: "Need More Workspaces?",
    UPSELL_TEXT:
      "You’ve reached your limit of available workspaces. Please visit Hoylu.com or talk to your administrator for additional workspaces.",
    UPSELL_BUTTON: "Hoylu.com",
  },
  NEW_WORKSPACE_DIALOG: {
    CHARACTERS: "at least 8 characters",
    CONTINUE: "Continue",
    CREATE_WORKSPACE: "Create Workspace",
    PASSWORD: "Password",
    SHOW_PASSWORD: "show password",
    NEW_PASSWORD: "New workspace password",
  },
  NOTIFICATIONS: {
    TITLE: "Notifications",
    NAVIGATE_TO_ELEMENT: "Navigate to element",
    CLOSE_BUTTON: "Close",
    SHOW_ALL_MESSAGES: "Show all messages",
    HIDE_READ: "Hide read",
    MARK_ALL_AS_READ: "Mark all Notifications as read",
    NOTIFICATION_BADGE: "Notification badge",
    SUBSCRIBE:
      "Click here to get informed about changes with in-App Notifications.\nCtrl + Click here to get informed in-App and via Summary E-mails.",
    UNSUBSCRIBE: "Click here to stop receiving notifications for this element.",
    NO_MORE_MESSAGES: "No more messages",
  },
  ORG_BANNER: {
    ORGANIZATION_BANNER: "Organization Banner",
  },
  CREDIT_LIMIT_WARNING_BANNER: {
    TITLE: "You are over your free account limit.",
    ADD_TO_PROJECT: "add to project...",
    BUTTON: "Upgrade",
  },
  ORG_BLOCKED_CREATE_DIALOG: {
    TITLE: "Can't Create Workspace",
    TEXT:
      "Your organization has activated the policy to only allow administrators to create a workspace. Please contact an organization administrator, that manages Hoylu access, for further details.",
  },
  CREDIT_LIMIT_REACHED_DIALOG: {
    TITLE: "You are over your free account limit.",
    BUTTON_TEXT: "Upgrade",
  },
  TEXT_INPUT: {
    CLEAR: "Clear",
    TOGGLE_PASSWORD_VISIBILITY: "Toggle password visibility",
  },
  PERMISSION_BAR: {
    ACCESS: "access",
    ANYONE_WITH_LINK_IN_ORG: "Anyone in my organisation with this link",
    AUTHENTICATED_ACCOUNTS_READ_ONLY_ACCESS:
      "Authenticated accounts that are outside of the organization will have read-only access to the workspaces.\n" +
      "Guest (unauthenticated) read-only access to workspaces is also enabled.",
    AUTHENTICATED_ACCOUNTS_NO_ACCESS:
      "Authenticated accounts that are outside of the organization will have no access to organization workspaces.\n" +
      "There is no guest (unauthenticated) access to organization workspaces.",
    HAS: "has",
    ONLY_ADMINS_CHANGE_ACCESS:
      "Only workspace admins can change access permissions",
    REMOVE_USER: "Remove User",
  },
  PI_CONFIGURATION: {
    SET_ITERATIONS: "Set number of iterations",
  },
  INVITE_USERS: {
    INVITE_USER: "Invite Users",
    INVITE_USERS_HEADER: "Invite to Hoylu",
    INVITE_USERS_DESCRIPTION:
      "Enter e-mail addresses of people you would like to invite to join Hoylu.\nInvites will only be sent to e-mail addresses without a Hoylu account.",
    INVITE: "Invite",
    CANCEL: "Cancel",
    ERR: "Invalid email detected",
    ERR_QTY: "You can send up to 10 emails at once",
    HINT:
      "You can invite multiple people by passing multiple email address separated with whitespace",
  },
  SHARED_WORKSPACE: {
    ENTER_ID: "Enter workspace ID or paste link",
    MAGNIFYING_GLASS: "magnifying glass",
    OPEN: "Open",
    OPEN_SHARED_WORKSPACE: "Open shared workspace",
  },
  SHARE_TYPE: {
    ADMIN: "admin",
    EDIT: "edit",
    NO_ACCESS: "no",
    READ: "read",
    REMOVE: "remove",
    // todo - needs German version
    ADMIN_TOOLTIP:
      "User has full access to setting</br>permissions, configuring and</br>editing the workspace.",
    EDIT_TOOLTIP:
      "User can edit content, but has</br>limited ability to configure</br>workspace.",
    READ_TOOLTIP:
      "User can only view the workspace</br>content, but link-sharing settings</br>may override this restriction.",
    NO_ACCESS_TOOLTIP:
      "User has no access to workspace</br>but link-sharing settings may</br>override this restriction.",
    EDIT_TOOLTIP_LINK:
      "Anyone with access to the</br>workspace can edit its content.",
    READ_TOOLTIP_LINK:
      "Anyone with access to the</br>workspace can read its content.",
    NO_ACCESS_TOOLTIP_LINK:
      "Only users with explicit permissions</br>can access the workspace.",
  },
  SHARE_WORKSPACE_DIALOG: {
    ANYONE_WITH_LINK: "Anyone with this link",
    LINK_SHARING: "Link Sharing",
    PERMISSIONS: "Permissions",
    WORKSPACE_INFO: "Workspace Info",
    CHANGE_THUMBNAIL: "Change Thumbnail",
    SHARE_THIS_WORKSPACE: "Share this workspace",
    REMOVE_WORKSPACE: "Remove Workspace",
    REMOVE_TEMPLATE: "Remove Template",
    REMOVE_FROM_PROJECT: "Remove from Project",
    MANDATORY_FIELD: "This field is required",
    CHARACTER_LIMIT: "Title is too long",
    WORKSPACE_TITLE: "Workspace Title",
    TEMPLATE_TITLE: "Template Title",
    TEMPLATE_DESCRIPTION_TITLE: "Template Description",
    TEMPLATE_DESCRIPTION_PLACEHOLDER: "Add description here",
    TEMPLATE_CATEGORY_TITLE: "Publish in Categories",
    TEMPLATE_CATEGORY_PLACEHOLDER: "Category",
    TEMPLATE_INFO: "Template Info",
    LINKED_MASTER: "Linked Master",
    SAVE: "Save",
    PUBLISH_AS_TEMPLATE: "Publish as template",
    PUBLISH_AS_TEMPLATE_NOT_ALLOWED:
      "This workspace is linked to another organization and cannot be published.\n" +
      "To publish this workspace as a template, please duplicate it and then publish the duplicate.",
    PUBLISH: "Publish",
    PROJECT: "Project",
    CANCEL: "Cancel",
    EDIT: "Edit",
  },
  SEARCH_WORKSPACE_DIALOG: {
    TITLE: "Search Workspaces",
    LABEL: "Label",
    CANCEL: "Cancel",
    SEARCH: "Search",
    LOADING_WORKSPACES: "Loading",
  },
  ASSIGN_PROJECT_DIALOG: {
    ASSIGN_TO_PROJECT: "Assign to Project",
    ASSIGN_PROJECT_DESCRIPTION:
      "Assigning a Workspace to a project will make this workspace show up on all eligible project participants dashboards.",
    SELECT_A_PROJECT: "Select a project",
  },
  REMOVE_WORKSPACE_FROM_PROJECT_DIALOG: {
    TITLE: "Remove ${0} from project",
    MESSAGE: "Are you sure you want to remove ${0} from this project?",
    BUTTON: "Remove",
  },
  SHARING_ICONS: {
    COLLAPSE: "Collapse",
    EXPAND: "Expand",
    LOCKED: "Locked",
    PERMISSIONS: "Permissions",
    UNLOCKED: "Unlocked",
    USERS: "Users",
  },
  TEMPLATE_CARD: {
    TILE_IMAGE: "Tile Image",
  },
  USER_PROFILE_DRAWER: {
    HOYLU_LOGO: "Hoylu Logo",
    LOGOUT: "Logout",
  },
  USER_PROFILE_WIDGET: {
    USER_MARBLE: "User Marble",
    LOGOUT: "Logout",
    INVITE_USER: "Invite Users",
    MANAGEMENT_PORTAL: "Management Portal",
    SWITCH_COLOR_SCHEME: "Switch color scheme",
    THEME_LIGHT_TITLE: "Light",
    THEME_DARK_TITLE: "Dark",
    UPLOAD: "Upload",
    MANAGE_ACCOUNT: "Manage Account",
    UPGRADE: "Upgrade",
    LEARN_MORE: "Learn more...",
    USAGE_UNDER_LIMIT: "You are using ${0}% \n of your free plan.",
    USAGE_OVER_LIMIT: "You are over your \n free account limit.",
    PLAN_TITLE: "(${0} Plan)",
  },
  WHATS_NEW_DIALOG: {
    BROWSER_SUGGESTION:
      "To get the most Hoylu has to offer, we recommend using the latest version of Google Chrome, Apple Safari or Microsoft Edge.",
    CONTINUE: "Continue",
    FEATURES: {
      FEATURE_1: {
        title: "Pull Planning Updates",
        description: `• Improved loading time up to 90 percent.
• Interactive dependency indicators showing the up- and downstream dependencies.
• Support for resolving dependency conflicts.
• Labels in Insights Panel.`,
      },
      FEATURE_2: {
        title: "Rich Text Enhancements",
        description: `• Automatic adapting text box size when editing.
• Bullet-point list support. (Tab or Shift+Tab to toggle the list indentation)
• Support for hyperlinks in text.`,
      },
      FEATURE_3: {
        title: "User Experience Updates",
        description: `• It is now possible to click on the milestone icon open attached link.
• CTRL + click or middle mouse button click on Thumbnail will open workspace in a new tab.`,
      },
    },
    WHATS_NEW: "What's New",
    WHATS_NEW_ICON: "whats new icon",
  },
  WORKSPACE_CARD: {
    ACCESSED: "Accessed:",
    LAST_ACCESSED: "Last Accessed:",
    HIDDEN_WORKSPACE: "Hidden Workspace",
    TEMPLATE: "Hoylu Template",
    MORE_OPTIONS: "More options",
    NOT_YET: "Never",
    OPEN: "Open",
    UNTITLED: "Untitled",
    WORKSPACE_MODIFIED: "Workspace modified since last accessed indicator",
    WORKSPACE_PREVIEW: "workspace preview",
    WORKSPACE_IMG_ALT: "Default thumbnail for workspace",
    CHANGES_SINCE: "Changes since your last visit",
    NO_CHANGES_SINCE: "No changes since your last visit",
    INACTIVE_PROJECT_LABEL:
      "This project is inactive.\nClick for more information.",
    LIMITED_WORKSPACE_LABEL:
      "Free account limit is exceeded.\nNo new elements can be added to this workspace.\nClick for more information.",
    LIMITED_TITLE: "Limited",
    DELETE: "Delete",
    DELETE_WORKSPACE: "Delete Workspace",
    ASSIGN_PROJECT: "Assign to Project",
    REMOVE_PROJECT: "Remove from Project",
    DUPLICATE: "Duplicate",
    ADD_LABEL: "Label",
    LABELS: "Labels",
    DUPLICATE_WORKSPACE: "Duplicate Workspace",
    LEAVE: "Leave",
    LEAVE_WORKSPACE: "Leave Workspace",
    HIDE: "Hide",
    HIDE_WORKSPACE: "Hide Workspace",
    UNHIDE: "Unhide",
    UNHIDE_WORKSPACE: "Unhide Workspace",
    ICON: "icon",
    SHARE: "Share",
    SHARE_SETTINGS: "Share Settings",
    EDIT_TITLE: "Edit title",
    DOWNLOAD_ARCHIVE: "Download archive",
    WORKSPACE_INFO: "Workspace Info",
    EDIT_TEMPLATE: "Edit Template",
    EDIT_TEMPLATE_TOOLTIP:
      "Edit Template workspace. Please note that others are able to create duplicates of the template while you are editing if they have access to the workspace.",
  },
  WORKSPACE_PASSWORD: {
    ADD_PASSWORD: "Add Password",
    ADD_WORKSPACE_PASSWORD: "Add workspace password:",
    CANCEL: "Cancel",
    CHANGE: "Change",
    CHANGE_PASSWORD: "Change password",
    CHANGE_WORKSPACE_PASSWORD: "Change workspace password",
    CHARACTERS: "(at least 8 characters)",
    CONFIRM_NEW_PASSWORD: "Confirm workspace password",
    ENTER_CURRENT_PASSWORD: "Enter current password",
    REMOVE: "Remove",
    REMOVE_PASSWORD: "Remove Password",
    REMOVE_WORKSPACE_PASSWORD: "Remove workspace password",
    SET_NEW_PASSWORD: "Set workspace password",
    WORKSPACE_NO_PASSWORD: "No password set",
    WORKSPACE_PASSWORD_SET: "Workspace password is set",
  },
  WORKSPACE_PERMISSIONS: {
    ACCESS: "access",
    ADD_USERS: "Add Users",
    IMPORT_PROJECT_USERS: "Import Project Users",
    ADD_WITH: "Add with",
    CANCEL: "Cancel",
    NOTIFY_USERS: "Notify users via e-mail",
    USER_PERMISSION: "User permission will be",
    BY_POLICY_ANY_ORG_ADMIN: "By policy any org admin", // By policy any org admin [has] [Admin, Write, Read, No] [access]
    PROJECT_ADMINS: "Project admin", // Project admin [has] [Admin, Write, Read, No] [access]
    ENTER_USER: "Enter user",
    NO_RESULTS_FOUND: "No results found",
    USE_THIS_ADDRESS: "Use this address",
    SUGGESTED_USERS: "Suggested users",
    LOADING: "Loading...",
    FAILED_TO_LOAD_USERS: "Failed to load users.",
    NO_ACCOUNTS_ERROR: "There are no Hoylu accounts for the email(s):",
    INVITE_AND_ADD: "Invite to Hoylu",
  },
  WORKSPACE_TEMPLATES: {
    CREATE: "create",
    PREVIEW: "preview",
    AGILE: "Agile",
    DESIGN: "Design",
    IDEATION: "Ideation",
    BUSINESS: "Business",
    TEMPLATES: "Templates",
  },
  AGILE_PLANNING: {
    PRESET: "Select preset",
  },
  DOWNLOAD_DIALOG: {
    DOWNLOAD: "Download",
    PASSWORD: "Password",
    SHOW_PASSWORD: "Show password",
    CANCEL: "Cancel",
    DOWNLOADING: "Downloading...",
    DOWNLOAD_FAILED: "Download failed.",
    TIMESTAMP_LABEL: "Timestamp - your local time (optional)",
    TIMESTAMP_TOOLTIP:
      "Select a timestamp to download the workspace as it was at that time. Keep empty to download the current state.",
  },
  UPLOAD_DIALOG: {
    UPLOADING_WORKSPACE: "Uploading Workspace",
    LOADED: "loaded",
    PROCESS: "processed",
    STEP: "Step",
    BROWSE: "Browse",
    ERROR: "Failed to upload workspace",
  },
  THUMBNAIL_EDITOR: {
    TITLE: "Workspace Thumbnail",
    CANCEL: "Cancel",
    SAVE: "Save",
    DELETE: "Delete",
    GENERATE_THUMBNAIL: "Generate Thumbnail",
    TAKE_SNAPSHOT: "Take Snapshot",
    TYPE: "Type",
    CUSTOM_IMAGE: "Custom image",
    SNAPSHOT: "SnapShot",
    BROWSE: "Browse",
    ERR: "Only *.png, *.jpg and *.gif with up to 1MB size are supported",
  },
  MANAGE_ACCOUNT: {
    TITLE: "Manage Account",
    EMAIL: "Email",
    CREATED: "Account created on: ",
    DELETE: "Delete Account",
    CLOSE: "Close",
    MANAGE: "Manage",
  },
  CONFIRM_ACCOUNT_DELETE: {
    TITLE: "Delete Account: ",
    P1: "In order to delete your account:",
    POINT1: "Check the confirmation checkbox and",
    POINT2: 'Then click "Delete Account".',
    P2:
      "You will receive an e-mail confirming that Hoylu has received your request and a follow up e-mail with further information.\nHoylu will proceed with permanently deleting your account in 7 days.",
    CONFIRM: "Confirm that deleted accounts cannot be recovered",
    DELETE: "Delete Account",
    CANCEL: "Cancel",
  },
  SECTIONS: {
    HIDDEN: "Hidden",
    FAVORITES: "Favorites",
    PROJECT: {
      COLLABORATORS: "Project collaborators",
      PROJECT_SETTINGS: "Project Settings",
      COPY_LINK: "Copy link to project",
      DASHBOARD: "Project Dashboard",
      REPORT_TYPE: "Report Type",
      DELETE_SCOPE: "Delete Scope",
      RELOAD: "Reload",
    },
    START_FROM_TEMPLATE: {
      TITLE: "Welcome, ${0}!",
      BUTTON: "Browse Templates",
      LABEL: "Start from template",
    },
    WORKSPACES_LIST: "Workspaces list",
  },
  HEADER: {
    NO_ACCESS:
      "Access to project denied. Please contact the Project Administrator",
  },
  VALIDATION: {
    MANDATORY_FIELD: "This field is required",
    CHARACTER_LIMIT: "Title is too long",
  },
  PROJECT: {
    DURATION: "${0} days",
    REMAINING_DURATION: "Remaining Project duration: ${0} days",
  },
};
