import React, { memo } from "react";
import {
  LineChart as NivoLineChart,
  BarChart as NivoBarChart,
  PieChart as NivoPieChart,
  LineChartProps,
  PieChartProps,
  BarChartProps,
} from "@hoylu/nivo-charts";

//This memoization is required due to Chart component state changes, that handles isLoaded and fetch / no data scenarios.
//If we don't memoize this component, it will re-render on every state change in parent component overloading rendering process resulting in glitches.
export const LineChart = memo((props: LineChartProps) => {
  return <NivoLineChart {...props} />;
});

export const PieChart = memo((props: PieChartProps) => {
  return <NivoPieChart {...props} />;
});

export const BarChart = memo((props: BarChartProps) => {
  return <NivoBarChart {...props} />;
});
