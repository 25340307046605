import React from "react";
import Styles from "./TaskView.module.css";

export type HeaderIconProps = {
  icon: string;
  title?: string;
};

export const HeaderIcon = ({ icon, title }: HeaderIconProps) => {
  return <div className={`${Styles.icon} ${icon}`} title={title} />;
};

type TextStartHeaderProps = { text: string };
export const TextStartHeader = ({ text }: TextStartHeaderProps) => {
  return <div className={Styles.textLeft}>{text}</div>;
};
