import { useDispatch, useSelector } from "react-redux";
import { cancelDashboardOption } from "../../state/workspaces/workspaces.actions";
import {
  getDialogPosition,
  getSelectedProjectWorkspaces,
  selectTaskLocationData,
} from "../../state/workspaces/workspaces.selector";
import React, { useEffect, useMemo, useRef } from "react";
import { generateDeepLink } from "../../utils/url.helpers";
import Styles from "./task.locations.modal.module.css";
import { useI18n } from "../../utils/hooks/use.i18n";
import Scrollbar from "@hoylu/client-common/dist/esm/assets/css.modules/scrollbar.module.css";
import {
  DraggableWindow,
  preventInteractions,
  useClickOutside,
} from "@hoylu/client-common";

export const TaskLocationsModal = () => {
  const t = useI18n("ANALYTICS.");
  const dispatch = useDispatch();
  const cancel = () => {
    dispatch(cancelDashboardOption());
  };
  const modalRef = useClickOutside(cancel);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const data = useSelector(selectTaskLocationData);
  const position = useSelector(getDialogPosition);
  const projectWorkspaces = useSelector(getSelectedProjectWorkspaces);
  const locations: [string, string][] = useMemo(() => {
    if (!data) return [];

    return data.workspaces.map((workspace) => [
      workspace,
      projectWorkspaces.find((w) => w.workspaceId === workspace)
        ?.workspaceName ?? workspace,
    ]);
  }, [data]);

  useEffect(() => {
    const handleScroll = (event: Event) => {
      if (!scrollRef.current) return;

      if (!scrollRef.current.contains(event.target as Node)) {
        cancel();
      }
    };

    document.addEventListener("scroll", handleScroll, true);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [cancel]);

  if (!data) {
    return null;
  }

  const handleLocationClick = (workspaceId: string) => {
    window.open(
      generateDeepLink(
        "element",
        workspaceId,
        data.taskId.replaceAll("-", "")
      )!,
      workspaceId
    );
  };

  return (
    <DraggableWindow
      initialPosition={position}
      style={{
        zIndex: "var(--modal-position)",
        position: "absolute",
      }}
      fitContent={true}
      dragWholeWindow={true}
      testId="task-locations-modal"
    >
      <div ref={modalRef}>
        <div className={Styles.header}>
          <span className={[Styles.icon, "hoylu-ui-icons-blank"].join(" ")} />
          <span>{t("LOCATIONS_LIST")}</span>
        </div>
        <div
          className={[Styles.locationsList, Scrollbar.default].join(" ")}
          ref={scrollRef}
        >
          {locations.map(([workspaceId, workspaceName]) => (
            <div className={Styles.locationRow} key={workspaceId}>
              <span className={Styles.locationName}>{workspaceName}</span>
              <span
                {...preventInteractions()}
                onClick={() => handleLocationClick(workspaceId)}
                className={[
                  Styles.icon,
                  Styles.clickable,
                  "hoylu-ui-icons-link-from-task",
                ].join(" ")}
              />
            </div>
          ))}
        </div>
      </div>
    </DraggableWindow>
  );
};
