export const German = {
  ACCOUNT_VERIFICATION_ERROR: {
    SKIP: "Überspringen",
    VERIFIY_EMAIL: "Bestätige deine Email-Adresse",
    WE_NOTICED:
      "Wir haben festgestellt, dass Sie Ihre E-Mail-Adresse überprüfen müssen. Klicken Sie dazu einfach auf die Schaltfläche unten.",
    WITHOUT_VERIFICATION:
      "Ohne Überprüfung können Sie nicht auf Hoylu Workspaces zugreifen.",
  },
  APP: {
    LOADING_YOUR_WORKSPACE: "Laden Ihrer Workspaces",
    READ_ONLY: "Read only",
  },
  ASSET_PANEL: {
    USE_EXPANDED_VIEW: "Erweiterte Anzeige verwenden",
    USE_COMPACT_VIEW: "Kompakte Anzeige verwenden",
  },
  BETA_DISABLED_CONTENT: {
    BETA_PERIOD_ENDED: "Die Beta-Phase ist beendet.",
    TAKE_ME: "Bring mich zu Hoylu",
    THANK_YOU: "Vielen Dank für Ihr Interesse an der Hoylu Beta.",
  },
  CENTERED_CONTENT_WITH_LOGO: {
    HOYLU_LOGO: "Hoylu Logo",
    LEFT_PATH_ICON: "Linkes Pfadsymbol",
    RIGHT_PATH_ICON: "Rechtes Pfadsymbol",
  },
  ANALYTICS: {
    TITLE: "Titel",
    DESCRIPTION: "Beschreibung",
    TEAM: "Team",
    WEEK: "Startwoche",
    START_DATE: "Startdatum",
    END_DATE: "Enddatum",
    STATUS: "Status",
    LABELS: "Labels",
    WORKSPACES: "Arbeitsbereiche",
    NO_TEAM: "Kein Team zugewiesen",
    TASK_LIST: "Aufgabenliste",
    TASK_COUNT: "${0} von ${1}",
    DATE_FROM: "Von",
    DATE_TO: "Bis",
    REFRESH: "Aktualisieren",
    TASKS: "Aufgaben",
    TOTAL: "Gesamt",
    PERCENT_COMPLETE: "Prozent abgeschlossen",
    COUNT: "Anzahl",
    NO_DATA: "Keine Daten",
    LOAD_ERROR: "Fehler beim Laden der Daten",
    LOCATIONS_TOOLTIP:
      "Liste der Workspaces anzeigen, in denen sich das Element befindet",
    LOCATIONS_LIST: "Workspace-Liste",
  },
  CHANGE_MASTER_DIALOG: {
    TITLE: "Template mit neuem Master-Workspace verlinken",
    SUBTITLE: "Workspace ID oder Link zu neuem Master-Workspace einfügen:",
    CANCEL: "Abbrechen",
    CHANGE_MASTER: "Master ändern",
  },
  CLOSE_BUTTON: {
    CLOSE: "Schließen",
  },
  CONFIRMATION_DIALOG: {
    CANCEL: "Abbrechen",
    OPTION_ICON: "Optionssymbol",
    PASSWORD: "Passwort",
  },
  CONFIRMATION_DRAWER_CONTENT: {
    CANCEL: "Abbrechen",
    OPTION_ICON: "Optionssymbol",
    PASSWORD: "Passwort",
  },
  DELETE_SCOPE_DIALOG: {
    TITLE: "Bereich löschen",
    MESSAGE:
      "Diese Operation wird alle Berichtsdaten für dieses Projekt zurücksetzen. \n" +
      "Einzelne Workspaces sind von dieser Operation NICHT betroffen.\n" +
      "Sie müssen die Workspaces neu synchronisieren, um die projektübergreifende Berichterstattung wieder nutzen zu können.\n",
    CONFIRM_DELETE: "Ich bestätige, dass ich diesen Bereich löschen möchte",
    BUTTON_TEXT: "Löschen",
  },
  COPY_EMAIL_LINK: {
    COPIED_LINK: "Link Kopiert",
    COPY_LINK: "Link Kopieren",
    COPY_WORKSPACE_LINK: "Workspace-Link kopieren",
    EMAIL: "E-Mail Link",
    EMAIL_WORKSPACE_LINK: "Workspace Link per E-Mail versenden",
    LEARN_MORE:
      "Um mehr über Hoylu zu erfahren und ein Konto zu erstellen, besuchen Sie bitte Hoylu.com",
    SMALL_COPIED_LINK: "Kopiert",
    SMALL_COPY_LINK: "Kopieren",
    SMALL_EMAIL: "E-Mail",
    WORKSPACE_SHARED_WITH_YOU: "Ein Hoylu-Workspace wurde mit Ihnen geteilt",
  },
  CREATE_WORKSPACES_SECTION: {
    CREATE: "Neu Erstellen",
    CREATE_WORKSPACE: "Workspace erstellen",
    FROM_TEMPLATE: "Von Vorlage",
    MODULES: {
      BLANK_WORKSPACE: "Leerer Workspace",
      BUSINESS_MODEL_CANVAS: "Business Model Canvas",
      KANBAN: "Kanban",
      PI_PLANNING: "PI Planning",
      PULL_PLANNING: "Pull Planning",
      AGILE_PLANNING: "Agile Planning",
      RETROSPECTIVE: "Retrospective",
      SIMPLE_DRAWING: "Einfaches Zeichnen",
      SUPPLY_CHAIN: "Supply Chain",
      SWOT_ANALYSIS: "SWOT-Analyse",
      VALUE_PROPOSITION_CANVAS: "Value Proposition Canvas",
      WEEKLY_PLANNER: "Wochenplaner",
      WORKSPACE_IDEAS: "Workspace-Ideen",
    },
    OPEN: "Öffnen",
    TOOLTIPPOSTFIX: "\n*Hoylu Enterprise Feature",
    TOGGLE_SHOW_ALL: "Alle Workspaces anzeigen",
    UPSELL: {
      AVAILABLE_WITH_ENTERPRISE:
        "Dieses Modul ist mit dem Enterprise Plan verfügbar",
      CONTACT_US: "Kontaktiere uns",
      NEED_MORE_WORKSPACES: "Brauchen Sie mehr Workspaces?",
      REACHED_LIMIT:
        "Sie haben ihr Workspace-Limit erreicht. Wechseln Sie jetzt auf Hoylu Plus oder kontaktieren Sie Ihren Project Admin um unlimitierte Workspaces und vieles mehr freizuschalten.",
      UPGRADE_TO_ENTERPRISE:
        "Um es auszuprobieren, aktualisieren Sie Ihren Plan auf ein Unternehmenskonto oder kontaktieren Sie uns für eine Demonstration und um mehr zu erfahren.",
      UPGRADE_TO_PLUS: "Auf 'Plus' Lizenz wechseln",
      AVAILABLE_WITH_PLUS: "Diese Funktion benötigt Hoylu Plus",
      UNLOCK_WITH_PLUS:
        "Wechseln Sie jetzt auf Hoylu Plus um unlimitierte Workspaces und vieles mehr freizuschalten.",
    },
    VIEW_LESS: "weniger anzeigen",
    VIEW_MORE: "mehr anzeigen",
  },
  DELETE_WORKSPACE_DIALOG: {
    CONFIRM_DELETE: "Ja, Workspace permanent löschen",
    DELETE: '"${0}" Löschen',
    WARNING:
      'Wollen Sie "${0}" wirklich löschen? Der Workspace wird unmittelbar von den Hoylu Servern entfernt. Diese Aktion kann nicht rückgängig gemacht werden',
  },
  INFO_SECTION_REMOVE_WORKSPACE_DIALOG: {
    HIDE_WORKSPACE:
      "Workspace ausblenden (Bleibt als ausgeblendeter Workspace am Dashboard)",
    LEAVE_WORKSPACE: "Workspace verlassen (Wird von Ihrem Dashboard entfernt)",
    DELETE_WORKSPACE:
      "Workspace löschen (Workspace ist für alle Benutzer permanent gelöscht)",
    HIDE: "Workspace ausblenden",
    LEAVE: "Workspace verlassen",
    DELETE: "Workspace löschen",
    CANCEL: "Abbrechen",
    TITLE: "Workspace entfernen",
  },
  DELETE_TEMPLATE_DIALOG: {
    TITLE: "Template entfernen",
    SUBTITLE:
      "Das Template wird von der Liste der Workspace Template entfernt. \n" +
      "Der verlinkte Master-Workspace wird wieder ein normaler Workspace.",
    CANCEL: "Abbrechen",
    CONTINUE: "Fortsetzen",
  },
  DELETE_WORKSPACE_DRAWER: {
    CONFIRM_DELETE: "Ja, Workspace permanent löschen",
    DELETE: '"${0}" Löschen',
    WARNING:
      'Wollen Sie "${0}" wirklich löschen? Der Workspace wird unmittelbar von den Hoylu Servern entfernt. Diese Aktion kann nicht rückgängig gemacht werden',
  },
  DUPLICATE_WORKSPACE_DIALOG: {
    CANCEL: "Abbrechen",
    COPY_OF: "Kopie von",
    COPY_THIS_WORKSPACE: "Diesen Workspace kopieren",
    DUPLICATE: "Duplizieren",
    MAKE_COPY: "Erstelle eine Kopie von",
    NEW_WORKSPACE_NAME: "Neuer Workspacename",
    OPTION_ICON: "Optionssymbol",
    PASSWORD: "Passwort",
    CHARACTERS: "mindestens 8 Zeichen",
    PRIVATE_WORKSPACE: 'Link-Zugriff auf "no access" setzen',
    PRIVATE_WORKSPACE_TOOLTIP:
      "Workspace ist privat mit keinem öffentlichen Zugriff via Link.\n" +
      'Diese Einstellung kann später via "Teilen"-Dialog verändert werden.',
    SHOW_PASSWORD: "passwort anzeigen",
    NEW_PASSWORD: "Passwort für neuen Workspace",
  },
  EDIT_LABEL_DIALOG: {
    LABELS: "Labels",
    ENTER_LABEL: "Label hinzufügen",
    ADD: "Hinzufügen",
    REMOVE: "Entfernen",
    LOADING_WORKSPACES: "Wird Geladen",
  },
  ERROR: {
    CANNOT_APPLY_CHANGES:
      "Änderungen können aktuell nicht gespeichert werden, bitte versuchen Sie es später nochmal.",
    CANNOT_CREATE_WORKSPACE:
      "Workspace kann aktuell nicht erstellt werden, bitte versuchen Sie es später nochmal.",
    CANNOT_DELETE_WORKSPACE:
      "Workspace kann aktuell nicht gelöscht werden, bitte versuchen Sie es später nochmal.",
    CANNOT_DUPLICATE_WORKSPACE:
      "Workspace kann aktuell nicht dupliziert werden, bitte versuchen Sie es später nochmal.",
    CANNOT_HIDE_WORKSPACE:
      "Workspace kann aktuell nicht ausgeblendet werden, bitte versuchen Sie es später nochmal.",
    CANNOT_UNHIDE_WORKSPACE:
      "Workspace kann aktuell nicht eingeblendet werden, bitte versuchen Sie es später nochmal.",
    CANNOT_REMOVE_PASSWORD:
      "Workspace Passwort kann aktuell nicht entfernt werden, bitte versuchen Sie es später nochmal.",
    CANNOT_SET_PASSWORD:
      "Workspace Passwort kann aktuell nicht gesetzt werden, bitte versuchen Sie es später nochmal.",
    FAILED_DETECT_TEAMS: "Settings konnten nicht gespeichert werden.",
    INCORRECT_PASSWORD: "Falsches Passwort, bitte wiederholen.",
    INCORRECT_WORKSPACE_PASSWORD:
      "Falsches Workspace Passwort, bitte wiederholen.",
    SOMETHING_WENT_WRONG:
      "Hier ist etwas schief gegangen, unsere Entwickler arbeiten daran.",
    VERIFY_WORKSPACE_ID:
      "Bitte überprüfen Sie ob die Workspace ID korrekt ist und ob sie die nötigen Zugriffsrechte haben.",
    WORKSPACE_DELETED: "Workspace gelöscht",
    WORKSPACE_HAS_BEEN_DELETED:
      "Der Workspace den Sie suchen wurde gelöscht. Versuchen Sie eine andere ID einzugeben.",
    PROJECTS: {
      INSUFFICIENT_PERMISSIONS_TO_REMOVE_WORKSPACE:
        "Workspace kann nicht von Projekt entfernt werden. Unzureichende Rechte. Bitte Projekt-Admin kontaktieren.",
      INSUFFICIENT_PERMISSIONS_TO_ADD_WORKSPACE:
        "Workspace kann nicht zu Projekt hinzugefügt werden. Unzureichende Rechte. Bitte Projekt-Admin kontaktieren.",
      CANNOT_ADD_WORKSPACE:
        "Workspace kann nicht zu Projekt hinzugefügt werden. Grund:",
      REASON_MAX_WORKSPACES:
        "Die maximale Anzahl von Workspaces in dem Projekt wurde schon erreicht.",
    },
  },
  ERROR_DIALOG: {
    OK: "Ok",
  },
  ERROR_FETCHING_WORKSPACE: {
    ERROR_FETCHING_WORKSPACES: "Fehler beim Abrufen der Workspaces",
    TRY_AGAIN: "Versuchen Sie es erneut",
  },
  LOAD_MORE_WORKSPACES: {
    LOAD_MORE: "Mehr Laden",
  },
  FILTER_BY_NAME: {
    FILTER_BY_NAME: "Nach Namen filtern",
    SEARCH: "Suche",
    SELECT_PROJECT: "Projekt Auswählen",
    LOADING_WORKSPACES: "Wird Geladen",
    MAGNIFYING_GLASS: "Lupe",
    DASHBOARD: "Dashboard",
    WORKSPACES: "Workspaces",
    PROJECT_SETTINGS: "Projekt Einstellungen",
    PROJECT_SETTINGS_LABEL: "Projekt Einstellungen öffnen...",
    NEW_PROJECT: "Neues Projekt",
    INACTIVE: "INAKTIV",
  },
  FILTER_BY_WORKSPACE_LABEL: {
    FILTER_BY_LABELS: "Nach Label filtern",
    LOADING_WORKSPACES: "Wird Geladen",
    FILTER: "Filter",
    APPLY: "Anwenden",
    SHOW_HIDDEN_WORKSPACES: "Ausgeblendete Workspaces anzeigen",
  },
  HIDE_WORKSPACE_DIALOG: {
    HIDE: "Ausblenden:",
    WARNING: "Dieser Workspace wird vor Ihrem Dashboard ausgeblendet",
  },
  LEAVE_WORKSPACE_DIALOG: {
    LEAVE: "Verlassen:",
    WARNING:
      "Der Workspace wird vom Dashboard entfernt und zählt nicht mehr gegen das Kredit Limit.\n" +
      "Gegebenenfalls werden Sie auch aus der Zugriffsrechte-Liste entfernt",
  },
  HIDE_WORKSPACE_DRAWER: {
    HIDE: "Ausblenden",
    WARNING: "Diesen Workspace ausgeblenden", // TODO: which translation should be used?
  },
  HOYLU_LOGO: {
    HOYLU_DASHBOARD: "Hoylu Dashboard",
    HOYLU_LOGO: "Hoylu Logo",
  },
  KANBAN_CONFIGURATION: {
    SET_COLUMNS: "Legen Sie die Anzahl der Spalten fest",
  },
  LINKED_MASTER_DIALOG: {
    TITLE: "Verlinkter Master-Workspace",
    SUBTITLE: "Das Template ist aktuell verlinkt mit:",
    NAME_FIELD: "Workspacename:",
    ID_FIELD: "Workspace ID:",
    CANCEL: "Abbrechen",
    CHANGE_MASTER: "Master ändern",
    EDIT_CONTENT: "Inhalt bearbeiten",
  },
  LOGIN_PROMPT: {
    AGREE: "Ich stimme den Nutzungsbedingungen von Hoylu zu",
    BACK_TO_LOGIN: "Zurück zum Login",
    ENTER_AS_GUEST: "Als Gast öffnen",
    ENTER_EMAIL: "Email eingeben",
    JOIN: "Beitreten",
    LOGIN: "Anmeldung",
    OR: "oder",
    SIGN_UP: "Konto erstellen",
    TERMS: "Nutzungsbedingungen",
    WELCOME_TO_HOYLU: "Willkommen in Hoylu",
  },
  LOADING_WORKSPACE_DIALOG: {
    LOADING_WORKSPACE: "Workspace wird geladen",
    LOADED: "geladen",
    PROCESS: "verarbeitet",
    RENDER: "gerendert",
    STEP: "Schritt",
  },
  MAC_APP: {
    UPSELL_TITLE: "Benötigen Sie mehr Workspaces?",
    UPSELL_TEXT:
      "Sie haben Ihr Limit an freien Workspaces erreicht. Bitte besuchen Sie Hoylu.com oder wenden Sie sich an Ihren Hoylu-Administrator, um weitere Workspaces zu erhalten.",
    UPSELL_BUTTON: "Hoylu.com",
  },
  NEW_WORKSPACE_DIALOG: {
    CHARACTERS: "mindestens 8 Zeichen",
    CONTINUE: "Fortsetzen",
    CREATE_WORKSPACE: "Workspace erstellen",
    PASSWORD: "Passwort",
    SHOW_PASSWORD: "passwort anzeigen",
    NEW_PASSWORD: "Passwort für neuen Workspace",
  },
  NOTIFICATIONS: {
    TITLE: "Benachrichtigungen",
    NAVIGATE_TO_ELEMENT: "Zum Element navigieren",
    CLOSE_BUTTON: "Schließen",
    SHOW_ALL_MESSAGES: "Alle Nachrichten anzeigen",
    HIDE_READ: "Nur Ungelesene",
    MARK_ALL_AS_READ: "Alle Benachrichtigungen als gelesen markieren",
    NOTIFICATION_BADGE: "Benachrichtigungssymbol",
    SUBSCRIBE:
      "Klicken Sie hier, um über Änderungen per In-App-Benachrichtigungen informiert zu werden.\nCtrl + Click hier, um in der App und per Zusammenfassungs-E-Mails informiert zu werden.",
    UNSUBSCRIBE:
      "Klicken Sie hier, um die Benachrichtigungen für dieses Element zu stoppen.",
    NO_MORE_MESSAGES: "Keine weiteren Nachrichten",
  },
  ORG_BANNER: {
    ORGANIZATION_BANNER: "Organisationsbanner",
  },
  CREDIT_LIMIT_WARNING_BANNER: {
    TITLE: "Limit für kostenloses Abo überschritten.",
    ADD_TO_PROJECT: "add to project...",
    BUTTON: "Upgrade",
  },
  ORG_BLOCKED_CREATE_DIALOG: {
    TITLE: "Can't Create Workspace", //TODO: Translate!
    TEXT:
      "Your organization has activated the policy to only allow administrators to create a workspace. Please contact an organization administrator, that manages Hoylu access, for further details.",
  },
  CREDIT_LIMIT_REACHED_DIALOG: {
    TITLE: "Sie sind über dem Limit ihres kostenlosen Abos.",
    BUTTON_TEXT: "Upgrade",
  },
  TEXT_INPUT: {
    CLEAR: "Zurücksetzen",
    TOGGLE_PASSWORD_VISIBILITY: "Passwortsichtbarkeit umschalten",
  },
  PERMISSION_BAR: {
    ACCESS: "Zugriff",
    ANYONE_WITH_LINK_IN_ORG: "Jeder in meiner Organisation mit dem link",
    AUTHENTICATED_ACCOUNTS_READ_ONLY_ACCESS:
      "Accounts außerhalb meiner Organisation oder Gäste (ohne Hoylu account) haben Lese Zugriff auf den workspace.",
    AUTHENTICATED_ACCOUNTS_NO_ACCESS:
      "Accounts außerhalb meiner Organisation oder Gäste (ohne Hoylu account) haben keinen Zugriff auf den workspace.",
    HAS: "hat", // Anyone with this link **has** [edit/read/no] access
    ONLY_ADMINS_CHANGE_ACCESS: "Nur Admins können Zugriffsrechte anpassen",
    REMOVE_USER: "Benutzer entfernen",
  },
  PI_CONFIGURATION: {
    SET_ITERATIONS: "Legen Sie die Anzahl der Iterationen fest",
  },
  INVITE_USERS: {
    INVITE_USER: "Einladen",
    INVITE_USERS_HEADER: "Zu Hoylu einladen",
    INVITE_USERS_DESCRIPTION:
      "Hier Email Adressen der Personen einfügen, die zu Hoylu eingeladen werden sollen.\nPersonen die bereits einen Hoylu account haben, werden nicht wieder eingeladen.",
    INVITE: "Einladen",
    CANCEL: "Abbrechen",
    ERR: "Ungültige Email Adresse",
    ERR_QTY: "Es können nur 10 Emails auf einmal versandt werden",
    HINT:
      "Zum Versenden mehrerer Einladungen, mehrere Email Adressen mit Leerzeichen getrennt einfügen",
  },
  SHARED_WORKSPACE: {
    ENTER_ID: "Geben Sie die Workspace-ID ein oder fügen Sie einen Link ein",
    MAGNIFYING_GLASS: "Lupe",
    OPEN: "Öffnen",
    OPEN_SHARED_WORKSPACE: "Freigegebenen Workspace öffnen",
  },
  SHARE_TYPE: {
    ADMIN: "admin",
    EDIT: "edit",
    NO_ACCESS: "no",
    READ: "read",
    REMOVE: "entfernen",
    ADMIN_TOOLTIP:
      "Der Benutzer hat vollen Zugriff auf</br>die Zugriffsrechte,</br>die Konfiguration und Bearbeitung</br>des Arbeitsbereichs.",
    EDIT_TOOLTIP:
      "Der Benutzer kann Inhalte bearbeiten,</br>hat aber nur eingeschränkte</br>Möglichkeiten zur Konfiguration</br>des Arbeitsbereichs.",
    READ_TOOLTIP:
      "Der Benutzer kann nur den Inhalt</br>des Arbeitsbereichs anzeigen,</br>aber die Linkfreigabeeinstellungen</br>könnten diese Einschränkung</br>aufheben.",
    NO_ACCESS_TOOLTIP:
      "Der Benutzer hat keinen Zugriff</br>auf den Arbeitsbereich, aber</br>die Linkfreigabeeinstellungen</br>könnten diese Einschränkung</br>aufheben.",
    EDIT_TOOLTIP_LINK:
      "Jeder mit Zugriff auf den</br>Arbeitsbereich kann dessen</br>Inhalte bearbeiten.",
    READ_TOOLTIP_LINK:
      "Jeder mit Zugriff auf den</br>Arbeitsbereich kann dessen</br>Inhalte lesen.",
    NO_ACCESS_TOOLTIP_LINK:
      "Nur Benutzer mit expliziten</br>Berechtigungen können auf</br>den Arbeitsbereich zugreifen.",
  },
  SHARE_WORKSPACE_DIALOG: {
    ANYONE_WITH_LINK: "Jeder mit diesem Link",
    LINK_SHARING: "Link teilen",
    PERMISSIONS: "Zugriffsrechte",
    WORKSPACE_INFO: "Workspace Info",
    CHANGE_THUMBNAIL: "Vorschaubild ändern",
    SHARE_THIS_WORKSPACE: "Diesen Workspace teilen",
    REMOVE_WORKSPACE: "Workspace entfernen",
    REMOVE_TEMPLATE: "Template entfernen",
    REMOVE_FROM_PROJECT: "Aus Projekt entfernen",
    MANDATORY_FIELD: "Workspace Titel: Pflichtfeld",
    CHARACTER_LIMIT: "Workspace Titel ist zu lang",
    WORKSPACE_TITLE: "Workspace Titel",
    TEMPLATE_TITLE: "Template Titel",
    TEMPLATE_DESCRIPTION_TITLE: "Template Beschreibung",
    TEMPLATE_DESCRIPTION_PLACEHOLDER: "Beschreibung hinzufügen",
    TEMPLATE_CATEGORY_TITLE: "Veröffentlichen in folgenden Kategorien",
    TEMPLATE_CATEGORY_PLACEHOLDER: "Kategorie",
    TEMPLATE_INFO: "Template Information",
    LINKED_MASTER: "Verlinkter Master",
    SAVE: "Speichern",
    PUBLISH_AS_TEMPLATE: "Template veröffentl",
    PUBLISH_AS_TEMPLATE_NOT_ALLOWED:
      "Dieser Workspace ist mit einer anderen Organisation verbunden und kann nicht direkt veröffentlicht werden.\n" +
      "Bitte duplizieren sie den Workspace und veröffenltichen sie das Duplikat als Template.",
    PUBLISH: "Veröffentlichen",
    PROJECT: "Projekt",
    CANCEL: "Abbrechen",
    EDIT: "Bearbeiten",
  },
  SEARCH_WORKSPACE_DIALOG: {
    TITLE: "Abbrechen Workspaces",
    LABEL: "Label",
    CANCEL: "Abbrechen",
    SEARCH: "Suche",
    LOADING_WORKSPACES: "Wird Geladen",
  },
  ASSIGN_PROJECT_DIALOG: {
    ASSIGN_TO_PROJECT: "Zu Projekt hinzufügen",
    ASSIGN_PROJECT_DESCRIPTION:
      "Einem Projekt zugeordnete Workspaces sind für andere Benutzer mit entsprechender Projektberechtigungen sichtbar.",
    SELECT_A_PROJECT: "Ein Projekt auswählen",
  },
  REMOVE_WORKSPACE_FROM_PROJECT_DIALOG: {
    TITLE: "Entferne ${0} vom Projekt",
    MESSAGE: "Sind Sie sicher ${0} von diesem Projekt zu entfernen?",
    BUTTON: "Entfernen",
  },
  SHARING_ICONS: {
    COLLAPSE: "Einklappen",
    EXPAND: "Ausklappen",
    LOCKED: "Gesperrt",
    PERMISSIONS: "Berechtigungen",
    UNLOCKED: "Freigeschaltet",
    USERS: "Benutzer",
  },
  TEMPLATE_CARD: {
    TILE_IMAGE: "Kacheln",
  },
  USER_PROFILE_DRAWER: {
    HOYLU_LOGO: "Hoylu Logo",
    LOGOUT: "Ausloggen",
  },
  USER_PROFILE_WIDGET: {
    USER_MARBLE: "Benutzer Avatar",
    LOGOUT: "Ausloggen",
    INVITE_USER: "Einladen",
    MANAGEMENT_PORTAL: "Management Portal",
    SWITCH_COLOR_SCHEME: "Farbschema wechseln",
    THEME_LIGHT_TITLE: "Hell",
    THEME_DARK_TITLE: "Dunkel",
    UPLOAD: "Hochladen",
    MANAGE_ACCOUNT: "Account-Einstellungen",
    UPGRADE: "Upgrade",
    LEARN_MORE: "Mehr erfahren...",
    USAGE_UNDER_LIMIT: "Sie nutzen ${0}% ihres \n kostenlosen Abos.",
    USAGE_OVER_LIMIT: "Sie sind über dem Limit \n ihres kostenlosen Abos.",
    PLAN_TITLE: "(${0} Plan)",
  },
  WHATS_NEW_DIALOG: {
    BROWSER_SUGGESTION:
      "Für die beste Erfahrung wird empfohlen die aktuellste Version von Google Chrome, Apple Safari oder Microsoft Edge zu verweden.",
    CONTINUE: "Fortsetzen",
    FEATURES: {
      FEATURE_1: {
        title: "Pull Planning Updates",
        description: `• Improved loading time up to 90 percent.
• Interactive dependency indicators showing the up- and downstream dependencies.
• Support for resolving dependency conflicts.
• Labels in Insights Panel.`,
      },
      FEATURE_2: {
        title: "Rich Text Enhancements",
        description: `• Automatic adapting text box size when editing.
• Bullet-point list support. (Tab or Shift+Tab to toggle the list indentation)
• Support for hyperlinks in text.`,
      },
      FEATURE_3: {
        title: "User Experience Updates",
        description: `• It is now possible to click on the milestone icon open attached link.
• CTRL + click or middle mouse button click on Thumbnail will open workspace in a new tab.`,
      },
    },
    WHATS_NEW: "Was gibt's Neues",
    WHATS_NEW_ICON: "whats new icon",
  },
  WORKSPACE_CARD: {
    ACCESSED: "Letzer Zugriff:",
    LAST_ACCESSED: "Zuletzt aufgerufen:",
    HIDDEN_WORKSPACE: "Ausgeblendeter Workspace",
    TEMPLATE: "Hoylu Template",
    MORE_OPTIONS: "Mehr Optionen",
    NOT_YET: "Noch nie",
    OPEN: "Öffnen",
    UNTITLED: "Ohne Titel",
    WORKSPACE_MODIFIED: "Workspace wurde seit dem letzten Zugriff geändert",
    WORKSPACE_PREVIEW: "Vorschau des Workspace",
    WORKSPACE_IMG_ALT: "Standard Vorschaubild für workspace",
    CHANGES_SINCE: "Änderungen seit dem letzten Besuch",
    NO_CHANGES_SINCE: "Keine Änderungen seit dem letzten Besuch",
    INACTIVE_PROJECT_LABEL:
      "Dieses Projekt ist inaktiv.\nKlicken Sie für weitere Informationen.",
    LIMITED_WORKSPACE_LABEL:
      "Gratis Account Limit überschritten.\nEs können keine neuen Elemente zum Workspaces hinzugefügt werden.\nFür mehr Informationen hier klicken.",
    LIMITED_TITLE: "Limitiert",
    DELETE: "Löschen",
    DELETE_WORKSPACE: "Workspace löschen",
    ASSIGN_PROJECT: "Zu Projekt hinzufügen",
    REMOVE_PROJECT: "Von Projekt entfernen",
    DUPLICATE: "Duplizieren",
    DUPLICATE_WORKSPACE: "Workspace duplizieren",
    ADD_LABEL: "Label",
    LABELS: "Labels",
    LEAVE: "Verlassen",
    LEAVE_WORKSPACE: "Workspace verlassen",
    HIDE: "Ausblenden",
    HIDE_WORKSPACE: "Workspace ausblenden",
    UNHIDE: "Einblenden",
    UNHIDE_WORKSPACE: "Workspace wieder einblenden",
    DOWNLOAD_ARCHIVE: "Download archive",
    ICON: "symbol",
    SHARE: "Teilen",
    SHARE_SETTINGS: "Zugriff Verwalten",
    EDIT_TITLE: "Titel bearbeiten",
    WORKSPACE_INFO: "Workspace Info",
    EDIT_TEMPLATE: "Vorlage bearbeiten",
    EDIT_TEMPLATE_TOOLTIP:
      "Vorlage bearbeiten. Bitte beachten Sie, dass der Workspace auch während der Bearbeitung von jedem User mit Zugriffsrechten auf den Workspace, dupliziert werden kann.",
  },
  WORKSPACE_PASSWORD: {
    ADD_PASSWORD: "Passwort hinzufügen",
    ADD_WORKSPACE_PASSWORD: "Fügen Sie das Kennwort für den Workspace hinzu",
    CANCEL: "Abbrechen",
    CHANGE: "Veränderung",
    CHANGE_PASSWORD: "Passwort ändern",
    CHANGE_WORKSPACE_PASSWORD: "Ändern Sie das Kennwort für den Workspace",
    CHARACTERS: "*Mindestens 8 Zeichen",
    CONFIRM_NEW_PASSWORD: "bestätige neues Passwort",
    ENTER_CURRENT_PASSWORD: "aktuelles Passwort eingeben",
    REMOVE: "Entfernen",
    REMOVE_PASSWORD: "Passwort entfernen",
    REMOVE_WORKSPACE_PASSWORD: "Entfernen Sie das Passwort für den Workspace",
    SET_NEW_PASSWORD: "neues Passwort eingeben",
    WORKSPACE_NO_PASSWORD: "Der Workspace hat kein Passwort",
    WORKSPACE_PASSWORD_SET: "Das Kennwort für den Workspace ist festgelegt",
  },
  WORKSPACE_PERMISSIONS: {
    ACCESS: "Zugriff",
    ADD_USERS: "Benutzer hinzufügen",
    IMPORT_PROJECT_USERS: "Projektteilnehmer importieren",
    ADD_WITH: "Hinzufügen mit",
    CANCEL: "Abbrechen",
    NOTIFY_USERS: "Sende Benachrichtigung via E-mail",
    USER_PERMISSION: "Benutzerberechtigung wird sein",
    BY_POLICY_ANY_ORG_ADMIN: "Organisationsrichtlinie: Jeder Org-Administrator", // Organsationsrichtline: Jeder Organisations-Admin [hat] [Admin, Write, Read, No] [zugriff]
    PROJECT_ADMINS: "Projekt-Administrator", // Projekt-Administrator [hat] [Admin, Write, Read, No] [zugriff]
    ENTER_USER: "Benutzer eingeben",
    NO_RESULTS_FOUND: "Keine Ergebnisse gefunden",
    USE_THIS_ADDRESS: "Verwenden Sie diese Adresse",
    SUGGESTED_USERS: "Vorgeschlagenen Benutzer",
    LOADING: "Wird geladen...",
    FAILED_TO_LOAD_USERS: "Benutzern konnten nicht geladen werden.",
    NO_ACCOUNTS_ERROR:
      "Die folgenden Email Adressen haben noch keinen Hoylu Account.",
    INVITE_AND_ADD: "Einladung schicken",
  },
  WORKSPACE_TEMPLATES: {
    CREATE: "Erstellen",
    PREVIEW: "Vorschau",
    AGILE: "Agile",
    DESIGN: "Design",
    IDEATION: "Ideation",
    BUSINESS: "Business",
    TEMPLATES: "Vorlagen",
  },
  AGILE_PLANNING: {
    PRESET: "Preset auswählen",
  },
  THUMBNAIL_EDITOR: {
    TITLE: "Workspace Vorschaubild",
    SAVE: "Speichern",
    CANCEL: "Abbrechen",
    DELETE: "Löschen",
    GENERATE_THUMBNAIL: "Vorschaubild wird erstellt",
    TAKE_SNAPSHOT: "Screenshot machen",
    TYPE: "Typ",
    CUSTOM_IMAGE: "Bild hochladen",
    SNAPSHOT: "Screenshot",
    BROWSE: "Durchsuchen",
    ERR: "Nur *.png, *.jpg und *.gif bis zu 1MB werden unterstützt",
  },
  MANAGE_ACCOUNT: {
    TITLE: " Account-Einstellungen",
    EMAIL: "Email",
    CREATED: "Account erstellt am:",
    DELETE: "Account löschen",
    CLOSE: "Schließen",
    MANAGE: "Einstellungen",
  },
  CONFIRM_ACCOUNT_DELETE: {
    TITLE: "Account löschen: ",
    P1: "Um den Account zu löschen:",
    POINT1:
      "Klicken Sie zunächst auf das Kontrollkästchen zur Bestätigung und danach",
    POINT2: `auf die Schaltfläche "Account Löschen".`,
    P2:
      "Sie erhalten in Kürze ein Bestätungs-Email für die Löschanfrage, gefolgt von weiteren Informationen.\nHoylu wird 7 Tage nach ihrer Anfrage den Account permanent löschen.",
    CONFIRM: "Gelöschte Accounts können nicht wiederhergestellt werden.",
    DELETE: "Account Löschen",
    CANCEL: "Abbrechen",
  },
  SECTIONS: {
    HIDDEN: "Ausgeblendet",
    FAVORITES: "Favoriten",
    PROJECT: {
      COLLABORATORS: "Projektmitglieder",
      PROJECT_SETTINGS: "Projekt Einstellungen",
      COPY_LINK: "Link zu Projekt kopieren",
      DASHBOARD: "Projekt Dashboard",
      REPORT_TYPE: "Berichtstyp",
      DELETE_SCOPE: "Berichtsbereich löschen",
      RELOAD: "Neu laden",
    },
    START_FROM_TEMPLATE: {
      TITLE: "Willkommen, ${0}!",
      BUTTON: "Templates durchsuchen",
      LABEL: "Von Vorlage starten",
    },
    WORKSPACES_LIST: "Workspace Liste",
  },
  HEADER: {
    NO_ACCESS:
      "Zugriff auf dieses Projekt wurde verweigert. Bitte kontaktieren sie den Projektadministrator.",
  },
  VALIDATION: {
    MANDATORY_FIELD: "Workspace Titel: Pflichtfeld",
    CHARACTER_LIMIT: "Workspace Titel ist zu lang",
  },
  PROJECT: {
    DURATION: "${0} Tage",
    REMAINING_DURATION: "Verbleibende Projektdauer: ${0} Tage",
  },
};
