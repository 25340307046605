import { NexusService } from "./service";
import { debug } from "debug";
import {
  NexusReasonDto,
  NexusReasonCreateDto,
  NexusReasonUpdateDto,
  Operation,
} from "./types";

const _LOG = debug("hoylu:reason:service:log");

export class NexusReasonService extends NexusService {
  protected readonly path: string = "reason";

  /**
   * Retrieves all reasons for a given scope
   * @param {string} scopeId - The scope ID to fetch reasons from (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusReasonDto[]>} Array of reason objects
   */
  public async getAllReasons(
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusReasonDto[]> {
    _LOG(`Fetching all reasons for scope: ${scopeId || this.scopeId}`);
    return this.getJSON<NexusReasonDto[]>(
      this.scopedPath(scopeId),
      undefined,
      undefined,
      signal
    );
  }

  /**
   * Creates a new reason
   * @param {NexusReasonCreateDto} reason - The reason data to create
   * @param {string} scopeId - The scope ID to create the reason in (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusReasonDto>} The created reason
   */
  public async postReason(
    reason: NexusReasonCreateDto,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusReasonDto> {
    _LOG(`Creating new reason in scope: ${scopeId || this.scopeId}`);
    return this.postJSON<NexusReasonDto>(
      this.scopedPath(scopeId),
      reason,
      undefined,
      signal
    );
  }

  /**
   * Retrieves a specific reason by ID
   * @param {string} id - The ID of the reason to fetch
   * @param {string} scopeId - The scope ID to fetch the reason from (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusReasonDto>} The requested reason
   */
  public async getReason(
    id: string,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusReasonDto> {
    _LOG(`Fetching reason ${id} from scope: ${scopeId || this.scopeId}`);
    return this.getJSON<NexusReasonDto>(
      this.scopedPath(scopeId, id),
      undefined,
      undefined,
      signal
    );
  }

  /**
   * Updates a reason with new data
   * @param {string} id - The ID of the reason to update
   * @param {NexusReasonUpdateDto} reason - The updated reason data
   * @param {string} scopeId - The scope ID of the reason (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusReasonDto>} The updated reason
   */
  public async putReason(
    id: string,
    reason: NexusReasonUpdateDto,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusReasonDto> {
    _LOG(`Updating reason ${id} in scope: ${scopeId || this.scopeId}`);
    return this.putJSON<NexusReasonDto>(
      this.scopedPath(scopeId, id),
      reason,
      undefined,
      signal
    );
  }

  /**
   * Patches a reason with specific operations
   * @param {string} id - The ID of the reason to patch
   * @param {Operation[]} operation - Array of operations to apply
   * @param {string} scopeId - The scope ID of the reason (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusReasonDto>} The patched reason
   */
  public async patchReason(
    id: string,
    operation: Operation[],
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusReasonDto> {
    _LOG(`Patching reason ${id} in scope: ${scopeId || this.scopeId}`);
    return this.patchJSON<NexusReasonDto>(
      this.scopedPath(scopeId, id),
      operation,
      undefined,
      signal
    );
  }

  /**
   * Deletes a reason
   * @param {string} id - The ID of the reason to delete
   * @param {string} scopeId - The scope ID of the reason (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<void>}
   */
  public async deleteReason(
    id: string,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<void> {
    _LOG(`Deleting reason ${id} from scope: ${scopeId || this.scopeId}`);
    return this.deleteJSON<void>(
      this.scopedPath(scopeId, id),
      undefined,
      signal
    );
  }
}
