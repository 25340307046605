import React, { useContext } from "react";
import { ReportFactory, ReportType } from "./ReportFactory";
import {
  DateInput,
  Dropdown,
  Icon,
  Search,
  SearchEngineType,
} from "@hoylu/client-common";
import { FilterAction, FilterContext } from "./FilterProvider";
import { DateTime } from "luxon";
import DefaultDropdownStyles from "@hoylu/client-common/dist/esm/assets/css.modules/default.dropdown.module.css";
import Styles from "./Analytics.module.css";
import { TaskList } from "./TaskListView/TaskList";
import { useI18n } from "../../utils/hooks/use.i18n";

export type AnalyticsProps = {
  type: ReportType;
};

const FILTER_ORDER_MAP: { [key: string]: number } = {
  team: 0,
  assignee: 1,
  status: 2,
  label: 3,
  workspace: 4,
};

const FilterSorter = (a: [string, any], b: [string, any]) => {
  return (
    (FILTER_ORDER_MAP[a[0]] ?? Infinity) - (FILTER_ORDER_MAP[b[0]] ?? Infinity)
  );
};

export function Analytics({ type }: AnalyticsProps) {
  const filterContext = useContext(FilterContext);
  const t = useI18n("ANALYTICS.");

  return (
    <>
      <div className={Styles.filters}>
        <DateInput
          label={t("DATE_FROM")}
          range={filterContext.dateRange}
          value={
            filterContext.filterState.from
              ? DateTime.fromISO(filterContext.filterState.from)
              : undefined
          }
          onChange={(v) =>
            filterContext.filterDispatch(FilterAction.setDateRange("from", v))
          }
          style={{ background: "inherit", borderBottomStyle: "none" }}
        />
        <DateInput
          label={t("DATE_TO")}
          range={filterContext.dateRange}
          value={
            filterContext.filterState.to
              ? DateTime.fromISO(filterContext.filterState.to)
              : undefined
          }
          onChange={(v) =>
            filterContext.filterDispatch(FilterAction.setDateRange("to", v))
          }
          style={{ background: "inherit", borderBottomStyle: "none" }}
        />
        {Object.entries(filterContext.filterState.filters)
          .sort(FilterSorter)
          .map(([key, options]) => {
            return (
              <Dropdown
                key={key}
                title={key}
                options={options.availableOptions}
                values={options.selectedOptions}
                onChange={(value) =>
                  filterContext.filterDispatch(
                    FilterAction.setSelectedOptions(key, value)
                  )
                }
                allowReset={true}
                useMultiSelect={true}
                allowSelectAll={true}
                searchEngineType={SearchEngineType.FUZZY}
                classes={[DefaultDropdownStyles.dropdown, Styles.dropdown]}
              />
            );
          })}
        <Search
          value={filterContext.filterState.query}
          handleChange={(q) =>
            filterContext.filterDispatch(FilterAction.setQuery(q))
          }
        />
        <Icon
          icon={"hoylu-ui-icons-refresh"}
          title={t("REFRESH")}
          onClick={() => filterContext.filterDispatch(FilterAction.refresh())}
        />
      </div>
      <div className={Styles.report}>
        <ReportFactory type={type} />
      </div>
      <TaskList />
    </>
  );
}
