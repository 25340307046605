import React from "react";
import { Localized } from "../../../strings";
import { ShareOptionValue } from "./ShareType";
import Styles from "./HeaderPermissions.module.css";
import PermissionStyles from "./ShareType.module.css";
import { useI18n } from "../../../utils/hooks/use.i18n";

export type HeaderPermissionsProps = {
  enabledOption: ShareOptionValue;
  text: string;
  publicPermissionLevel?: ShareOptionValue;
};

export const HeaderPermissions: React.FC<HeaderPermissionsProps> = ({
  enabledOption,
  text,
  publicPermissionLevel,
}) => {
  const t = useI18n();
  const tp = useI18n("PERMISSION_BAR.");

  const computeStyle = (option: ShareOptionValue) => {
    let isNotSelectedStyle = Styles.inactivePill;
    if (
      !publicPermissionLevel ||
      enabledOption.value >= publicPermissionLevel.value
    ) {
      if (option === enabledOption) {
        return option.color;
      } else {
        return isNotSelectedStyle;
      }
    }

    if (option === publicPermissionLevel) {
      return option.color;
    } else if (option === enabledOption) {
      return `${isNotSelectedStyle} ${option.color} ${PermissionStyles.border}`;
    } else {
      return isNotSelectedStyle;
    }
  };
  const accessText: string = `${text} ${tp("HAS")}`;
  return (
    <div className={Styles.headerPermissions}>
      <div title={accessText}>{accessText}</div>
      <div className={Styles.permissionsInfo}>
        <div className={Styles.permission}>
          <div
            className={`${Styles.permissionPill} ${computeStyle(
              enabledOption
            )}`}
            data-tooltip-id={"tooltip"}
            data-tooltip-html={
              enabledOption.tooltip && t(enabledOption.tooltip(true))
            }
            data-tooltip-class-name={[
              enabledOption.color,
              PermissionStyles.tooltip,
            ].join(" ")}
          >
            <div className={Styles.permissionTitle}>
              {Localized.string(enabledOption.title)}
            </div>
          </div>
        </div>
        <div>{tp("ACCESS")}</div>
      </div>
    </div>
  );
};
