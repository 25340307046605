import { NexusService } from "./service";
import { debug } from "debug";
import {
  NexusTeamDto,
  NexusTeamCreateDto,
  NexusTeamUpdateDto,
  Operation,
} from "./types";

const _LOG = debug("hoylu:team:service:log");

export class NexusTeamService extends NexusService {
  protected readonly path: string = "team";

  /**
   * Retrieves all teams for a given scope
   * @param {string} scopeId - The scope ID to fetch teams from (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusTeamDto[]>} Array of team objects
   */
  public async getAllTeams(
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusTeamDto[]> {
    _LOG(`Fetching all teams for scope: ${scopeId || this.scopeId}`);
    return this.getJSON<NexusTeamDto[]>(
      this.scopedPath(scopeId),
      undefined,
      undefined,
      signal
    );
  }

  /**
   * Creates a new team
   * @param {NexusTeamCreateDto} team - The team data to create
   * @param {string} scopeId - The scope ID to create the team in (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusTeamDto>} The created team
   */
  public async postTeam(
    team: NexusTeamCreateDto,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusTeamDto> {
    _LOG(`Creating new team in scope: ${scopeId || this.scopeId}`);
    return this.postJSON<NexusTeamDto>(
      this.scopedPath(scopeId),
      team,
      undefined,
      signal
    );
  }

  /**
   * Retrieves a specific team by ID
   * @param {string} id - The ID of the team to fetch
   * @param {string} scopeId - The scope ID to fetch the team from (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusTeamDto>} The requested team
   */
  public async getTeam(
    id: string,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusTeamDto> {
    _LOG(`Fetching team ${id} from scope: ${scopeId || this.scopeId}`);
    return this.getJSON<NexusTeamDto>(
      this.scopedPath(scopeId, id),
      undefined,
      undefined,
      signal
    );
  }

  /**
   * Updates a team with new data
   * @param {string} id - The ID of the team to update
   * @param {NexusTeamUpdateDto} team - The updated team data
   * @param {string} scopeId - The scope ID of the team (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusTeamDto>} The updated team
   */
  public async putTeam(
    id: string,
    team: NexusTeamUpdateDto,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusTeamDto> {
    _LOG(`Updating team ${id} in scope: ${scopeId || this.scopeId}`);
    return this.putJSON<NexusTeamDto>(
      this.scopedPath(scopeId, id),
      team,
      undefined,
      signal
    );
  }

  /**
   * Patches a team with specific operations
   * @param {string} id - The ID of the team to patch
   * @param {Operation[]} operation - Array of operations to apply
   * @param {string} scopeId - The scope ID of the team (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<NexusTeamDto>} The patched team
   */
  public async patchTeam(
    id: string,
    operation: Operation[],
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<NexusTeamDto> {
    _LOG(`Patching team ${id} in scope: ${scopeId || this.scopeId}`);
    return this.patchJSON<NexusTeamDto>(
      this.scopedPath(scopeId, id),
      operation,
      undefined,
      signal
    );
  }

  /**
   * Deletes a team
   * @param {string} id - The ID of the team to delete
   * @param {string} scopeId - The scope ID of the team (defaults to current scope)
   * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the request
   * @returns {Promise<void>}
   */
  public async deleteTeam(
    id: string,
    scopeId: string = "",
    signal?: AbortSignal
  ): Promise<void> {
    _LOG(`Deleting team ${id} from scope: ${scopeId || this.scopeId}`);
    return this.deleteJSON<void>(
      this.scopedPath(scopeId, id),
      undefined,
      signal
    );
  }
}
