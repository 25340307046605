import React, { useMemo } from "react";
import { Localized } from "../../strings";
import { Project, WorkspaceProjects } from "../../state/workspaces/types";
import Styles from "./ProjectNavigationDropdown.module.css";
import DefaultDropdownStyle from "@hoylu/client-common/dist/esm/assets/css.modules/default.dropdown.module.css";
import { Dropdown, Option, SearchEngineType } from "@hoylu/client-common";
import { addPrefixWhenProjectIsInactive } from "../../utils/add.prefix.when.project.is.inactive";
import { ProjectValueRenderer } from "./ProjectValueRenderer";
import { ProjectOptionRenderer } from "./ProjectOptionRenderer";
import { getDashboardProjectOption } from "./get.dashboard.project.option";
import { getEmptyProjectOption } from "./get.empty.project.option";
import { useDispatch } from "react-redux";
import { ProjectOption } from "./types";
import { selectProject } from "../../state/workspaces/workspaces.actions";

type ProjectNavigationDropdownProps = {
  allProjects: WorkspaceProjects;
  selectedProject: Project | null;
  showProjects: boolean;
  allowCreatingProject: boolean;
};

export const ProjectNavigationDropdown: React.FC<ProjectNavigationDropdownProps> = ({
  allProjects,
  selectedProject,
  showProjects,
  allowCreatingProject,
}) => {
  const strings = Localized.object("FILTER_BY_NAME");
  const dispatch = useDispatch();

  const DashboardProject = getDashboardProjectOption(() =>
    dispatch(selectProject(null))
  );
  const EmptyProjectOption = getEmptyProjectOption();

  const projectsDropdownOptions: Option<ProjectOption>[] = [
    DashboardProject,
    ...allProjects.map((p) => ({
      label: addPrefixWhenProjectIsInactive(p),
      value: {
        ...p,
        onSelected: () => dispatch(selectProject(p)),
      },
    })),
  ];

  if (allowCreatingProject) {
    projectsDropdownOptions.push(EmptyProjectOption);
  }

  const value = useMemo(() => {
    return (
      projectsDropdownOptions.find(
        ({ value }) => value.id === selectedProject?.id
      ) ?? DashboardProject
    );
  }, [selectedProject, projectsDropdownOptions]);

  if (!showProjects || !allProjects.length) {
    return null;
  }

  return (
    <div
      aria-label={strings.FILTER_BY_NAME}
      tabIndex={0}
      className={Styles.dropdownContainer}
    >
      <Dropdown
        onChange={() => {}}
        title={value.label}
        values={[value]}
        options={projectsDropdownOptions}
        allowReset={false}
        searchEngineType={SearchEngineType.FUZZY}
        valueRenderer={ProjectValueRenderer}
        optionRenderer={ProjectOptionRenderer}
        classes={[
          DefaultDropdownStyle.dropdown,
          Styles.projectNavigationDropdown,
        ]}
        dropdownClasses={[
          DefaultDropdownStyle.dropDownPopUp,
          Styles.dropDownPopUp,
        ]}
        ignoreScrollContext={true}
      />
    </div>
  );
};

export default ProjectNavigationDropdown;
