import { WorkspaceType } from "../state/workspaces/WorkspaceType";
import sketchPreviewUrl from "../assets/images/sketch.png";
import flowPreviewUrl from "../assets/images/flow.png";
import defaultPreviewUrl from "../assets/images/modules/blank.png";
import darkPreviewUrl from "../assets/images/modules/dark_blank.png";
import { THEME_LIGHT, useThemeContext } from "@hoylu/client-common";
import { useMemo } from "react";

export const useWorkspaceDefaultImage = (type: WorkspaceType): string => {
  const themeContext = useThemeContext();

  return useMemo(() => {
    switch (type) {
      case WorkspaceType.SKETCH:
        return sketchPreviewUrl;
      case WorkspaceType.FLOW:
        return flowPreviewUrl;
      default:
        return themeContext.theme === THEME_LIGHT
          ? defaultPreviewUrl
          : darkPreviewUrl;
    }
  }, [themeContext.theme]);
};
