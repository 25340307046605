import React, { useRef } from "react";
import { decode } from "html-entities";
import Styles from "./TaskView.module.css";
import { preventInteractions } from "@hoylu/client-common";
import { NexusStatusEntity } from "@hoylu/nexus-service";
import { useDispatch } from "react-redux";
import { activateDashboardOption } from "../../../state/workspaces/workspaces.actions";
import { DashboardOption } from "../../../state/workspaces/DashboardOption";
import { useI18n } from "../../../utils/hooks/use.i18n";

type TitleCellProps = {
  title: string;
};

export const TitleCell = ({ title }: TitleCellProps) => {
  const classNames = [Styles.textLeft];
  return (
    <div title={title} className={classNames.join(" ")}>
      {decode(title)}
    </div>
  );
};

export type WeekCellProps = {
  weekNumber: string;
};

export function WeekCell({ weekNumber }: WeekCellProps) {
  return <div title={weekNumber}>{weekNumber}</div>;
}

export type StatusCellProps = {
  status: NexusStatusEntity;
  reason?: string;
};

export function StatusCell({ status, reason }: StatusCellProps): JSX.Element {
  //There is extreamly rare edge case where the status is undefined.
  //In this case we will show a question mark icon.
  //This is to prevent the table from crashing and indicates user he need to reload master data.
  return (
    <div className={Styles.statusContainer}>
      <div
        className={`${Styles.icon} ${status?.icon || "hoylu-ui-icons-question-mark"}`}
        title={reason ? reason : status?.name}
      />
    </div>
  );
}

type LocationCellProps = {
  taskId: string;
  workspaces: string[];
};

/** Supports pdf hyperlinks to workspace elements */
export function LocationCell({
  taskId,
  workspaces,
}: LocationCellProps): JSX.Element {
  const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

  const t = useI18n("ANALYTICS.");
  const dispatch = useDispatch();

  const getPositionPoint = () => {
    const bounds = ref.current?.getBoundingClientRect();

    if (bounds) {
      const leftShift = 165; // around half of modal width plus small margin

      return {
        x: bounds.x - leftShift,
        y: bounds.y,
      };
    }

    return undefined;
  };

  const handleCellClick = () => {
    dispatch(
      activateDashboardOption({
        optionType: DashboardOption.SHOW_TASK_LOCATIONS,
        taskLocationsData: { taskId, workspaces },
        dialogPosition: getPositionPoint(),
      })
    );
  };

  return (
    <div
      className={[Styles.linkContainer, Styles.clickable].join(" ")}
      {...preventInteractions()}
      onClick={handleCellClick}
      title={t("LOCATIONS_TOOLTIP")}
      ref={ref}
    >
      <div className={[Styles.icon, "hoylu-ui-icons-blank"].join(" ")} />
      <span>{`(${workspaces.length})`}</span>
    </div>
  );
}
