export const ERROR_BAD_REQUEST = 400;
export const ERROR_UNAUTHORIZED = 401;
export const ERROR_FORBIDDEN = 403;
export const ERROR_NOTFOUND = 404;
export const ERROR_NOT_ALLOWED = 405;
export const ERROR_PRECONDITION_FAILED = 412;
export const ERROR_CANCEL = -1000;
export const ERROR_CONFIGURATION = -2000;
export const ERROR_PARSING = -3000;
export const ERROR_PASSWORD_INPUT = -4000;

export class ServiceError extends Error {
  errorCode: number;

  constructor(message: string, errorCode: number) {
    super(message);
    this.errorCode = errorCode;
  }
}

export class ServerError extends ServiceError {
  constructor(message: string, errorCode: number) {
    super(message, errorCode);
  }
}

export class UnauthorizedError extends ServerError {
  constructor(message: string) {
    super(message, ERROR_UNAUTHORIZED);
  }
}

export class MethodNotAllowed extends ServiceError {
  constructor(message: string) {
    super(message, ERROR_NOT_ALLOWED);
  }
}

export class PreconditionFailedError extends ServerError {
  constructor(message: string) {
    super(message, ERROR_PRECONDITION_FAILED);
  }
}

export class CancelError extends ServiceError {
  constructor(message: string) {
    super(message, ERROR_CANCEL);
  }
}

export class NotFoundError extends ServerError {
  constructor(message: string) {
    super(message, ERROR_NOTFOUND);
  }
}

export class ForbiddenError extends ServerError {
  constructor(message: string) {
    super(message, ERROR_FORBIDDEN);
  }
}

export class ConfigurationError extends ServiceError {
  constructor(message: string) {
    super(message, ERROR_CONFIGURATION);
  }
}

export class PasswordInputError extends ServiceError {
  constructor(message: string) {
    super(message, ERROR_PASSWORD_INPUT);
  }
}

/**
 * Error thrown when a response or part of a response could not be parsed or are not as expected.
 */
export class ParsingError extends ServerError {
  constructor(message: string) {
    super(message, ERROR_PARSING);
  }
}

export class InvalidOperationError extends Error {
  constructor(message: string) {
    super(message);
  }
}
