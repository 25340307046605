import React from "react";
import { TaskPPC } from "./TaskPPC";
import { TaskStatus } from "./TaskStatus";
import { TaskReason } from "./TaskReason";

export type ReportType = "status" | "ppc" | "reason";

export type ReportFactoryProps = {
  type: ReportType;
};
export const ReportFactory = ({ type }: ReportFactoryProps) => {
  switch (type) {
    case "status":
      return <TaskStatus />;
    case "ppc":
      return <TaskPPC />;
    case "reason":
      return <TaskReason />;
    default:
      return null;
  }
};
