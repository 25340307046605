import React from "react";
import { useWorkspaceDefaultImage } from "../../../utils/defaultImages";
import WorkspaceImagePreview from "./WorkspaceImagePreview";
import { CustomWorkspacePreviewProps } from "./CustomWorkspacePreview";

const DefaultWorkspacePreview: React.FC<Omit<
  CustomWorkspacePreviewProps,
  "token"
>> = ({
  workspaceDetails,
  canEditLabels,
  showEditLabelPrompt,
  setWorkspaceFavorite,
  showWorkspaceWeight,
}) => {
  const imgSrc = useWorkspaceDefaultImage(workspaceDetails.workspaceType);

  return (
    <WorkspaceImagePreview
      imgSrc={imgSrc}
      setWorkspaceFavorite={setWorkspaceFavorite}
      canEditLabels={!!canEditLabels}
      showEditLabelPrompt={showEditLabelPrompt}
      showWorkspaceWeight={showWorkspaceWeight}
      workspaceDetails={workspaceDetails}
    />
  );
};

export default DefaultWorkspacePreview;
