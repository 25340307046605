import React, { useEffect, useState } from "react";
import { useWorkspaceDefaultImage } from "../../../utils/defaultImages";
import { useSelector } from "react-redux";
import { hasModifiedThumbnail } from "../../../state/workspaces/workspaces.selector";
import { WorkspaceCardProps } from "./WorkspaceCard";
import { RootState } from "typesafe-actions";
import WorkspaceImagePreview from "./WorkspaceImagePreview";

export type CustomWorkspacePreviewProps = Pick<
  WorkspaceCardProps,
  | "workspaceDetails"
  | "token"
  | "showEditLabelPrompt"
  | "canEditLabels"
  | "showWorkspaceWeight"
  | "setWorkspaceFavorite"
>;

const CustomWorkspacePreview: React.FC<
  CustomWorkspacePreviewProps & { setIsVisible: (value: boolean) => void }
> = ({
  workspaceDetails,
  setIsVisible,
  token,
  showEditLabelPrompt,
  canEditLabels,
  setWorkspaceFavorite,
  showWorkspaceWeight,
}): JSX.Element => {
  const defaultImgSrc = useWorkspaceDefaultImage(
    workspaceDetails.workspaceType
  );
  const [imgSrc, setImgSrc] = useState<string>("");
  const ignoreCache = useSelector((state: RootState) =>
    hasModifiedThumbnail(state, workspaceDetails.workspaceId)
  );

  useEffect(() => {
    let isFreshlyAccessed = false;
    let customThumbnailUrl = workspaceDetails.thumbnailUrl;
    if (!customThumbnailUrl) return;

    if (workspaceDetails.lastAccess) {
      const hoursInMilliseconds = 24 * 3600 * 1000; // cache-control has max-age=86400 which is 24h
      const timeDifference = Date.now() - workspaceDetails.lastAccess.getTime();
      isFreshlyAccessed = timeDifference < hoursInMilliseconds;
    }

    if (customThumbnailUrl.startsWith("blob:")) {
      setImgSrc(customThumbnailUrl);
    } else {
      if (isFreshlyAccessed || ignoreCache || workspaceDetails.templateId) {
        customThumbnailUrl += "?defyCache=" + Date.now();
      }
      // fetching here to nicely handle 404's and avoid situation when default thumbnails are blinking
      fetch(customThumbnailUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response: Response) => {
          if (response.status === 200) {
            response.blob().then((blob: Blob) => {
              if (blob instanceof Blob) {
                setImgSrc(URL.createObjectURL(blob));
              }
            });
          }
        })
        .catch(console.debug);
    }
    setIsVisible(true);
    // we do want to fire the hook only on component init
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    workspaceDetails?.thumbnailUrl,
    ignoreCache,
    workspaceDetails?.thumbnailChanged,
  ]);

  useEffect(() => {
    return () => URL.revokeObjectURL(imgSrc); //Revoke URL when img src change
  }, [imgSrc]);

  return (
    <WorkspaceImagePreview
      canEditLabels={!!canEditLabels}
      showEditLabelPrompt={showEditLabelPrompt}
      imgSrc={imgSrc || defaultImgSrc}
      setWorkspaceFavorite={setWorkspaceFavorite}
      showWorkspaceWeight={showWorkspaceWeight}
      workspaceDetails={workspaceDetails}
    />
  );
};

export default CustomWorkspacePreview;
