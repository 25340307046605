import Styles from "./ShareType.module.css";

export type ShareOptionValue = {
  title: string;
  color?: string;
  value: number;
  tooltip?: (isLink: boolean) => string;
};

export type User = {
  email: string;
  permission: ShareOptionValue;
};

export const ShareType: Record<string, ShareOptionValue> = {
  ADMIN: {
    title: "SHARE_TYPE.ADMIN",
    color: Styles.admin,
    value: 3,
    tooltip: (_) => "SHARE_TYPE.ADMIN_TOOLTIP",
  },
  EDIT: {
    title: "SHARE_TYPE.EDIT",
    color: Styles.edit,
    value: 2,
    tooltip: (isLink) => getTooltipAddress(isLink, "SHARE_TYPE.EDIT_TOOLTIP"),
  },
  READ: {
    title: "SHARE_TYPE.READ",
    color: Styles.read,
    value: 1,
    tooltip: (isLink) => getTooltipAddress(isLink, "SHARE_TYPE.READ_TOOLTIP"),
  },
  NO_ACCESS: {
    title: "SHARE_TYPE.NO_ACCESS",
    color: Styles.noAccess,
    value: 0,
    tooltip: (isLink) =>
      getTooltipAddress(isLink, "SHARE_TYPE.NO_ACCESS_TOOLTIP"),
  },
  REMOVE: {
    title: "SHARE_TYPE.REMOVE",
    value: -1,
  },
};

export enum InfoSection {
  WORKSPACE = "workspace",
  TEMPLATE = "template",
}

const getTooltipAddress = (isLinkShare: boolean, addressBase: string) => {
  return isLinkShare ? `${addressBase}_LINK` : addressBase;
};
