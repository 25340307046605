import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  statusSorter,
  TaskDataBuilder,
  weekGenerator,
} from "../../utils/analytics/task.data.builder";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAnalyticsMasterData,
  selectTaskStatus,
  selectTaskStatusByTeam,
  selectTaskStatusByWeek,
} from "../../state/analytics/analytics.selector";
import {
  fetchTaskStatus,
  fetchTaskStatusByTeam,
  fetchTaskStatusByWeek,
} from "../../state/analytics/analytics.actions";
import { selectedProject } from "../../state/workspaces/workspaces.selector";
import { FilterContext, toReportFilters } from "./FilterProvider";
import { Chart } from "./Chart";
import { taskTypeDefs, taskTypeFill } from "@hoylu/nivo-charts";
import { Localized } from "../../strings";
import { BarChart, LineChart, PieChart } from "./MemoizedCharts";
import {
  ReportResult,
  TeamStatusReportResult,
  WeekStatusReportResult,
} from "@hoylu/nexus-service";

export const TaskStatus = () => {
  const builder = new TaskDataBuilder();
  const filtersContext = useContext(FilterContext);
  const masterData = useSelector(selectAnalyticsMasterData);
  const strings = Localized.object("ANALYTICS");

  const [reload, setReload] = useState(0);
  const dispatch = useDispatch();
  const statusData = useSelector(selectTaskStatus);
  const statusDataByWeek = useSelector(selectTaskStatusByWeek);
  const statusDataByTeam = useSelector(selectTaskStatusByTeam);
  const project = useSelector(selectedProject);

  useEffect(() => {
    dispatch(
      fetchTaskStatus.request({
        ...toReportFilters(filtersContext),
      })
    );
    dispatch(
      fetchTaskStatusByWeek.request({
        ...toReportFilters(filtersContext),
      })
    );
    dispatch(
      fetchTaskStatusByTeam.request({
        ...toReportFilters(filtersContext),
      })
    );
  }, [reload, filtersContext]);

  useEffect(() => {
    if (project) {
      setReload(reload + 1);
    }
  }, [project]);

  const pieData = useMemo(() => {
    return builder.buildPieDataSet<ReportResult>(
      statusData?.data ?? [],
      (reportResult) =>
        masterData.data?.status.find((r) => r.id === reportResult.value)
          ?.name ?? reportResult.value,
      (reportResult) => reportResult.count,
      (status) =>
        masterData.data?.status.find((s) => s.name === status)?.color ??
        "black",
      (a, b) => statusSorter(a, b) * -1
    );
  }, [statusData, masterData]);

  const lineData = useMemo(() => {
    const firstWeek = statusDataByWeek?.data?.[0]?.week;
    if (!firstWeek) return { data: [], size: 0 };
    return builder.buildLineDataSet<WeekStatusReportResult, ReportResult>(
      statusDataByWeek?.data ?? [],
      weekGenerator(firstWeek),
      (reportResult) =>
        masterData.data?.status?.find((s) => s.id === reportResult.value)
          ?.name ?? reportResult.value,
      (report) => report.week,
      (report) => report.statuses,
      (reportResult) => reportResult.count,
      (status) =>
        masterData.data?.status.find((s) => s.name === status)?.color ??
        "black",
      statusSorter
    );
  }, [statusDataByWeek, masterData]);

  const barData = useMemo(() => {
    const collator = Intl.Collator();
    return builder.buildBarDataSet<TeamStatusReportResult, ReportResult>(
      statusDataByTeam?.data ?? [],
      (reportResult) => reportResult.count,
      (report) => report.statuses,
      (report) =>
        masterData.data?.team?.find((t) => t.id === report.team)?.name ??
        report.team,
      (reportResult) =>
        masterData.data?.status?.find((s) => s.id === reportResult.value)
          ?.name ?? reportResult.value,
      (report) =>
        masterData.data?.team?.find((t) => t.id === report.team)?.shortName ??
        report.team.slice(0, 4),
      (status) =>
        masterData.data?.status.find((s) => s.name === status)?.color ??
        "black",
      collator.compare,
      statusSorter
    );
  }, [statusDataByTeam, masterData]);

  return (
    <>
      <Chart state={statusData}>
        <PieChart
          data={pieData}
          showCenterMetric={true}
          suffix={strings.TASKS}
          defs={taskTypeDefs}
          fill={taskTypeFill}
        />
      </Chart>
      <Chart state={statusDataByWeek}>
        <LineChart
          data={lineData}
          yLabel={strings.COUNT}
          suffix={strings.TASKS}
          defs={taskTypeDefs}
          fill={taskTypeFill}
        />
      </Chart>
      <Chart state={statusDataByTeam}>
        <BarChart
          data={barData}
          yLabel={strings.COUNT}
          suffix={strings.TASKS}
          defs={taskTypeDefs}
          fill={taskTypeFill}
          showTooltipTotal={true}
        />
      </Chart>
    </>
  );
};
