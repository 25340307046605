import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  TaskDataBuilder,
  weekGenerator,
} from "../../utils/analytics/task.data.builder";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAnalyticsMasterData,
  selectTaskReason,
  selectTaskReasonByTeam,
  selectTaskReasonByWeek,
} from "../../state/analytics/analytics.selector";
import useAsyncEffect from "use-async-effect";
import {
  fetchTaskReason,
  fetchTaskReasonByTeam,
  fetchTaskReasonByWeek,
} from "../../state/analytics/analytics.actions";

import { FilterContext, toReportFilters } from "./FilterProvider";
import { Chart } from "./Chart";
import {
  taskTypeDefs,
  taskTypeFill,
  VarianceReasonsColorUtils,
} from "@hoylu/nivo-charts";
import { Localized } from "../../strings";
import { BarChart, LineChart, PieChart } from "./MemoizedCharts";
import {
  ReportResult,
  TeamReasonReportResult,
  WeekReasonReportResult,
} from "@hoylu/nexus-service";

export const TaskReason = () => {
  const builder = new TaskDataBuilder();
  const filtersContext = useContext(FilterContext);
  const masterData = useSelector(selectAnalyticsMasterData);

  const strings = Localized.object("ANALYTICS");

  const [reload, setReload] = useState(0);
  const dispatch = useDispatch();
  const reasonData = useSelector(selectTaskReason);
  const reasonDataByWeek = useSelector(selectTaskReasonByWeek);
  const reasonDataByTeam = useSelector(selectTaskReasonByTeam);

  useAsyncEffect(async () => {
    dispatch(
      fetchTaskReason.request({
        ...toReportFilters(filtersContext),
      })
    );
    dispatch(
      fetchTaskReasonByWeek.request({
        ...toReportFilters(filtersContext),
      })
    );
    dispatch(
      fetchTaskReasonByTeam.request({
        ...toReportFilters(filtersContext),
      })
    );
  }, [reload, filtersContext]);

  useEffect(() => {
    setReload(reload + 1);
  }, [filtersContext.project]);

  const pieData = useMemo(() => {
    const collator = Intl.Collator();
    const reasonColorUtils = VarianceReasonsColorUtils.getInstance();
    return builder.buildPieDataSet<ReportResult>(
      reasonData?.data ?? [],
      (reportResult) =>
        masterData.data?.reason.find((r) => r.id === reportResult.value)
          ?.name ?? reportResult.value,
      (reportResult) => reportResult.count,
      (reason) => reasonColorUtils.getColor(reason),
      collator.compare
    );
  }, [reasonData, masterData]);

  const lineData = useMemo(() => {
    const firstWeek = reasonDataByWeek?.data?.[0]?.week;
    if (!firstWeek) return { data: [], size: 0 };
    const reasonColorUtils = VarianceReasonsColorUtils.getInstance();
    return builder.buildLineDataSet<WeekReasonReportResult, ReportResult>(
      reasonDataByWeek?.data ?? [],
      weekGenerator(firstWeek),
      (reportResult) =>
        masterData.data?.reason?.find((s) => s.id === reportResult.value)
          ?.name ?? reportResult.value,
      (report) => report.week,
      (report) => report.reasons,
      (reportResult) => reportResult.count,
      (reason) => reasonColorUtils.getColor(reason),
      (a, b) => 0
    );
  }, [reasonDataByWeek, masterData]);

  const barData = useMemo(() => {
    const collator = Intl.Collator();
    const reasonColorUtils = VarianceReasonsColorUtils.getInstance();
    return builder.buildBarDataSet<TeamReasonReportResult, ReportResult>(
      reasonDataByTeam?.data ?? [],
      (reportResult) => reportResult.count,
      (report) => report.reasons,
      (report) =>
        masterData.data?.team?.find((t) => t.id === report.team)?.name ??
        report.team,
      (reportResult) =>
        masterData.data?.reason?.find((s) => s.id === reportResult.value)
          ?.name ?? reportResult.value,
          (report) =>
            masterData.data?.team?.find((t) => t.id === report.team)?.shortName ??
            report.team.slice(0, 4),
      (reason) => reasonColorUtils.getColor(reason),
      collator.compare,
      collator.compare
    );
  }, [reasonDataByTeam, masterData]);

  return (
    <>
      <Chart state={reasonData}>
        <PieChart
          data={pieData}
          showCenterMetric={true}
          suffix={strings.TASKS}
          defs={taskTypeDefs}
          fill={taskTypeFill}
        />
      </Chart>
      <Chart state={reasonDataByWeek}>
        <LineChart
          data={lineData}
          yLabel={strings.COUNT}
          suffix={strings.TASKS}
          defs={taskTypeDefs}
          fill={taskTypeFill}
        />
      </Chart>
      <Chart state={reasonDataByTeam}>
        <BarChart
          data={barData}
          yLabel={strings.COUNT}
          suffix={strings.TASKS}
          defs={taskTypeDefs}
          fill={taskTypeFill}
          showTooltipTotal={true}
        />
      </Chart>
    </>
  );
};
