import type { WorkspacePermissionsAndUsers } from "./types";
import { defaultGlobalAccess } from "../../utils/create.workspace.utils";

export function defaultRoles(): WorkspacePermissionsAndUsers {
  return {
    administrators: [],
    readers: [],
    writers: [],
    unspecifiedUsers: [],
    globalAccess: defaultGlobalAccess,
  };
}
