import { DocumentError, ERRORCODES } from "@hoylu/web-io-document/src/errors";
import Url from "url";
import { debug } from "debug";
import {
  ForbiddenError,
  NotFoundError,
  ServerError,
  UnauthorizedError,
} from "@hoylu/web-suite/src/context/services/errors";
import { WithHeaders } from "./types";
import fetchRetry from "fetch-retry";

const _LOG = debug("hoylu:service:log");

type Credential = {
  token: string;
};

type CredentialProvider = () => Credential;

export type NexusServiceRequestCallback = (response: Response) => {};

export type Config = {
  server: string;
  apiRoot?: string;
  timeout?: number;
  credentialProvider?: CredentialProvider;
  scopeId: string;
};

/**
 * Fetch with retries on network errors
 */
let _fetch: ReturnType<typeof fetchRetry> | undefined = undefined;
function fetchWithRetry(
  input: RequestInfo | URL,
  init?: RequestInit
): ReturnType<typeof fetch> {
  if (!_fetch) {
    _fetch = fetchRetry(fetch, {
      retries: 3,
      retryOn: [429, 502, 503],
      retryDelay: (attempt) => attempt * 1000,
    });
  }
  return _fetch(input, init);
}

/**
 * Service class for making HTTP requests to the Nexus API
 */
export abstract class NexusService {
  private server: string;
  private apiRoot: string;
  private timeout: number;
  private credentialProvider: CredentialProvider;
  protected scopeId: string = "";
  protected abstract readonly path: string;

  configDefaults: Config = {
    server: "",
    apiRoot: "/api/v1",
    timeout: 15000,
    scopeId: "",
  };

  /**
   * Creates a new NexusService instance
   * @param {Config} config - Configuration object for the service
   * @throws {DocumentError} When required config parameters are missing or invalid
   */
  constructor(config: Config) {
    config = config || {};

    if (!config.server)
      throw new DocumentError("Missing 'server'", ERRORCODES.MISSING_DATA);
    if (!config.credentialProvider)
      throw new DocumentError(
        "Missing 'credentialProvider'",
        ERRORCODES.MISSING_DATA
      );

    let u = Url.parse(config.server);
    if (!u.host) {
      throw new DocumentError("Invalid 'server'", ERRORCODES.INVALID_DATA);
    }

    this.server = `${u.protocol}//${u.host}`;
    this.timeout = config.timeout!;
    this.credentialProvider = config.credentialProvider;
    this.scopeId = config.scopeId || this.configDefaults.scopeId;
    this.apiRoot = config.apiRoot || this.configDefaults.apiRoot!;

    if (this.timeout === null || isNaN(this.timeout) || this.timeout < 0) {
      this.timeout = this.configDefaults.timeout!;
    }
    _LOG("Configure HoyluService", config, this);
  }

  /**
   * Sets the credential provider for authentication
   * @param {CredentialProvider} credentialProvider - Function that provides authentication credentials
   */
  setTokenProvider(credentialProvider: CredentialProvider) {
    this.credentialProvider = credentialProvider;
  }

  /**
   * Gets the authorization headers for requests
   * @returns {Object} Headers object containing the Bearer token
   */
  getAuthHeaders() {
    return {
      authorization: `Bearer ${this.credentialProvider()?.token}`,
    };
  }

  /**
   * Wraps response data with headers
   * @param {Response} response - Fetch Response object
   * @returns {Promise<WithHeaders<T>>} Response data with headers
   */
  async responseWithHeaders<T>(response: Response): Promise<WithHeaders<T>> {
    return {
      data: await response.json(),
      headers: response.headers,
    };
  }

  /**
   * Performs a GET request
   * @param {string} endpoint - API endpoint
   * @param {any} [data] - Query parameters
   * @param {HeadersInit} [headers] - Additional headers
   * @param {AbortSignal} [signal] - AbortController signal
   * @returns {Promise<T>} Response data
   */
  public async getJSON<T>(
    endpoint: string,
    data?: any,
    headers?: HeadersInit,
    signal?: AbortSignal | null
  ): Promise<T> {
    return this.request<T>(
      "GET",
      `${this.apiRoot}/${endpoint}`,
      data,
      headers,
      undefined,
      signal
    );
  }

  public async getJSONWithHeaders<T>(
    endpoint: string,
    data?: any,
    headers?: HeadersInit,
    signal?: AbortSignal | null
  ): Promise<WithHeaders<T>> {
    return this.getWithHeaders<T>(
      `${this.apiRoot}/${endpoint}`,
      data,
      headers,
      signal
    );
  }

  public async postJSON<T>(
    endpoint: string,
    data: any,
    headers?: HeadersInit,
    signal?: AbortSignal | null
  ): Promise<T> {
    return this.request<T>(
      "POST",
      `${this.apiRoot}/${endpoint}`,
      data,
      headers,
      undefined,
      signal
    );
  }

  public async putJSON<T>(
    endpoint: string,
    data: any,
    headers?: HeadersInit,
    signal?: AbortSignal | null
  ): Promise<T> {
    return this.request<T>(
      "PUT",
      `${this.apiRoot}/${endpoint}`,
      data,
      headers,
      undefined,
      signal
    );
  }

  public async patchJSON<T>(
    endpoint: string,
    data: any,
    headers?: HeadersInit,
    signal?: AbortSignal | null
  ): Promise<T> {
    return this.request<T>(
      "PATCH",
      `${this.apiRoot}/${endpoint}`,
      data,
      headers,
      undefined,
      signal
    );
  }

  public async deleteJSON<T>(
    endpoint: string,
    headers?: HeadersInit,
    signal?: AbortSignal | null
  ): Promise<T> {
    return this.request<T>(
      "DELETE",
      `${this.apiRoot}/${endpoint}`,
      "",
      headers,
      undefined,
      signal
    );
  }

  /**
   * Gets more data using the provided link header
   * @param {string} [link] - Link header containing the next page URL
   * @param {AbortSignal} [signal] - AbortController signal
   * @returns {Promise<WithHeaders<T>>} Response data with headers for the next page
   */
  public async getMoreData<T>(
    link?: string,
    signal?: AbortSignal | null
  ): Promise<WithHeaders<T>> {
    if (link) {
      const match = link.match(/^\<(.*?)>; rel=\"next\"$/);
      if (match) {
        return this.getWithHeaders<T>(match[1], "", {}, signal);
      }
    }
    return {
      data: <T>[],
      headers: <Headers>{
        get: (header) => {},
      },
    };
  }

  /**
   * Performs a GET request and includes response headers
   * @param {string} path - Request path
   * @param {any} [data] - Query parameters
   * @param {HeadersInit} [headers] - Additional headers
   * @param {AbortSignal} [signal] - AbortController signal
   * @returns {Promise<WithHeaders<T>>} Response data with headers
   */
  public async getWithHeaders<T>(
    path: string,
    data: any = "",
    headers?: HeadersInit,
    signal?: AbortSignal | null
  ): Promise<WithHeaders<T>> {
    return this.request<WithHeaders<T>>(
      "GET",
      path,
      data,
      headers,
      this.responseWithHeaders,
      signal
    );
  }

  /**
   * Makes an HTTP request to the API
   * @param {string} method - HTTP method
   * @param {string} path - Request path
   * @param {any} [data] - Request data
   * @param {HeadersInit} [headers] - Additional headers
   * @param {NexusServiceRequestCallback} [callback] - Response callback
   * @param {AbortSignal} [signal] - AbortController signal
   * @returns {Promise<T>} Response data
   * @throws {UnauthorizedError | ForbiddenError | NotFoundError | ServerError} On request failure
   */
  public async request<T>(
    method: string,
    path: string,
    data: any = "",
    headers?: HeadersInit,
    callback?: NexusServiceRequestCallback,
    signal?: AbortSignal | null
  ): Promise<T> {
    let options: RequestInit = {
      method: method,
      mode: "cors",
      headers: {
        ...headers,
        "Content-Type": "application/json",
        ...this.getAuthHeaders(),
      },
      signal,
    };
    if (method === "GET") {
      if (data) {
        const params = new URLSearchParams();
        for (var [key, values] of Object.entries(data)) {
          if (Array.isArray(values)) {
            for (let value of values) {
              params.append(key, value);
            }
          } else {
            params.append(key, values as string);
          }
        }
        path = `${path}?${params.toString()}`;
      }
    } else {
      if (!["DELETE", "HEAD"].some((e) => e === method.toUpperCase())) {
        // these methods are body-less too
        options = {
          ...options,
          body: JSON.stringify(data),
        };
      }
    }

    const response = await fetchWithRetry(`${this.server}${path}`, options);
    if (response.ok) {
      if (response.status === 204) {
        return {} as T;
      }
      if (callback) {
        return callback(response) as T;
      }
      return response.json();
    } else {
      _LOG(
        `Calling "${path}" failed: ${this.errorStatusToException(response)}`
      );
      throw this.errorStatusToException(response);
    }
  }

  /**
   * Builds a scoped path by combining scope ID, base path and additional arguments
   * @param {string} [scope] - Optional scope ID to override the default
   * @param {...string} args - Additional path segments
   * @returns {string} Complete scoped path
   */
  protected scopedPath(scope?: string, ...args: string[]) {
    return (
      `${scope || this.scopeId}/${this.path}` +
      (args.length > 0 ? `/${args.join("/")}` : "")
    );
  }

  /**
   * Converts HTTP error status to appropriate exception
   * @param {Response} response - Fetch Response object
   * @returns {Error} Appropriate error instance based on status code
   */
  errorStatusToException(response: Response) {
    try {
      const errorText = response.statusText;
      const message = `Service error: ${errorText}`;
      switch (response.status) {
        case 401:
          return new UnauthorizedError(message);
        case 403:
          return new ForbiddenError(message);
        case 404:
          return new NotFoundError(message);
        default:
          return new ServerError(message, response.status);
      }
    } catch (e) {
      return "Default Service Error";
    }
  }
}
