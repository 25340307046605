import { RootState } from "typesafe-actions";
import { NexusReportService, NexusTaskService } from "@hoylu/nexus-service";

export type ReportFilters = {
  to?: string;
  from?: string;
  query?: string;
  filters: Record<string, string[]>;
};

export const getNexusTaskService = (state: RootState) =>
  new NexusTaskService({
    scopeId: state.context.workspaces.selectedProject?.id!,
    server: state.context.config.serviceConfig.nexus,
    credentialProvider: () => state.context.user,
  });

export const getNexusReportService = (state: RootState) =>
  new NexusReportService({
    scopeId: state.context.workspaces.selectedProject?.id!,
    server: state.context.config.serviceConfig.nexus,
    credentialProvider: () => state.context.user,
  });
