import {
  createAction,
  createAsyncAction,
  EmptyAction,
  PayloadAction,
} from "typesafe-actions";
import type {
  Project,
  ProjectResponse,
  ProjectResponseWithRole,
  WorkspaceDetails,
  WorkspaceDetailsWithUserRoles,
  WorkspaceLabelsRequest,
  WorkspaceLabelsResponse,
  WorkspaceProjectParameters,
} from "./types";
import { DashboardOption } from "./DashboardOption";
import type { UserInvitePayload } from "../user/user.actions";
import {
  CreateWorkspaceDetails,
  DuplicateWorkspaceDetails,
  UpsertWorkspaceUsersPermissions,
} from "../../services/workspaces/api/workspaces.v3.types";
import { UserWorkspaceTonnageResponse } from "./types";
import { StoredSearchParams, TaskLocationsData } from "./workspaces.reducer";
import type { AbsolutePosition, Point } from "@hoylu/client-common";

type WorkspaceId = string;

export type PopulateAllWorkspacesResult = {
  details: WorkspaceDetails[];
};

export type PopulateWorkspacesResult = {
  details: WorkspaceDetails[];
  cursorOrOffset: string | number | null;
};

export type PopulateWorkspacesRequest = {
  limit: number;
  cursorOrOffset?: string | number;
};

export const populateAllWorkspaces = createAsyncAction(
  "POPULATE_ALL_WORKSPACES_REQUEST",
  "POPULATE_ALL_WORKSPACES_SUCCESS",
  "POPULATE_ALL_WORKSPACES_FAILURE"
)<
  undefined,
  PopulateAllWorkspacesResult,
  {
    error: Error;
    requestAction: EmptyAction<"POPULATE_ALL_WORKSPACES_REQUEST">;
    message: string;
  }
>();

export const populateInitialWorkspaces = createAsyncAction(
  "POPULATE_INITIAL_WORKSPACES_REQUEST",
  "POPULATE_INITIAL_WORKSPACES_SUCCESS",
  "POPULATE_INITIAL_WORKSPACES_FAILURE"
)<PopulateWorkspacesRequest, PopulateWorkspacesResult, Error>();

export const loadMoreWorkspaces = createAsyncAction(
  "LOAD_MORE_WORKSPACES_REQUEST",
  "LOAD_MORE_WORKSPACES_SUCCESS",
  "LOAD_MORE_WORKSPACES_FAILURE"
)<
  PopulateWorkspacesRequest,
  PopulateWorkspacesResult,
  {
    error: Error;
    requestAction: PayloadAction<
      "LOAD_MORE_WORKSPACES_REQUEST",
      PopulateWorkspacesRequest
    >;
    message: string;
  }
>();

export const loadHiddenWorkspaces = createAsyncAction(
  "LOAD_HIDDEN_WORKSPACES_REQUEST",
  "LOAD_HIDDEN_WORKSPACES_SUCCESS",
  "LOAD_HIDDEN_WORKSPACES_FAILURE"
)<PopulateWorkspacesRequest, PopulateWorkspacesResult, Error>();

export const atMaxWorkspaces = createAction("AT_MAX_WORKSPACES")<WorkspaceId>();

export const getWorkspaceDetails = createAsyncAction(
  "GET_WORKSPACE_DETAIL_REQUEST",
  "GET_WORKSPACE_DETAIL_SUCCESS",
  "GET_WORKSPACE_DETAIL_FAILURE"
)<
  WorkspaceId,
  WorkspaceDetails,
  {
    error: Error;
    workspaceId: string;
    requestAction: PayloadAction<"GET_WORKSPACE_DETAIL_REQUEST", WorkspaceId>;
    message: string;
  }
>();

export const workspaceChannelReady = createAction("WORKSPACE_CHANNEL_READY")<
  WorkspaceId
>();
export const workspaceDocumentReady = createAction("WORKSPACE_DOCUMENT_READY")<
  WorkspaceId
>();
export const makeWorkspaceLive = createAction("MAKE_WORKSPACE_LIVE")<
  WorkspaceId
>();
export const queueEditWorkspace = createAction("QUEUE_EDIT_WORKSPACE")<{
  workspaceId: WorkspaceId;
  allowEditTemplates?: boolean;
}>();
export const searchParamsForEditWorkspace = createAction(
  "SEARCH_PARAMS_FOR_EDIT_WORKSPACE"
)<StoredSearchParams>();
export const queueProjectLoading = createAction("QUEUE_PROJECT_LOADING")<
  string | undefined
>();
export const queueCreateWorkspace = createAction("QUEUE_CREATE_WORKSPACE")<
  undefined
>();
export const workspaceProgressUpdate = createAction(
  "WORKSPACE_LOAD_PROGRESS_UPDATE"
)<ProgressParameters>();
export const applyTemplateToWorkspace = createAction(
  "APPLY_TEMPLATE_WORKSPACE"
)<{
  workspaceId: string;
  templateName: string;
}>();
export type CreateWorkspaceFromTemplateParameters = WorkspaceDetails;
export const createWorkspaceFromTemplate = createAction(
  "CREATE_WORKSPACE_FROM_TEMPLATE"
)<CreateWorkspaceFromTemplateParameters>();
export const editWorkspace = createAction("EDIT_WORKSPACE")<
  Required<Pick<WorkspaceDetails, "workspaceId" | "lastAccess">>
>();
export const setSearchTerm = createAction("SET_SEARCH_TERM")<string>();
export const setLastAccess = createAction("SET_LAST_ACCESS")<
  Required<Pick<WorkspaceDetails, "workspaceId" | "lastAccess">>
>();
export const showDashboard = createAction("SHOW_DASHBOARD")<undefined>();
export const unfocusWorkspaces = createAction("UNFOCUS_WORKSPACES")<
  undefined
>();
export type FocusWorkspaceParameters = {
  workspaceId: string;
  isFocused: boolean;
};

export type ProgressParameters = {
  workspaceId: string;
  progress: number;
  progressStep: number;
};
export const toggleFocusWorkspace = createAction("TOGGLE_FOCUS_WORKSPACE")<
  FocusWorkspaceParameters
>();
export const workspaceShowingPrompt = createAction("WORKSPACE_SHOWING_PROMPT")<
  undefined
>();
export const toggleHiddenWorkspaces = createAction("TOGGLE_HIDDEN_WORKSPACES")<
  undefined
>();
export const selectProject = createAction("SELECT_PROJECT")<Project | null>();

export type Language = {
  language: string;
};

export type CreateWorkspaceParameters = {
  details: WorkspaceDetails;
  isPrivate?: boolean;
  password?: string;
};

// TODO: get better at typescript magic so we can write:
// export const createWorkspace = createRetryableAsyncAction(...)
// and it would automatically add the requestAction to the failure payload
export const createWorkspace = createAsyncAction(
  "CREATE_WORKSPACE_REQUEST",
  "CREATE_WORKSPACE_SUCCESS",
  "CREATE_WORKSPACE_FAILURE"
)<
  CreateWorkspaceDetails,
  WorkspaceDetails,
  {
    error: Error;
    requestAction: PayloadAction<
      "CREATE_WORKSPACE_REQUEST",
      CreateWorkspaceDetails
    >;
    message: string;
  }
>();

/** Do not use this action directly - but only call it in epics. The underlying API is going to be depreceted. */
export const updateWorkspaceDetails = createAsyncAction(
  "UPDATE_WORKSPACE_DETAILS_REQUEST",
  "UPDATE_WORKSPACE_DETAILS_SUCCESS",
  "UPDATE_WORKSPACE_DETAILS_FAILURE"
)<
  WorkspaceDetails,
  WorkspaceDetails,
  {
    error: Error;
    requestAction: PayloadAction<
      "UPDATE_WORKSPACE_DETAILS_REQUEST",
      WorkspaceDetails
    >;
    message: string;
  }
>();

export interface UpsertWorkspaceUsersParameters {
  workspaceId: string;
  userEmails: string[];
  permission: UpsertWorkspaceUsersPermissions;
}

export const getProjectCollaborators = createAsyncAction(
  "GET_PROJECT_COLLABORATORS_REQUEST",
  "GET_PROJECT_COLLABORATORS_SUCCESS",
  "GET_PROJECT_COLLABORATORS_FAIURE"
)<string, string[], Error>();

export const upsertWorkspaceUsers = createAsyncAction(
  "UPSERT_WORKSPACE_USERS_REQUEST",
  "UPSERT_WORKSPACE_USERS_SUCCESS",
  "UPSERT_WORKSPACE_USERS_FAILURE"
)<
  UpsertWorkspaceUsersParameters,
  UpsertWorkspaceUsersParameters,
  {
    error: Error;
    requestAction: PayloadAction<
      "UPSERT_WORKSPACE_USERS_REQUEST",
      UpsertWorkspaceUsersParameters
    >;
    message: string;
  }
>();

export type RemoveWorkspaceUsersParameters = {
  workspaceId: string;
  userEmails: string[];
};
export const removeWorkspaceUsers = createAsyncAction(
  "REMOVE_WORKSPACE_USERS_REQUEST",
  "REMOVE_WORKSPACE_USERS_SUCCESS",
  "REMOVE_WORKSPACE_USERS_FAILURE"
)<
  RemoveWorkspaceUsersParameters,
  RemoveWorkspaceUsersParameters,
  {
    error: Error;
    requestAction: PayloadAction<
      "REMOVE_WORKSPACE_USERS_REQUEST",
      RemoveWorkspaceUsersParameters
    >;
    message: string;
  }
>();

export type WorkspaceNameParameters = {
  workspaceId: string;
  name: string;
};
export const updateWorkspaceName = createAsyncAction(
  "UPDATE_WORKSPACE_NAME_REQUEST",
  "UPDATE_WORKSPACE_NAME_SUCCESS",
  "UPDATE_WORKSPACE_NAME_FAILURE"
)<
  WorkspaceNameParameters,
  WorkspaceNameParameters,
  {
    error: Error;
    requestAction: PayloadAction<
      "UPDATE_WORKSPACE_NAME_REQUEST",
      WorkspaceNameParameters
    >;
    message: string;
  }
>();
export const updateWorkspaceLabels = createAsyncAction(
  "UPDATE_WORKSPACE_LABELS_REQUEST",
  "UPDATE_WORKSPACE_LABELS_SUCCESS",
  "UPDATE_WORKSPACE_LABELS_FAILURE"
)<
  WorkspaceLabelsRequest,
  WorkspaceLabelsResponse,
  {
    error: Error;
    requestAction: PayloadAction<
      "UPDATE_WORKSPACE_LABELS_REQUEST",
      WorkspaceLabelsRequest
    >;
    message: string;
  }
>();
export const handleFullscreenChange = createAction("HANDLE_FULLSCREEN")();
export const setFocusAction = createAction("SET_FOCUS")();
export const markWorkspaceAsFavorite = createAction("MARK_WORKSPACE_FAVORITE")<
  string
>();

export const toggleWorkspaceVisibility = createAsyncAction(
  "TOGGLE_WORKSPACE_VISIBILITY_REQUEST",
  "TOGGLE_WORKSPACE_VISIBILITY_SUCCESS",
  "TOGGLE_WORKSPACE_VISIBILITY_FAILURE"
)<
  WorkspaceDetails,
  WorkspaceDetails,
  {
    error: Error;
    requestAction: PayloadAction<
      "TOGGLE_WORKSPACE_VISIBILITY_REQUEST",
      WorkspaceDetails
    >;
    message: string;
  }
>();

export const getMyTonnage = createAsyncAction(
  "GET_MY_TONNAGE_REQUEST",
  "GET_MY_TONNAGE_SUCCESS",
  "GET_MY_TONNAGE_FAILURE"
)<undefined, UserWorkspaceTonnageResponse, Error>();

export const getUserTonnage = createAsyncAction(
  "GET_USER_TONNAGE_REQUEST",
  "GET_USER_TONNAGE_SUCCESS",
  "GET_USER_TONNAGE_FAILURE"
)<string, UserWorkspaceTonnageResponse, Error>();

export const recalculateUserTonnage = createAsyncAction(
  "RECALCULATE_USER_TONNAGE_REQUEST",
  "RECALCULATE_USER_TONNAGE_SUCCESS",
  "RECALCULATE_USER_TONNAGE_FAILURE"
)<string, undefined, Error>();

type DeleteWorkspaceParams = {
  details: WorkspaceDetails;
  password?: string;
};

export const leaveWorkspace = createAsyncAction(
  "LEAVE_WORKSPACE_REQUEST",
  "LEAVE_WORKSPACE_SUCCESS",
  "LEAVE_WORKSPACE_FAILURE"
)<
  WorkspaceDetails,
  undefined,
  {
    error: Error;
    requestAction: PayloadAction<"LEAVE_WORKSPACE_REQUEST", WorkspaceDetails>;
    message: string;
  }
>();

export const deleteWorkspace = createAsyncAction(
  "DELETE_WORKSPACE_REQUEST",
  "DELETE_WORKSPACE_SUCCESS",
  "DELETE_WORKSPACE_FAILURE"
)<
  DeleteWorkspaceParams,
  undefined,
  {
    error: Error;
    requestAction: PayloadAction<
      "DELETE_WORKSPACE_REQUEST",
      DeleteWorkspaceParams
    >;
    message: string;
  }
>();

export type DuplicateWorkspaceSuccessParameters = {
  details: WorkspaceDetails;
  open?: boolean;
};

export const duplicateWorkspace = createAsyncAction(
  "DUPLICATE_WORKSPACE_REQUEST",
  "DUPLICATE_WORKSPACE_SUCCESS",
  "DUPLICATE_WORKSPACE_FAILURE"
)<
  DuplicateWorkspaceDetails,
  DuplicateWorkspaceSuccessParameters,
  {
    error: Error;
    requestAction: PayloadAction<
      "DUPLICATE_WORKSPACE_REQUEST",
      DuplicateWorkspaceDetails
    >;
    message: string;
    clearError: boolean;
  }
>();

export const cancelDashboardOption = createAction("CANCEL_DASHBOARD_OPTION")();

export type ModalOptions = {
  modalTitle?: string;
  modalText?: string;
  modalButtonText?: string;
  modalButtonLinkUrl?: string;
};

type ActivateOptionParams = {
  optionType: DashboardOption;
  workspaceId?: string;
  useCaseName?: string;
  modalOptions?: ModalOptions;
  emailPreset?: string;
  showOverlay?: boolean;
  dialogPosition?: Point | AbsolutePosition;
  taskLocationsData?: TaskLocationsData;
};

export const activateDashboardOption = createAction(
  "ACTIVATE_DASHBOARD_OPTION"
)<ActivateOptionParams>();

export type SetWorkspacePasswordParameters = {
  workspaceId: string;
  currPassword: string;
  newPassword: string;
};

export const setWorkspacePassword = createAsyncAction(
  "SET_WORKSPACE_PASSWORD_REQUEST",
  "SET_WORKSPACE_PASSWORD_SUCCESS",
  "SET_WORKSPACE_PASSWORD_FAILURE"
)<
  SetWorkspacePasswordParameters,
  SetWorkspacePasswordParameters,
  {
    error: Error;
    message: string;
    requestAction: PayloadAction<
      "SET_WORKSPACE_PASSWORD_REQUEST",
      SetWorkspacePasswordParameters
    >;
  }
>();

export type RemoveWorkspacePasswordParameters = {
  workspaceId: string;
  currPassword: string;
};

export const removeWorkspacePassword = createAsyncAction(
  "REMOVE_WORKSPACE_PASSWORD_REQUEST",
  "REMOVE_WORKSPACE_PASSWORD_SUCCESS",
  "REMOVE_WORKSPACE_PASSWORD_FAILURE"
)<
  RemoveWorkspacePasswordParameters,
  RemoveWorkspacePasswordParameters,
  {
    error: Error;
    message: string;
    requestAction: PayloadAction<
      "REMOVE_WORKSPACE_PASSWORD_REQUEST",
      RemoveWorkspacePasswordParameters
    >;
  }
>();

// Execute along populate workspaces, and also when opening assign-workspace-to-project dialog for updating the list
export const assignableProjects = createAsyncAction(
  "ASSIGNABLE_PROJECTS_REQUEST",
  "ASSIGNABLE_PROJECTS_SUCCESS",
  "ASSIGNABLE_PROJECTS_FAILURE"
)<
  undefined,
  ProjectResponse[],
  {
    error: Error;
    requestAction: EmptyAction<"ASSIGNABLE_PROJECTS_REQUEST">;
    message: string;
  }
>();

// Execute along populate workspaces
export const getProjectsOfUser = createAsyncAction(
  "GET_PROJECTS_OF_USER_REQUEST",
  "GET_PROJECTS_OF_USER_SUCCESS",
  "GET_PROJECTS_OF_USER_FAILURE"
)<
  undefined,
  ProjectResponseWithRole[],
  {
    error: Error;
    requestAction: EmptyAction<"GET_PROJECTS_OF_USER_REQUEST">;
    message: string;
  }
>();

export const addWorkspaceToProject = createAsyncAction(
  "ADD_WORKSPACE_TO_PROJECT_REQUEST",
  "ADD_WORKSPACE_TO_PROJECT_SUCCESS",
  "ADD_WORKSPACE_TO_PROJECT_FAILURE"
)<
  WorkspaceProjectParameters,
  WorkspaceProjectParameters,
  {
    error: Error;
    requestAction: PayloadAction<
      "ADD_WORKSPACE_TO_PROJECT_REQUEST",
      WorkspaceProjectParameters
    >;
    message: string;
  }
>();

export const removeWorkspaceFromProject = createAsyncAction(
  "REMOVE_WORKSPACE_FROM_PROJECT_REQUEST",
  "REMOVE_WORKSPACE_FROM_PROJECT_SUCCESS",
  "REMOVE_WORKSPACE_FROM_PROJECT_FAILURE"
)<
  WorkspaceProjectParameters,
  WorkspaceProjectParameters,
  {
    error: Error;
    requestAction: PayloadAction<
      "REMOVE_WORKSPACE_FROM_PROJECT_REQUEST",
      WorkspaceProjectParameters
    >;
    message: string;
  }
>();

export const getWorkspacesOfProject = createAsyncAction(
  "GET_WORKSPACE_OF_PROJECT_REQUEST",
  "GET_WORKSPACE_OF_PROJECT_SUCCESS",
  "GET_WORKSPACE_OF_PROJECT_FAILURE"
)<
  string,
  WorkspaceDetails[],
  {
    error: Error;
    requestAction: PayloadAction<"GET_WORKSPACE_OF_PROJECT_REQUEST", string>;
    message: string;
  }
>();

export const importProjectUsers = createAction(
  "IMPORT_PROJECT_USERS_TO_WORKSPACE_REQUEST"
)<WorkspaceDetailsWithUserRoles>();

export interface InviteToWorkspacePayload {
  invite: UserInvitePayload;
  workspaceId: string;
  newUsersPermission: UpsertWorkspaceUsersPermissions;
}

export const inviteToWorkspace = createAsyncAction(
  "INVITE_TO_WORKSPACE_REQUEST",
  "INVITE_TO_WORKSPACE_SUCCESS",
  "INVITE_TO_WORKSPACE_FAILURE"
)<InviteToWorkspacePayload, never, Error>();

export const createWorkspaceFromTemplateId = createAsyncAction(
  "CREATE_WORKSPACE_FROM_TEMPLATE_ID_REQUEST",
  "CREATE_WORKSPACE_FROM_TEMPLATE_ID_SUCCESS",
  "CREATE_WORKSPACE_FROM_TEMPLATE_ID_FAILURE"
)<
  { templateId: string },
  never,
  {
    error: Error;
    requestAction: PayloadAction<
      "CREATE_WORKSPACE_FROM_TEMPLATE_ID_REQUEST",
      { templateId: string }
    >;
    message: string;
  }
>();

export const editThumbnail = createAction("EDIT_THUMBNAIL")<{
  flag: boolean;
}>();

export const uploadThumbnail = createAsyncAction(
  "UPLOAD_THUMBNAIL_REQUEST",
  "UPLOAD_THUMBNAIL_SUCCESS",
  "UPLOAD_THUMBNAIL_FAILURE"
)<
  { workspaceId: string; file: Blob },
  { workspaceId: string; url: string },
  Error
>();

export const removeThumbnail = createAsyncAction(
  "REMOVE_THUMBNAIL_REQUEST",
  "REMOVE_THUMBNAIL_SUCCESS",
  "REMOVE_THUMBNAIL_FAILURE"
)<{ workspaceId: string }, { workspaceId: string }, Error>();

export const workspaceSnapshot = createAsyncAction(
  "WORKSPACE_SNAPSHOT_REQUEST",
  "WORKSPACE_SNAPSHOT_SUCCESS",
  "WORKSPACE_SNAPSHOT_FAILURE"
)<{ workspaceId: string }, { workspaceId: string; snapshot: Blob }, Error>();
