import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  ppcSorter,
  TaskDataBuilder,
  weekGenerator,
} from "../../utils/analytics/task.data.builder";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAnalyticsMasterData,
  selectTaskPPC,
  selectTaskPPCByTeam,
  selectTaskPPCByWeek,
} from "../../state/analytics/analytics.selector";
import {
  fetchTaskPPC,
  fetchTaskPPCByTeam,
  fetchTaskPPCByWeek,
} from "../../state/analytics/analytics.actions";
import { selectedProject } from "../../state/workspaces/workspaces.selector";
import { FilterContext, toReportFilters } from "./FilterProvider";
import { Chart } from "./Chart";
import {
  barChartPercentLabelFormatter,
  percentageChartValueFormat,
  PpcColorUtils,
} from "@hoylu/nivo-charts";
import { Localized } from "../../strings";
import { BarChart, LineChart, PieChart } from "./MemoizedCharts";
import { PpcResult, PpcTeamResult, PpcWeekResult } from "@hoylu/nexus-service";

const ppcKeySelector = (status: string) =>
  status === "Completed" ? status : "Incomplete";

const ppcValueSelector = (obj: PpcResult) => obj.percentage;
const ppcPercentageValueSelector = (obj: PpcResult) =>
  Math.round(obj.percentage * 100);

export const TaskPPC = () => {
  const builder = new TaskDataBuilder();
  const filtersContext = useContext(FilterContext);
  const masterData = useSelector(selectAnalyticsMasterData);

  const strings = Localized.object("ANALYTICS");

  const [reload, setReload] = useState(0);
  const dispatch = useDispatch();
  const ppcData = useSelector(selectTaskPPC);
  const ppcDataByWeek = useSelector(selectTaskPPCByWeek);
  const ppcDataByTeam = useSelector(selectTaskPPCByTeam);
  const project = useSelector(selectedProject);

  useEffect(() => {
    dispatch(
      fetchTaskPPC.request({
        ...toReportFilters(filtersContext),
      })
    );
    dispatch(
      fetchTaskPPCByWeek.request({
        ...toReportFilters(filtersContext),
      })
    );
    dispatch(
      fetchTaskPPCByTeam.request({
        ...toReportFilters(filtersContext),
      })
    );
  }, [reload, filtersContext]);

  useEffect(() => {
    setReload(reload + 1);
  }, [project]);

  const pieData = useMemo(() => {
    const ppcColorUtils = new PpcColorUtils();
    return builder.buildPieDataSet<PpcResult>(
      ppcData?.data ?? [],
      (ppcResult) => ppcKeySelector(ppcResult.status),
      ppcValueSelector,
      (status) => ppcColorUtils.getColor(status),
      ppcSorter
    );
  }, [ppcData]);

  const lineData = useMemo(() => {
    const firstWeek = ppcDataByWeek?.data?.[0]?.week;
    if (!firstWeek) return { data: [], size: 0 };
    const ppcColorUtils = new PpcColorUtils();
    return builder.buildLineDataSet<PpcWeekResult, PpcResult>(
      ppcDataByWeek?.data ?? [],
      weekGenerator(firstWeek),
      (ppcResult) => ppcKeySelector(ppcResult.status),
      (ppcReport) => ppcReport.week,
      (ppcReport) => ppcReport.ppcResult,
      ppcPercentageValueSelector,
      (status) => ppcColorUtils.getColor(status),
      ppcSorter
    );
  }, [ppcDataByWeek, masterData]);

  const barData = useMemo(() => {
    const collator = Intl.Collator();
    const ppcColorUtils = new PpcColorUtils();
    return builder.buildBarDataSet<PpcTeamResult, PpcResult>(
      ppcDataByTeam?.data ?? [],
      ppcPercentageValueSelector,
      (ppcReport) => ppcReport.ppcResult,
      (ppcReport) =>
        masterData.data?.team?.find((t) => t.id === ppcReport.team)?.name ??
        ppcReport.team,
      (ppcResult) => ppcKeySelector(ppcResult.status),
      (report) =>
        masterData.data?.team?.find((t) => t.id === report.team)?.shortName ??
        report.team.slice(0, 4),
      (status) => ppcColorUtils.getColor(status),
      collator.compare,
      ppcSorter
    );
  }, [ppcDataByTeam, masterData]);

  return (
    <>
      <Chart state={ppcData}>
        <PieChart
          data={pieData}
          showCenterMetric={false}
          valueFormat={percentageChartValueFormat}
          suffix={strings.TASKS}
        />
      </Chart>
      <Chart state={ppcDataByWeek}>
        <LineChart
          data={lineData}
          yLabel={strings.PERCENT_COMPLETE}
          suffix={`% ${strings.TASKS}`}
        />
      </Chart>
      <Chart state={ppcDataByTeam}>
        <BarChart
          data={barData}
          yLabel={strings.PERCENT_COMPLETE}
          suffix={`% ${strings.TASKS}`}
          labelFormatter={barChartPercentLabelFormatter}
          showTooltipTotal={false}
        />
      </Chart>
    </>
  );
};
