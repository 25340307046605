import {
  CreateWorkspaceDetails,
  DuplicateWorkspaceDetails,
} from "../services/workspaces/api/workspaces.v3.types";
import { GlobalAccess } from "../services/workspaces/global.access";
import { defaultModule } from "./defaultModule";

export const defaultWorkspaceName = "New workspace";
export const defaultGlobalAccess: GlobalAccess = GlobalAccess.NONE;

export const getCreateFromTemplatePayload = (
  workspaceId: string
): DuplicateWorkspaceDetails => {
  return {
    workspaceId,
    open: true,
    workspaceName: defaultWorkspaceName,
    globalAccess: defaultGlobalAccess,
  };
};

export const getCreateBlankWorkspacePayload = (): CreateWorkspaceDetails => ({
  globalAccess: defaultGlobalAccess,
  module: { name: defaultModule },
  workspaceName: defaultWorkspaceName,
});
