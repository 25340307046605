import { AnalyticData } from "../../state/analytics/analytics.reducer";
import React from "react";
import { MediumRippleSpinner } from "../RippleSpinner";
import Styles from "./Analytics.module.css";
import { useI18n } from "../../utils/hooks/use.i18n";

export type ChartProps<T> = {
  state?: AnalyticData<T>;
};

const Overlay = ({ state }: { state?: AnalyticData<any> }) => {
  const t = useI18n("ANALYTICS.");

  if (state?.isLoading) {
    return (
      <div className={`${Styles.chartOverlay}`}>
        <MediumRippleSpinner />
      </div>
    );
  }

  if (state?.fetchFailed) {
    return (
      <div className={`${Styles.chartOverlay}`}>
        <span>{t("LOAD_ERROR")}</span>
      </div>
    );
  }

  if (
    !state?.isLoading &&
    Array.isArray(state?.data) &&
    state?.data.length === 0
  ) {
    return (
      <div className={`${Styles.chartOverlay}`}>
        <span>{t("NO_DATA")}</span>
      </div>
    );
  }

  return null;
};

export const Chart = <T,>({
  state,
  children,
}: React.PropsWithChildren<ChartProps<T>>) => {
  return (
    <div className={Styles.chart}>
      <Overlay state={state} />
      {children}
    </div>
  );
};
