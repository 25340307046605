import { combineEpics } from "redux-observable";
import { catchError } from "rxjs/operators";
import { merge, of } from "rxjs";
import { AppEpic } from "../services/types";
import { epicFailure } from "./root.action";

import * as configEpics from "../services/config/config.epic";
import * as loginEpics from "../services/user/login.epic";
import * as featureFlagUpdateEpics from "../services/user/featureFlagUpdate.epic";
import * as userEpics from "../services/user/user.epic";
import * as workspaceEpics from "../services/workspaces/workspaces.epic";
import * as tonnageEpics from "../services/workspaces/tonnage.epic";
import * as projectEpics from "../services/workspaces/projects.epic";
import * as viewModeEpics from "../services/viewMode/viewMode.epic";
import * as retryEpics from "../services/retry.epic";
import * as pendoEpics from "../services/user/pendo.epic";
import * as templateEpics from "../services/templates/templates.epic";
import * as analyticsEpics from "../services/analytics/analytics.epic";

const rootEpic = combineAndProtectEpics(
  ...Object.values(configEpics),
  ...Object.values(loginEpics),
  ...Object.values(featureFlagUpdateEpics),
  ...Object.values(userEpics),
  ...Object.values(workspaceEpics),
  ...Object.values(tonnageEpics),
  ...Object.values(projectEpics),
  ...Object.values(retryEpics),
  ...Object.values(viewModeEpics),
  ...Object.values(pendoEpics),
  ...Object.values(templateEpics),
  ...Object.values(analyticsEpics)
);

function protect(epic: AppEpic): AppEpic {
  return (action$, state$, dependencies) =>
    epic(action$, state$, dependencies).pipe(
      catchError((error, sourceObservable) => {
        dependencies.log.error("epic failure", error);
        return merge(of(epicFailure(error)), sourceObservable);
      })
    );
}

export function combineAndProtectEpics(...args: AppEpic[]) {
  const protectedEpics = Array.from(args).map(protect);
  return combineEpics(...protectedEpics);
}

export default rootEpic;
