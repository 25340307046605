/* global Promise */
/* global fetch */

import { DocumentError, ERRORCODES } from "./errors";
import Url from "url";
import { SCHEMA_VERSION } from "./constants";

const getDocumentV1ApiPath = (id: string) => `/api/v1/${id}`;
const v3ApiPath = "/api/v3";

export const DocumentTypes = {
  HOYLU: "HOYLU",
  SKETCH: "SKETCH",
  FLOW: "FLOW",
  EXPERIMENTAL: "EXPERIMENTAL",
};

export const Permissions = {
  Read: "Read",
  Write: "Write",
};

type Credential = {
  token: string;
};

type CredentialProvider = () => Credential;

type Config = {
  server: string;
  timeout?: number;
  credentialProvider: CredentialProvider;
};

export class DocumentService {
  private server: string;
  private timeout: number;
  private credentialProvider: CredentialProvider;

  constructor(config: Config) {
    config = config || {};

    if (!config.server)
      throw new DocumentError("Missing 'server'", ERRORCODES.MISSING_DATA);
    if (!config.credentialProvider)
      throw new DocumentError(
        "Missing 'credentialProvider'",
        ERRORCODES.MISSING_DATA
      );

    let u = Url.parse(config.server);
    if (!u.host)
      throw new DocumentError("Invalid 'server'", ERRORCODES.INVALID_DATA);

    this.server = Url.format(u);
    this.timeout = config.timeout || 15000;
    this.credentialProvider = config.credentialProvider;

    if (this.timeout === null || isNaN(this.timeout) || this.timeout < 0) {
      this.timeout = 15000;
    }
  }

  setTokenProvider(credentialProvider: CredentialProvider) {
    this.credentialProvider = credentialProvider;
  }

  private getToken() {
    if (this.credentialProvider) {
      let credentials = this.credentialProvider();
      if (credentials) {
        return credentials.token;
      }
    }
    return undefined;
  }

  hasPassword(id: string) {
    validateDocumentId(id);

    let url = Url.resolve(this.server, `${getDocumentV1ApiPath(id)}/password`);

    let options: any = {
      method: "HEAD",
      timeout: this.timeout,
      mode: "cors",
      headers: {
        authorization: undefined,
      },
    };

    let credentials = this.getToken();
    if (credentials) {
      options.headers.authorization = "Bearer " + credentials;
    }
    return fetch(url, options as RequestInit).then((response) => {
      if (response.status === 200) {
        return true;
      } else if (response.status === ERRORCODES.NOT_FOUND) {
        return false;
      } else if (
        response.status === ERRORCODES.REQUEST_ERROR ||
        response.status === ERRORCODES.NOT_AUTHORIZED ||
        response.status === ERRORCODES.FORBIDDEN ||
        response.status === ERRORCODES.SERVER_ERROR
      ) {
        return Promise.reject(
          new DocumentError(
            `Server: ${response.status} unable test password for document`,
            response.status
          )
        );
      } else {
        return Promise.reject(
          new DocumentError(
            `Server returned ${response.status} for id: ${id}`,
            ERRORCODES.SERVER_ERROR
          )
        );
      }
    });
  }

  connect(id: string, password?: string) {
    validateDocumentId(id);
    const url = new URL(
      Url.resolve(this.server, `${v3ApiPath}/workspace/${id}/open`)
    );
    url.searchParams.set(
      "maxSupportedSchemaVersion",
      SCHEMA_VERSION.toString()
    );

    const options: any = {
      method: "GET",
      timeout: this.timeout,
      mode: "cors",
      headers: {
        authorization: undefined,
      },
    };

    const credentials = this.getToken();
    if (credentials) {
      options.headers.authorization = "Bearer " + credentials;
    }

    if (password && typeof password === "string") {
      options.headers["X-Hoylu-Workspace-Password"] = password;
    }

    return fetch(url.toString(), options as RequestInit)
      .then((response) => {
        if (response.status === 200) {
          // could throw parse error here
          return response.json();
        } else if (
          response.status === ERRORCODES.REQUEST_ERROR ||
          response.status === ERRORCODES.NOT_AUTHORIZED ||
          response.status === ERRORCODES.FORBIDDEN ||
          response.status === ERRORCODES.NOT_FOUND ||
          response.status === ERRORCODES.PRECONDITION_FAILED ||
          response.status === ERRORCODES.SERVER_ERROR
        ) {
          return Promise.reject(
            new DocumentError(`${response.status} id: ${id}`, response.status)
          );
        } else {
          return Promise.reject(
            new DocumentError(
              `Server returned ${response.status} for id: ${id}`,
              ERRORCODES.SERVER_ERROR
            )
          );
        }
      })
      .then((body) => {
        if (!body || !body.workspaceId) {
          Promise.reject(
            new DocumentError(
              "Missing data in response",
              ERRORCODES.MISSING_DATA
            )
          );
        }
        return body;
      });
  }

  updateSchemaVersion(id: string, documentMetadata: any) {
    validateDocumentId(id);
    const url = new URL(Url.resolve(this.server, getDocumentV1ApiPath(id)));

    documentMetadata.schemaVersion = SCHEMA_VERSION;

    const credentials = this.getToken();
    const authorization = credentials ? "Bearer " + credentials : undefined;

    const options: any = {
      method: "PUT",
      timeout: this.timeout,
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        authorization,
      },
      body: JSON.stringify(documentMetadata),
    };

    return fetch(url.toString(), options as RequestInit)
      .then((response) => {
        if (response.status === 200) {
          // could throw parse error here
          return response.json();
        } else if (
          //UpgradeRequired
          response.status === ERRORCODES.REQUEST_ERROR ||
          response.status === ERRORCODES.FORBIDDEN ||
          response.status === ERRORCODES.NOT_FOUND ||
          response.status === ERRORCODES.SERVER_ERROR
        ) {
          return Promise.reject(
            new DocumentError(`${response.status} id: ${id}`, response.status)
          );
        } else {
          return Promise.reject(
            new DocumentError(
              `Server returned ${response.status} for id: ${id}`,
              ERRORCODES.SERVER_ERROR
            )
          );
        }
      })
      .then((body) => {
        if (!body || !body.documentId) {
          Promise.reject(
            new DocumentError(
              "Missing data in response",
              ERRORCODES.MISSING_DATA
            )
          );
        }
        return body;
      });
  }

  getProject(documentId: string, projectId: string) {
    validateDocumentId(documentId);
    const url = new URL(Url.resolve(this.server, `${v3ApiPath}/projects/${projectId}`));
    let credentials = this.getToken();

    const headers = credentials
      ? { authorization: "Bearer " + credentials }
      : undefined;

    const options: RequestInit = {
      method: "GET",
      mode: "cors",
      headers,
      signal: AbortSignal.timeout(this.timeout)
    };

    /*** Triggering notifications requires providing both the ID and the name of the assigned project.
     * To avoid having to specify these details each time the trigger is sent, we retrieve the missing project name
     * when the document is loaded. The request should not cause the application to crash.
     * */
    return fetch(url, options as RequestInit)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        return Promise.resolve();
      })
      .catch(() => {
          return Promise.resolve()
        }
      )
  }

  //TODO: This needs to be wired to correct endpoint, for now check if existing projects is assignable by the user - that requires the admin role
  myProjects() {
    const url = new URL(Url.resolve(this.server, `${v3ApiPath}/projects/my`));
    let credentials = this.getToken();

    const headers = credentials
      ? {authorization: "Bearer " + credentials}
      : undefined;

    const options: RequestInit = {
      method: "GET",
      mode: "cors",
      headers,
      signal: AbortSignal.timeout(this.timeout)
    };
    return fetch(url, options as RequestInit).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        // This request should not cause the application to crash - however this should really be handled by the caller
        return Promise.resolve();
      }
    })

  }


  roles(id: string) {
    validateDocumentId(id);
    const url = Url.resolve(this.server, `${v3ApiPath}/workspace/${id}/roles`);

    const credentials = this.getToken();
    const headers = credentials
      ? {authorization: "Bearer " + credentials}
      : undefined;

    const options: RequestInit = {
      method: "GET",
      mode: "cors",
      headers,
      signal: AbortSignal.timeout(this.timeout),
    };

    return fetch(url, options).then(handleResponse(id));
  }
}

function validateDocumentId(id: string) {
  if (!id || typeof id !== "string")
    throw new DocumentError("Missing 'id'", ERRORCODES.MISSING_DATA);
}

function handleResponse(workspaceId: string) {
  return (response: Response) => {
    if (response.status === 200) {
      return response.json();
    } else if (
      response.status === ERRORCODES.REQUEST_ERROR ||
      response.status === ERRORCODES.NOT_AUTHORIZED ||
      response.status === ERRORCODES.FORBIDDEN ||
      response.status === ERRORCODES.NOT_FOUND ||
      response.status === ERRORCODES.PRECONDITION_FAILED ||
      response.status === ERRORCODES.SERVER_ERROR
    ) {
      return Promise.reject(
        new DocumentError(
          `${response.status} id: ${workspaceId}`,
          response.status
        )
      );
    } else {
      return Promise.reject(
        new DocumentError(
          `Server returned ${response.status} for id: ${workspaceId}`,
          ERRORCODES.SERVER_ERROR
        )
      );
    }
  };
}
