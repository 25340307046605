import type { Store } from "redux";
import { from } from "rxjs";
import { updateFlags } from "./state/config/config.actions";
import { version } from "../package.json";
import { RootState } from "typesafe-actions";
import type { ConfigState, FeatureFlags } from "./state/config/config.reducer";
import type { User } from "./state/user/user.reducer";
import {
  LDClientInterface,
  LDSingleKindContext,
  LDClientWrapper,
  screenSize,
} from "@hoylu/client-common";

function getPlatform(config: ConfigState) {
  if (config.teamsClient) {
    return "teams";
  }
  if (config.webexMode) {
    return "webex";
  }
  const electronOrigin = "http://hoylu.local"; //NOSONAR typescript:SS5332 - this http:// is not a call, but a check for the string
  if (window.location.origin.includes(electronOrigin)) {
    return "electron";
  }
  return "";
}

function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function connectFeatureFlagsToDispatch(store: Store) {
  const unsubscribe = store.subscribe(() => {
    const state = store.getState() as RootState;
    if (state.context.config.isLoaded) {
      unsubscribe();
      initLaunchDarkly(store, state.context.config);
    }
  });
}

let ldclient: LDClientInterface;

function initLaunchDarkly(store: Store, config: ConfigState) {
  const user: LDSingleKindContext = {
    kind: "user",
    key: "not_logged_in",
    anonymous: true,
    screen: screenSize(),
    platform: getPlatform(config),
    timezone: getTimezone(),
    appVersion: version,
  };
  const ldClientId = config.isBeta
    ? config.launchDarkly?.betaClientId
    : config.launchDarkly?.clientId;
  ldclient = new LDClientWrapper(ldClientId, user);
  function update() {
    store.dispatch(updateFlags(getFlags()));
  }
  ldclient.on("ready", update);
  ldclient.on("change", update);
}

function getFlags(): FeatureFlags {
  return {
    isAnonymous: !!ldclient.getContext().anonymous,
    enableBetaAccess: !!ldclient.variation("allow-beta-access"),
    enableSupplyChain: !!ldclient.variation("supply-chain-module"),
    enableWhatsNew: !!ldclient.variation("whats-new"),
    enableOrgBanner: !!ldclient.variation("organization-banner"),
    redirectPullPlanningV3ToLegacy: !!ldclient.variation("ppv3-in-legacy"),
    enableUpgradeWithinApp: !!ldclient.variation("upgrade-from-within-app"),
    validateEmailNotification: !!ldclient.variation(
      "validate-email-notification"
    ),
    highlightEnterpriseFeatures: !!ldclient.variation(
      "highlight-enterpise-features"
    ),
    enableHubspot: !!ldclient.variation("enable-hubspot"),
    enablePendo: !isEmbeddedHost() && !!ldclient.variation("enable-pendo"), // do not enable pendo on embedded.hoylu.com because it is not compliant with MS Teams policies
    enablePendoPII: !!ldclient.variation("enable-pendo-pii"),
    projectLicensing: !!ldclient.variation("project-licensing"),
    showWorkspaceWeight: !!ldclient.variation("show-workspace-size"),
    workspaceTemplates: !!ldclient.variation("workspace-templates"),
    showLegacyModules: !!ldclient.variation("show-legacy-modules"),
    showTemplateBasedModules: !!ldclient.variation(
      "show-template-based-modules"
    ),
    enableWorkspaceExport: !!ldclient.variation("Workspace-Export"),
    enableWorkspaceInfo: !!ldclient.variation("workspace-info-section"),
    showTemplateCreationUI: !!ldclient.variation("show-template-creation-ui"),
    enableFavouritesCategory: !!ldclient.variation(
      "enable-favourites-category"
    ),
    enableFavoritesWorkspaceSection: !!ldclient.variation(
      "enable-favorites-ws-section"
    ),
    enableInAppProjectCreation: !!ldclient.variation(
      "enable-in-app-project-creation"
    ),
    showCreditLimitUi: !!ldclient.variation("show-credit-limit-ui"),
    orgTemplates: !!ldclient.variation("org-templates"),
    showNotificationsUI: !!ldclient.variation("notifications"),
    enableNexusReporting: !!ldclient.variation("enable-nexus-reporting"),
  };
}

async function updateFeatureFlagUser(user: User, config: ConfigState) {
  // https://docs.launchdarkly.com/sdk/client-side/javascript#users
  let ldUser: LDSingleKindContext = {
    kind: "user",
    key: user.userId,
    email: user.email,
    orgId: user.orgId,
    platform: getPlatform(config),
    screen: screenSize(),
    timezone: getTimezone(),
    appVersion: version,
    _meta: {
      privateAttributes: ["email", "firstName", "lastName"],
    },
  };
  await ldclient.identify(ldUser);

  // in your authentication promise handler or callback

  return getFlags();
}

export const flagsForUserObservable = (user: User, config: ConfigState) =>
  from(updateFeatureFlagUser(user, config));

const isEmbeddedHost = () => {
  return window.location.hostname === "embedded.hoylu.com";
};
